export const navigation = [
  { name: "Home", href: "/", current: true },
  { name: "About", href: "/about", current: false },
  { name: "Services", current: false, menu: true },
  { name: "Dental Tourism", href: "/dental-tourism", current: false },
  { name: "Gallery", href: "/gallery", current: false },
  { name: "Book Now", href: "/book-now", current: false },
];

export const serviceNavs = [
  {
    name: "Oral Surgery",
    href: "oral-surgery",
  },
  {
    name: "Periodontology",
    href: "periodontology",
  },
  {
    name: "Implantology",
    href: "implantology",
  },
  {
    name: "Orthodontics",
    href: "orthodontics",
  },
  {
    name: "Prosthetics",
    href: "prosthetics",
  },
  {
    name: "Dental Aesthetics",
    href: "dental-aesthetic",
  },
  {
    name: "Pediatric Dentistry",
    href: "pediatric-dentistry",
  },
  {
    name: "Dental Hygiene",
    href: "dental-hygiene",
  },
  {
    name: "Facial Aesthetics",
    href: "facial-aesthetics",
  },
];
