import React from "react";
import {
  BanknotesIcon,
  BriefcaseIcon,
  CurrencyEuroIcon,
  HeartIcon,
  HomeIcon,
  ReceiptRefundIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";

const actions = [
  {
    tag: "briefcase",
    icon: BriefcaseIcon,
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
    ring: "ring-teal-700",
  },
  {
    tag: "rocket",
    icon: RocketLaunchIcon,
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
    ring: "ring-purple-700",
  },
  {
    tag: "eu",
    icon: CurrencyEuroIcon,
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
    ring: "ring-sky-700",
  },
  {
    tag: "home",
    icon: HomeIcon,
    iconForeground: "text-yellow-700",
    iconBackground: "bg-yellow-50",
    ring: "ring-yellow-700",
  },
  {
    tag: "insurance",
    icon: ReceiptRefundIcon,
    iconForeground: "text-rose-700",
    iconBackground: "bg-rose-50",
    ring: "ring-rose-700",
  },
  {
    tag: "money",
    icon: BanknotesIcon,
    iconForeground: "text-cyan-700",
    iconBackground: "bg-indigo-50",
    ring: "ring-indigo-700",
  },
  {
    tag: "heart",
    icon: HeartIcon,
    iconForeground: "text-red-500",
    iconBackground: "bg-red-50",
    ring: "ring-red-700",
  },
  {
    tag: "none",
    icon: null,
    iconForeground: "bg-gray-100",
    iconBackground: "bg-gray-100",
    ring: "ring-gray-100",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const OfferSection = ({ tagValues }) => {
  return (
    <>
      <div className="bg-gradient-to-b from-gray-900 to-black flex justify-center w-full p-7 pt-0">
        <video className="rounded-b-2xl" autoPlay loop muted controls p>
          <source src="/assets/videos/video.mp4" type="video/mp4" />
        </video>
      </div>
      <div
        className="pb-5 divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-100 shadow sm:grid  sm:gap-px sm:divide-y-0 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {actions.map((action, actionIdx) => (
          <div
            key={action.title}
            className={classNames(
              actionIdx === 0 ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none" : "",
              actionIdx === 1 ? "sm:rounded-tr-lg" : "",
              actionIdx === actions.length - 2 ? "sm:rounded-bl-lg" : "",
              actionIdx === actions.length - 1 ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none" : "",
              "group relative bg-gray-100 p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500",
            )}
          >

            <div className="mt-8">
              <h3 className="sm:text-lg font-value-tourism-dental leading-7 text-gray-900 text-2xl">
               <span
                 className={classNames(
                   action.iconBackground,
                   action.iconForeground,
                   action.ring,
                   "inline-flex rounded-full p-3 ring-2",
                 )}
               >
                {action.icon && <action.icon className="h-6 w-6" aria-hidden="true" />}
              </span> &ensp;
                <span className="absolute inset-0" aria-hidden="true" />
                {tagValues[action.tag]}
              </h3>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
