import React from "react";
export const oralSurgeryParagraph = () => (
  <section>
    <div className="font-semibold text-2xl">
      Potresti avere bisogno di un intervento chirurgico orale per diverse ragioni:
    </div>

    <ul className="list-disc pl-16 mt-1">
      <li>Decadimento dentale esteso.</li>
      <li>Denti gravemente rotti.</li>
      <li>Malattia gengivale.</li>
      <li>Denti inclusi.</li>
      <li>Denti mancanti.</li>
      <li>Disturbi dell'articolazione temporomandibolare (TMD).</li>
      <li>Perdita di osso nella tua mascella.</li>
      <li>Apnea notturna.</li>
      <li>Estrazione dentale</li>
    </ul>

    <p className="mt-5">
      Il tipo più comune di intervento chirurgico orale è l'estrazione del dente (rimozione del dente). Un'estrazione potrebbe essere raccomandata se hai una grave carie dentale, malattia gengivale (parodontite), traumi dentali o complicazioni legate ai denti del giudizio. A volte le estrazioni dentarie vengono eseguite per prepararti all'utilizzo di dentiere o altri dispositivi protesici.
    </p>

    <p className="mt-5">
      La maggior parte dei dentisti preferisce salvare i denti naturali quando possibile, ma a volte le estrazioni sono necessarie per preservare la tua salute orale complessiva. Inoltre, molti dentisti raccomandano l'estrazione del dente del giudizio come misura preventiva per ridurre il rischio di carie, perdita ossea e altri problemi.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Innesto osseo dentale
    </div>

    <p className="mt-5">
      Un innesto osseo dentale è necessario quando si è verificata una perdita di osso nella mascella. Ci sono un paio di motivi per cui ciò può accadere. Quando i tuoi denti naturali sono presenti, le radici stimolano i nervi nella tua mascella. Questo segnala al tuo cervello di inviare nutrienti alla tua mascella, mantenendola forte e sana. Se un dente è mancante da un po' di tempo, può verificarsi una deteriorazione ossea in quella zona perché non ci sono radici per stimolare i nervi. Un innesto osseo dentale ripristina il volume e la densità nella tua mascella in modo che possano essere successivamente posizionati impianti dentali.
    </p>

    <p className="mt-5">
      La durata del tuo intervento dipenderà da diversi fattori, tra cui il tipo di intervento chirurgico orale che stai subendo, quanti denti vengono trattati e se scegli o meno la sedazione. L'estrazione di un singolo dente di solito richiede circa 30 minuti, mentre un intervento più complesso, come la chirurgia correttiva della mandibola, richiede tipicamente almeno due o tre ore.
    </p>

    <p className="mt-5">
      Dopo l'intervento chirurgico orale, ti verranno fornute dettagliate istruzioni post-operatorie. È importante che segui attentamente queste linee guida per ridurre il rischio di sanguinamento, infezioni e altre complicazioni.
    </p>
  </section>
);

export const periodontologyParagraph = () => (
  <section>
    <p className="mt-5">
      La principale funzione del parodonto (tessuti gengivali) è quella di mantenere i denti nella loro posizione all'interno dell'osso, ma agisce anche come un cuscinetto durante la masticazione per evitare danni ai denti quando subiscono forze eccessive. I tessuti e i denti agiscono e lavorano insieme per proteggersi e favorire una corretta funzione durante la masticazione.
    </p>

    <p className="mt-5">
      Lo stato orale di una persona può essere un chiaro indicatore della loro salute generale, in particolare la condizione parodontale delle gengive può mostrarci molto più di quanto accada a livello di parodontologia. Le malattie sistemiche (diabete, fumo, HIV, ...) possono avere un effetto diretto sulle strutture che supportano i denti. Alcune patologie possono anche essere rilevate attraverso la bocca prima che diventino evidenti in qualsiasi altra parte del corpo. Per questo motivo, i parodontisti (dentisti gengivali) possono essere i primi a rilevare segni di una malattia generale come il diabete o disturbi del sangue. Lo studio dentistico o il parodontologo possono diventare un centro per la diagnosi precoce del diabete.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      COSA FANNO I PARODONTISTI?
    </div>

    <p className="mt-5">
      I parodontisti sono quei dentisti specializzati nella prevenzione e nel trattamento delle malattie che colpiscono il parodonto. Tutti i dentisti ricevono una formazione in parodontologia, ma un parodontologo riceve una formazione aggiuntiva specifica in questo campo. I parodontisti con uno status di specialista hanno conseguito una laurea post-laurea o un master in parodontologia e implantologia dentale durante tre corsi accademici a tempo pieno. Quindi è comune nella pratica quotidiana che i casi più complicati siano trattati da specialisti in questo settore della odontoiatria.
    </p>

    <p className="mt-5">
      Le malattie parodontali trattate dai parodontisti sono molto comuni tra la popolazione e sono una conseguenza dell'infiammazione dei tessuti parodontali (gengive). La gengivite è la risposta infiammatoria delle gengive dovuta all'accumulo di batteri nei denti e nei tessuti molli della bocca. La gengivite si verifica almeno una volta nella vita in tutti gli individui. Questa risposta è un meccanismo di difesa che il corpo umano ha per combattere i batteri patogeni ma può causare danni ai tessuti se non trattata correttamente. L'infiammazione può estendersi alle gengive e distruggere il legamento parodontale e l'osso alveolare causando la parodontite. Ciò provoca una perdita di supporto per i denti e persino la perdita dei denti quando la malattia è in fasi più avanzate.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      TRATTAMENTO DELLA PARODONTITE
    </div>

    <p className="mt-5">
      Con un adeguato consiglio e trattamento da parte di professionisti, è solitamente possibile fermare completamente la progressione della parodontite. La chiave è eliminare la placca batterica che accelera il processo patologico ed instaurare eccellenti abitudini di igiene orale. Pertanto, il primo passo di un trattamento parodontale consiste nel far prendere coscienza al paziente della sua condizione e dare istruzioni sull'igiene orale. In questa prima fase igienica è molto importante assicurarsi che il paziente adempia adeguatamente alle sue igiene. D'altra parte, il parodontista eseguirà procedure igieniche per eliminare il tartaro e la placca batterica da aree non accessibili al paziente. Talvolta può essere somministrata terapia antibiotica se l'infezione dei tessuti persiste e non risponde alle misure igieniche.
    </p>

    <p className="mt-5">
      Dopo questa prima fase, la condizione parodontale e l'igiene del paziente vengono controllate dopo qualche settimana. Si controlla il progresso della malattia e si esegue una misurazione della profondità delle tasche. Se alcune tasche persistono (spazi dove si trovano batteri che hanno la capacità di distruggere gengive e osso), vengono offerte opzioni di trattamento supplementari, comprese le procedure chirurgiche. Talvolta è necessario eseguire una chirurgia che solleva la gengiva per poter accedere a quelle aree dove c'è il tartaro e pulirlo. Ciò consente di avere una visione diretta e assicurarsi che l'area sia completamente pulita. In altri casi sarà possibile eseguire interventi chirurgici rigenerativi in cui la perdita ossea viene trattata e rigenerata al fine di cercare di restituire una anatomia più corretta ai difetti ossei creati dalla parodontite. In altri casi non sarà possibile rigenerare questi difetti e verranno eseguite chirurgie in cui l'osso viene rimodellato e, se necessario, anche la gengiva.
    </p>

    <p className="mt-5">
      Dopo questa fase chirurgica, se necessario, verrà eseguita una fase restaurativa. È in questo momento che possono essere posizionati impianti, protesi definitive, trattamenti ortodontici o altre opzioni di trattamento appropriate a ciascuna situazione individuale. Gli impianti possono essere posizionati quando il trattamento parodontale è completato.
    </p>

    <p className="mt-5">
      Infine, il successo della terapia parodontale a lungo termine dipende dall'igiene orale da parte del paziente e dalla manutenzione professionale, nonché dai consigli dei professionisti. È importante eseguire questa fase di manutenzione regolarmente per controllare lo stato dei tessuti parodontali e verific

are che l'infiammazione sia sotto controllo. In questo modo è possibile verificare il mantenimento dei risultati ottenuti dal trattamento parodontale, prevenire la ricorrenza della malattia e rilevare precocemente la comparsa di nuove lesioni parodontali per poterle trattare e informare il paziente sulle misure igieniche da adottare per evitare la ricorrenza della malattia parodontale.
    </p>
  </section>
);

export const implantologyParagraph = () => (
  <section>

    <div className="font-semibold mt-9 text-2xl">
      IMPLANTOLOGIA, UNA BRANCA DELLA ODONTOIATRIA DEDICATA AGLI IMPIANTI DENTARI
    </div>

    <p className="mt-5">
      Un dente naturale ha due componenti principali: la corona e la radice. La corona è la parte visibile del dente e la radice si trova al di sotto della gengiva, nell'osso della mandibola. L'implantologia è la branca dell'odontoiatria dedicata agli impianti dentali. L'impianto dentale è una radice artificiale che viene inserita nell'osso mascellare (sia nella mascella superiore che in quella inferiore). Spesso ha la forma di una vite composta da titanio o lega di titanio.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      GLI IMPIANTI SOSTITUISCONO I DENTI MANCANTI
    </div>

    <p className="mt-5">
      Gli impianti dentali possono essere presi in considerazione per i pazienti che hanno uno o più denti mancanti.
    </p>

    <p className="mt-5">
      Un impianto dentale da solo non sostituisce un dente naturale. Un moncone deve essere montato sull'impianto una volta che quest'ultimo è stato inserito nell'osso mascellare.
    </p>

    <p className="mt-5">
      A seconda del numero di denti da sostituire, verrà preparata e installata una corona artificiale, una dentiera completa o parziale o un ponte sull'abutment (o abutments). Se il paziente ha solo un dente mancante, sarà sufficiente una singola corona. Se è necessario sostituire più denti, sono disponibili molte opzioni: utilizzare un singolo impianto con molte corone collegate da un ponte o una protesi, oppure utilizzare più impianti con corone distinte. Questa scelta spetta al paziente, secondo il piano di trattamento raccomandato dallo specialista in implantologia.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      UN INTERVENTO AMBULATORIALE ESEGUITO SOTTO ANESTESIA LOCALE
    </div>

    <p className="mt-5">
      L'inserimento degli impianti dentali è un intervento chirurgico che di solito viene eseguito in ambulatorio e sotto anestesia locale.
    </p>

    <p className="mt-5">
      Diversi professionisti della salute dentale sono qualificati per installare gli impianti dentali: dentisti, chirurghi orali e maxillo-facciali, parodontologi e altri specialisti in odontoiatria. Questi professionisti devono avere una formazione approfondita in implantologia. I dentisti e i protesisti spesso si specializzano nella fabbricazione di corone, ponti e dentiere che vengono montati sugli impianti.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      VANTAGGI DEGLI IMPIANTI DENTALI
    </div>

    <ul className="list-disc pl-16 mt-1" >
      <li>
        Quando un paziente ha perso uno o più denti, gli impianti dentali hanno molteplici vantaggi rispetto ad altri trattamenti che coinvolgono protesi rimovibili.
      </li>

      <li>
        Gli impianti consentono l'installazione di protesi dentali fisse o rimovibili.
      </li>

      <li>
        Sono più confortevoli e discreti perché non causano alcun fastidioso sfregamento o movimento sulle gengive, come nel caso delle protesi rimovibili che non sono montate sugli impianti (ad esempio: dentiere parziali o complete). È possibile ottenere una dentiera supportata da impianti che non copre affatto il palato.
      </li>

      <li>
        Non è necessaria alcuna colla dentale per fissare la protesi supportata dagli impianti.
      </li>

      <li>
        Gli impianti offrono una maggiore stabilità ai denti artificiali, il che facilita la masticazione e la digestione, migliora il comfort e agevola il linguaggio.
      </li>

      <li>
        I pazienti non sono costretti a adattare la loro dieta.
      </li>

      <li>
        Gli impianti consentono di distribuire le forze di masticazione tra gli impianti e le strutture adiacenti della bocca (gengive, ossa e denti rimanenti), il che aiuta a ridurre lo stress su queste strutture. Quando ci sono ancora denti sani in bocca, il paziente può aspettarsi di poter conservare questi denti più a lungo rispetto a quando non è stato installato alcun impianto e lo spazio creato dalla mancanza di denti è rimasto vuoto.
      </li>

      <li>
        Gli impianti contribuiscono alla preservazione dell'osso alveolare (l'osso che sostiene i denti) prevenendone la riassorbimento dove mancano i denti. Infatti, quando manca un dente, l'osso alveolare che lo sosteneva non viene più stimolato e si deteriora gradualmente.
      </li>

      <li>
        Gli impianti non richiedono la limatura e la modifica dei denti adiacenti (come nel caso di un ponte tradizionale).
      </li>

      <li>
        Gli impianti danno l'impressione di avere denti naturali, sia esteticamente che funzionalmente, e agiscono come una radice artificiale ancorata nell'osso mascellare, come una radice di dente naturale. I pazienti possono quindi recuperare l'integrità delle loro caratteristiche facciali che erano state influenzate dalla perdita di diversi denti.
      </li>

      <li>
        I due componenti principali di un impianto (la corona artificiale e l'impianto stesso) non saranno influenzati dalla carie dentale.
      </li>

      <li>
        Una volta completata l'osseointegrazione, gli impianti raramente devono essere sostituiti e quindi offrono una soluzione affidabile e a lungo termine. Nonostante l'alto costo iniziale, l'impianto dentale è un buon investimento data la sua aspettativa di vita.
      </li>
    </ul>

    <div className="font-semibold mt-9 text-2xl">
      COSTI DEGLI IMPIANTI DENTALI
    </div>

    <p>
      I costi degli impianti dentali sono suddivisi in due categorie:
    </p>

    <ul className="list-disc pl-16 mt-1" >
      <li>Il costo dell'intervento chirurgico (quando gli impianti dentali e gli abutments vengono inseriti nell'osso del paziente);</li>
      <li>Il costo del restauro protesico (corona, ponte o dentiera che verrà collegato all'abutment dell'impianto).</li>
    </ul>

  </section>
);

export const orthodonticsParagraph = () => (
  <section>
    <p className="mt-5">
      L'ortodonzia è la branca dell'odontoiatria che si occupa della diagnosi e del trattamento delle "malocclusioni" (morsi scorretti). I trattamenti ortodontici comuni includono apparecchi, allineatori trasparenti e dispositivi di ritenzione.
    </p>

    <p className="mt-5">
      Il modo in cui i tuoi denti si adattano tra loro ha un impatto significativo sulla tua salute orale. Migliorando il modo in cui i tuoi denti superiori e inferiori si incontrano, puoi ridurre il rischio di una serie di problemi di salute orale, tra cui carie, malattia delle gengive e usura eccessiva (erosione).
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Chi trae beneficio dall'ortodonzia?
    </div>

    <p className="mt-5">
      Quasi tutti possono trarre qualche beneficio dall'ortodonzia. Tuttavia, alcune persone hanno bisogno di un trattamento più di altre. Molte persone cercano il trattamento ortodontico perché vogliono migliorare l'aspetto del loro sorriso. Ma oltre ai vantaggi estetici, l'ortodonzia offre una migliore funzione masticatoria e una migliore salute orale.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Problemi ortodontici comuni
    </div>

    <p className="mt-5">
      I tipi di problemi ortodontici includono:
    </p>

    <ul className="list-disc pl-16 mt-1" >
      <li>
        Sovrapposizione dei denti superiori quando sporgono oltre i denti inferiori.
      </li>

      <li>
        Sottosviluppo, quando i denti inferiori sporgono più avanti rispetto ai denti superiori.
      </li>

      <li>
        Denti sovrapposti.
      </li>

      <li>
        Denti affollati.
      </li>

      <li>
        Denti storti.
      </li>

      <li>
        Denti ruotati.
      </li>

      <li>
        Denti inclusi (parzialmente o completamente intrappolati nell'osso mascellare).
      </li>
    </ul>

    <div className="font-semibold mt-9 text-2xl">
      Apparecchi ortodontici
    </div>

    <p className="mt-5">
      Gli apparecchi ortodontici spostano gradualmente i denti nelle posizioni desiderate nel tempo utilizzando una combinazione di staffe, fasce e fili. Ci sono diversi tipi di apparecchi a seconda delle tue esigenze, compresi gli apparecchi ortodontici tradizionali in metallo e gli apparecchi chiari (ceramica).
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Allineatori trasparenti
    </div>

    <p className="mt-5">
      Gli allineatori ortodontici trasparenti sono un'alternativa popolare agli apparecchi. Marchi ben noti includono Invisalign® e ClearCorrect®. Invece di utilizzare staffe e fili per raddrizzare i denti, questi sistemi utilizzano una serie di mascherine trasparenti personalizzate. Indossi ogni set di mascherine per una o due settimane prima di cambiarle con il set successivo della serie. Nel tempo, gli allineatori trasparenti spostano i denti nelle posizioni corrette.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Dispositivi di ritenzione
    </div>

    <p>
      Se indossi apparecchi ortodontici o allineatori trasparenti, avrai bisogno di un dispositivo di ritenzione una volta terminato il trattamento ortodontico. Un dispositivo di ritenzione è un apparecchio orale personalizzato che impedisce ai tuoi denti di spostarsi dalle loro posizioni corrette. Il tuo ortodontista può dirti con quale frequenza dovrai indossare il dispositivo di ritenzione per mantenere i risultati del trattamento.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Espansori del palato
    </div>

    <p>
      Gli ortodontisti raccomandano alcuni trattamenti durante l'infanzia. Questo perché le ossa facciali di un bambino sono ancora in fase di sviluppo e sono molto più facili da spostare e manipolare. Un espansore del palato aiuta a espandere la mascella superiore di un bambino. La maggior parte dei bambini non ha bisogno di espansori del palato. Ma nelle circostanze giuste, questi dispositivi possono creare spazio extra senza la necessità di estrazioni dentali o altri interventi.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Quali sono i vantaggi dell'ortodonzia?
    </div>

    <p>
      I vantaggi estetici dell'ortodonzia sono evidenti. Ma questi trattamenti offrono anche vantaggi funzionali e legati alla salute:
    </p>

    <ul className="list-disc pl-16 mt-1" >
      <li>I tuoi denti sono molto più facili da pulire quando sono allineati correttamente.</li>
      <li>Il raddrizzamento dei denti può migliorare la funzione di masticazione e di linguaggio.</li>
      <li>Il trattamento ortodontico può alleviare il dolore legato al disturbo dell'ATM.</li>
      <li>Una morsa allineata può ridurre il rischio di carie, malattie delle gengive e altri problemi di salute orale.</li>
      <li>L'ortodonzia può aiutarti a ottenere e mantenere un sorriso bello e sano.</li>
    </ul>

  </section>
);

export const prostheticsParagraph = () => (
  <section>
    <p className="mt-5">
      Esistono diversi tipi di apparecchi dentali fissi, come le corone e gli impianti, e dispositivi rimovibili, comprese le protesi complete o parziali, che possono essere rimosse secondo necessità.
    </p>

    <p className="mt-5">
      In questo articolo, esaminiamo cinque tipi di dispositivi protesici dentali in modo che tu possa avere una discussione informata con il tuo dentista su quale potrebbe essere il migliore per te.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      1. Corone dentali
    </div>

    <p className="mt-5">
      Una corona dentale è una nuova copertura per un dente danneggiato.
    </p>

    <p className="mt-5">
      Una corona può essere realizzata in metallo o porcellana. Le corone tendono a essere buone soluzioni a lungo termine per i denti che sono stati scheggiati, incrinati o consumati. Ma i denti che richiedono una notevole quantità di restauro sono a molto più alto rischio di fallimento, secondo uno studio del 2018 pubblicato su Journal of Dentistry.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Ottenere una corona richiede spesso due appuntamenti. In un processo tipico per una corona dentale, un dentista:
    </div>

    <ul className="list-disc pl-16 mt-1" >
      <li>
        Ottiene un calco morbido dei tuoi denti.
      </li>

      <li>
        Riempie eventuali cavità nel dente danneggiato (se necessario).
      </li>

      <li>
        Prepara il dente per ricevere una corona.
      </li>

      <li>
        Mette una corona temporanea sul dente mentre ne viene fatta una permanente in laboratorio.
      </li>

      <li>
        Dopo alcune settimane, posiziona una corona permanente che viene cementata in posizione.
      </li>

      <li>
        Gli studi odontoiatrici che possono realizzare corone in loco possono offrire corone in giornata.
      </li>
    </ul>

    <p className="mt-5">
      Le corone sono considerate una soluzione relativamente permanente. Una volta che una corona è in posizione, dovrebbe durare da 5 a 15 anni o anche più a lungo se mantenuta correttamente. Dovresti spazzolare e usare il filo interdentale per una corona come faresti con qualsiasi altro dente.
    </p>

    <p className="mt-5">
      Una corona può creparsi o scheggiarsi nel tempo, o il cemento che la tiene in posizione può ammorbidirsi. Ciò può consentire ai batteri di insediarsi e infettare il dente.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Ponte dentale
    </div>

    <p className="mt-5">
      Quando uno o più denti sono mancanti, un ponte dentale è un compromesso tra le protesi e gli impianti.
    </p>

    <p className="mt-5">
      Come suggerisce il nome, un ponte dentale è destinato a coprire un vuoto lasciato dai denti mancanti. Un ponte è solitamente ancorato ai denti naturali su entrambe le estremità del vuoto e può essere composto da uno o più denti finti chiamati ponticelli. In alcuni casi, un ponte può essere collegato a un impianto per una maggiore sicurezza e stabilità.
    </p>

    <p className="mt-5">
      Un ponte è spesso realizzato in porcellana per assomigliare ai denti naturali e deve essere spazzolato e usato il filo interdentale regolarmente. Possono essere necessari degli ausili per il filo interdentale chiamati infilafili per far passare il filo tra il ponte e la gengiva.
    </p>

    <p className="mt-5">
      Ecco il processo tipico per ottenere un ponte:
    </p>

    <ul className="list-disc pl-16 mt-1" >
      <li className="mt-2">
        Nel primo appuntamento, i denti d'ancoraggio ai lati del ponte vengono limati e viene preso un calco della tua bocca. Potrebbe essere realizzato un ponte temporaneo per riempire il vuoto mentre ne viene fatto uno permanente entro un paio di settimane.
      </li>

      <li className="mt-2">
        Nel secondo appuntamento, il ponte permanente viene inserito in posizione. Vengono effettuati eventuali aggiustamenti minori in modo che il ponte appaia naturale e funzioni con la tua morsa naturale. Il ponte viene quindi cementato in posizione.
      </li>

      <li>
        Un ponte ben fatto può durare per decenni, ma un periodo di 5-15 anni è una previsione ragionevole.
      </li>

    </ul>

    <div className="font-semibold mt-9 text-2xl">
      Protesi dentarie
    </div>

    <p className="mt-5">
      Le protesi dentarie sono dispositivi protesici che si adattano perfettamente alle gengive e hanno l'aspetto e la funzionalità dei denti naturali. Sono anche chiamate denti finti.
    </p>

    <p className="mt-5">
      La quantità di perdita di denti e le tue preferenze aiuteranno a determinare quale tipo di protesi dentarie sia migliore per te. I tipi più comuni di protesi dentarie includono:
    </p>

    <ul className="list-disc pl-16 mt-1" >

      <li className="mt-2">
        Le protesi complete sostituiscono tutti i tuoi denti e devono essere rimosse giornalmente per la pulizia.
      </li>

      <li className="mt-2">
        Le protesi parziali rimovibili sostituiscono alcuni, ma non tutti, i tuoi denti.
      </li>

      <li>
        Le protesi parziali fisse sono anche conosciute come ponti supportati da impianti.
      </li>

      <li>
        Le protesi supportate da impianti sono fissate agli impianti ma devono essere rimosse giornalmente per la pulizia (chiamate anche protesi a scatto).
      </li>

    </ul>

    <p className="mt-5">
      Un altro tipo di protesi dentaria è chiamato flipper. Queste sono protesi parziali temporanee che sostituiscono uno o due denti. Sono realizzate con materiali meno resistenti rispetto alle protesi parziali regolari poiché sono destinate a essere usate per un breve periodo.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Faccette dentali
    </div>

    <p className="mt-5">
      Le faccette possono aiutare a migliorare il colore e l'aspetto dei denti naturali coprendoli con uno strato sottile dal look naturale.
    </p>

    <p className="mt-5">
      Ecco il processo tipico per ottenere una faccetta:
    </p>

    <ul className="list-disc pl-16 mt-1" >

      <li className="mt-2">
        Il dentista lima lo smalto dai tuoi denti e prende un calco della tua bocca.
      </li>

      <li className="mt-2">
        Utilizzando il calco, i tecnici di laboratorio creeranno le tue faccette in porcellana o in un materiale composito a base di resina. Il processo potrebbe richiedere un paio di settimane.
      </li>

      <li>
        Le faccette vengono quindi cementate sui tuoi denti. Una luce ultravioletta viene utilizzata per indurire rapidamente il cemento che tiene le faccette attaccate ai tuoi denti.
      </li>

      <li>
        Devi avere denti relativamente sani per supportare una faccetta. Le faccette possono durare da 10 a 15 anni prima di dover essere sostituite.
      </li>
    </ul>

    <div className="font-semibold mt-9 text-2xl">
      Quali sono i vantaggi di un dispositivo protesico dentale?
    </div>

    <p className="mt-5">
      Un dispositivo protesico dentale è utile per offrire uno o più dei seguenti benefici:
    </p>

    <ul className="list-disc pl-16 mt-1" >

      <li className="mt-2">
        Migliorare l'aspetto dei tuoi denti, aumentando la fiducia in se stessi.
      </li>

      <li className="mt-2">
        Migliorare la tua capacità di masticare, consentendoti di consumare una dieta sana.
      </li>

      <li>
        Sostenere la salute e la forza dell'osso mascellare, supportare la forza degli altri denti.
      </li>

      <li>
        Proteggere dalle malattie delle gengive che possono portare a problemi di salute gravi.
      </li>
    </ul>
  </section>
);

export const dentalAestheticsParagraph = () => (
  <section>
    <div className="font-semibold mt-9 text-2xl">
      Cos'è l'Estetica Dentale?
    </div>

    <p className="mt-5">
      In termini semplici, possiamo definire l'estetica dentale come una procedura per migliorare l'aspetto generale dei denti. Lo scopo principale dell'estetica dentale è mantenere i denti allineati, migliorare la loro struttura e abbellirne colore e forma in modo che tu possa sorridere con fiducia. L'estetica dentale è sia una misura preventiva che restaurativa, in quanto può essere utilizzata per correggere denti mancanti e altri problemi simili. Tuttavia, è importante sottolineare che l'odontoiatria estetica non è la stessa cosa della odontoiatria cosmetica.
    </p>

    <p className="mt-5">
      La prima è qualsiasi metodo utilizzato per migliorare il tuo sorriso attraverso trattamenti che sarebbero necessari. Al contrario, la seconda comporta la correzione dei lavori dentali esistenti o vecchi come le faccette, le corone, i ponti, e così via, che potrebbero non essere particolarmente piacevoli alla vista. Ora che abbiamo discusso le basi dell'estetica dentale, osserviamo le varie procedure per l'estetica dentale.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Procedure di Estetica Dentale
    </div>

    <div className="font-semibold mt-9 text-2xl">
      L'estetica dentale include una serie di procedure, e le più comuni sono:
    </div>

    <p className="mt-5">
      <strong>Sbiancamento:</strong> È una procedura di estetica dentale molto diffusa. Il metodo prevede lo sbiancamento dei denti per rimuovere le macchie esterne sui denti. Alcun ingiallimento dei denti è una conseguenza di problemi interni come un dente morente o l'erosione dello smalto, e l'odontoiatria estetica corregge tali anomalie. Lo sbiancamento dei denti con il laser è una procedura moderna di sbiancamento dentale in cui le gengive vengono coperte con gomma e si applica il perossido di idrogeno per sbiancare i denti. Successivamente, si proietta un flash del laser all'argon sui denti per accelerare il processo di sbiancamento. Lo sbiancamento dei denti con il laser è sei volte più efficace rispetto allo sbiancamento dei denti convenzionale.
    </p>

    <p className="mt-5">
      <strong>Rimodellamento:</strong> È legato alla rimozione di una parte dello smalto di un dente fuori forma che richiede la rimozione di parte dello smalto. Il rimodellamento può essere utilizzato per correggere scheggiature e crepe sui denti. Può anche modificare la posizione, la forma o la lunghezza dei denti. Un altro uso importante dei denti è la correzione di un morso eccessivo o un morso insufficiente.
    </p>

    <p className="mt-5">
      <strong>Lifting delle Gengive:</strong> È una procedura di estetica dentale che prevede l'innalzamento e la successiva modellazione della linea gengivale in modo che il tuo sorriso non sembri né troppo gengivale né troppo "dente da cavallo". Il processo ridisegna il tessuto gengivale e le ossa sottostanti per creare un sorriso più simmetrico e uniforme.
    </p>

    <p className="mt-5">
      <strong>Raddrizzamento dei Denti:</strong> È una procedura di estetica dentale standard in cui i denti vengono gradualmente raddrizzati con apparecchi ortodontici o bite. Il raddrizzamento aiuta a correggere sovrapposizioni, affollamenti o spazi ampi tra i denti. Oggi, molte persone preferiscono anche Invisalign poiché sono invisibili all'occhio nudo.
    </p>

    <p className="mt-5">
      <strong>Bonding:</strong> È una procedura di estetica dentale che di solito viene eseguita dopo altri trattamenti dentali. Il processo di legatura con resina composita è quando materiali compositi simili all'smalto dentale vengono applicati sulla superficie dei denti e poi modellati, induriti e lucidati.
    </p>

    <p className="mt-10">
      Pertanto, possiamo affermare che l'estetica dentale è necessaria per rendere le persone più sicure di sé e infondere fiducia in se stesse. Ha reso la vita di molte persone più facile permettendo loro di sorridere in modo più ampio e luminoso.
    </p>
  </section>
);

export const pedodonticsParagraph = () => (
  <section>
    <div className="font-semibold mt-9 text-2xl">
      Benefici della pedodonzia
    </div>

    <p className="mt-5">
      I bambini iniziano di solito ad avere i denti primari intorno ai sei mesi di età. I bambini dovrebbero visitare il dentista pediatrico entro sei mesi dalla comparsa del primo dente o entro l'età di un anno, a seconda di quale avvenga prima. I dentisti pediatrici affrontano problemi legati alla bocca e alla mascella e aiutano anche a correggere abitudini come il succhiare il pollice. Scoprite i benefici della visita dal dentista pediatrico. Questi benefici sono utili sia per i bambini che per i genitori.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Mantenere i bambini a loro agio
    </div>

    <p className="mt-5">
      I bambini spesso sono nervosi quando devono andare dal dentista o dal medico. Il dentista pediatrico è addestrato a mettere i bambini a proprio agio. Il dentista utilizza un set di abilità speciali per far sentire i bambini a loro agio. Questo crea un rapporto di fiducia tra i bambini e il dentista.
    </p>

    <p className="mt-5">
      Anche il personale dell'ufficio del dentista pediatrico è addestrato a lavorare con i bambini. Il personale crea un ambiente divertente e confortevole per i pazienti. L'ufficio potrebbe anche offrire attività divertenti per i bambini. Questo tiene occupate le menti dei bambini prima e durante l'appuntamento dentistico.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Insegnare ai bambini le corrette tecniche di igiene orale
    </div>

    <p className="mt-5">
      È difficile per i genitori insegnare ai loro figli come spazzolare e usare il filo interdentale correttamente. I bambini potrebbero trascurare alcune parti dei denti, rendendoli vulnerabili alla carie. I dentisti addestrati in pedodonzia insegnano ai bambini le corrette tecniche di igiene orale. I dentisti forniscono anche ai genitori consigli per rendere più semplice la cura orale a casa. Questi consigli possono persino rendere divertente lo spazzolamento e l'uso del filo interdentale per i bambini.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Un approccio olistico alla cura dentale
    </div>

    <p className="mt-5">
      I dentisti pediatrici sono formati per adottare un approccio olistico alla cura dentale. I dentisti esaminano i fattori ambientali che influenzano i denti, inclusi cibi e bevande consumati. Questi dentisti considerano anche i fattori di rischio come l'uso del ciuccio e le abitudini di succhiare il pollice. Allo stesso tempo, i dentisti pediatrici offrono pulizie dentali, trattamenti al fluoro e altri servizi dentali per mantenere sorrisi sani. Questo approccio olistico garantisce che il bambino riceva la giusta cura.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Assicurare lo sviluppo corretto della bocca
    </div>

    <p className="mt-5">
      Poiché i dentisti pediatrici sono formati per la cura orale dei bambini, cercano problemi di sviluppo. I dentisti assicurano che la bocca si sviluppi correttamente e che i denti eruttino al momento giusto. Quando i dentisti notano problemi, iniziano un piano di trattamento. Il trattamento precoce promuove uno sviluppo sano. Uno sviluppo orale corretto è fondamentale per il linguaggio e l'alimentazione.
    </p>
  </section>
);

export const dentalHygieneParagraph = () => (
  <section>
    <div className="font-semibold mt-9 text-2xl">
      Malattie dentali e gengivali causate da scarsa igiene orale
    </div>

    <p className="mt-5">
      La principale causa delle malattie dentali e gengivali è la scarsa igiene orale personale. Le malattie causate dalla scarsa igiene orale possono essere prevenute mediante visite regolari dal dentista per una visita preventiva e da un igienista orale, che non solo esegue l'igiene orale professionale ma suggerisce anche gli strumenti necessari per una corretta cura dentale a casa. La masticazione errata degli alimenti, il consumo frequente di cibi morbidi e la mancanza di sale nel corpo possono contribuire all'insorgenza di malattie dentali e gengivali.
    </p>

    <p className="mt-5">
      Si consiglia l'igiene orale professionale due volte l'anno. I pazienti con impianti, protesi fisse, devono recarsi da un igienista ogni 6 mesi. I pazienti che indossano apparecchi dentali dovrebbero sottoporsi a procedure di igiene orale professionale ogni 4 mesi o come consigliato dall'igienista orale.
    </p>

    <p className="mt-5">
      L'igiene orale professionale viene sempre eseguita prima dell'otturazione estetica, della protesi permanente e degli interventi chirurgici.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      RIMOZIONE DEL TARTARO
    </div>

    <div className="font-semibold mt-9 text-2xl">
      Cos'è?
    </div>

    <p className="mt-5">
      L'igiene dentale professionale rimuove la placca dentale molle e dura utilizzando strumenti speciali nell'ufficio dell'igienista orale. La placca dentale è una delle cause più comuni di infiammazione gengivale, carie prematuramente, parodontite (meglio conosciuta come malattia parodontale) e perdita precoce dei denti, quindi deve essere rimossa tempestivamente.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Malattie dentali e gengivali causate da scarsa igiene orale
    </div>

    <p className="mt-5">
      La principale causa delle malattie dentali e gengivali è la scarsa igiene orale personale. Le malattie causate dalla scarsa igiene orale possono essere prevenute mediante visite regolari dal dentista per una visita preventiva e da un igienista orale, che non solo esegue l'igiene orale professionale ma suggerisce anche gli strumenti necessari per una corretta cura dentale a casa. La masticazione errata degli alimenti, il consumo frequente di cibi morbidi e la mancanza di sale nel corpo possono contribuire all'insorgenza di malattie dentali e gengivali.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Quando è consigliata l'igiene dentale?
    </div>

    <p className="mt-5">
      Si consiglia l'igiene orale professionale due volte l'anno. I pazienti con impianti, protesi fisse, devono recarsi da un igienista ogni 6 mesi. I pazienti che indossano apparecchi dentali dovrebbero sottoporsi a procedure di igiene orale professionale ogni 4 mesi o come consigliato dall'igienista orale.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Metodo "Air-Flow" per l'igiene dentale
    </div>

    <p className="mt-5">
      Il forte flusso di aria, acqua e polvere di carbonato di calcio medico pulisce rapidamente e senza dolore la placca dentale. Questo metodo non danneggia i tessuti duri del dente e le gengive, poiché la superficie del dente viene trattata da una certa distanza.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Pulizia del tartaro
    </div>

    <p className="mt-5">
      L'igienista orale pulisce la placca mineralizzata utilizzando uno scaler ad ultrasuoni e gli strumenti manuali necessari. Tutte le superfici dei denti e delle otturazioni vengono lucidate con una pasta. La placca morbida e pigmentata viene rimossa mediante abrasione dell'aria utilizzando polvere di carbonato di calcio (meglio conosciuta come bicarbonato di sodio).
    </p>
  </section>
);

export const facialAestheticsParagraph = () => (
  <section>
    <p className="mt-5">
      L'estetica del viso si riferisce a un tipo di procedura non chirurgica e minimamente invasiva che può temporaneamente ridurre le rughe o ripristinare il volume. Le aree di trattamento comuni includono la fronte, le guance e le labbra.
      <br/>
      L'estetica del viso è un termine ampio che può riferirsi a iniezioni antirughe, filler dermici e filler labbra. Questa disciplina affronta le principali preoccupazioni riguardo al viso, tra cui la perdita di volume, le piccole rughe o le grinze.
    </p>

    <p className="mt-5">
      Questi risultati non dureranno per sempre e alla fine svaniranno, ma le persone possono scegliere di "riempirli" regolarmente se desiderano mantenere i risultati. Durante la vostra prima consultazione con il nostro team di estetica del viso, possiamo aiutarvi a identificare le vostre principali preoccupazioni e i trattamenti che daranno i migliori risultati. Successivamente, possiamo formulare un piano di trattamento che funzioni per voi.
    </p>

    <p className="mt-5">
      Quando si decide di sottoporsi a trattamenti di estetica del viso, è importante avere aspettative realistiche su ciò che può essere ottenuto. Vi aiuteremo a gestire le vostre aspettative offrendo una rappresentazione realistica di ciò che ogni trattamento può ottenere e quanto durerà. Vi consiglieremo anche trattamenti alternativi se pensiamo che si possano ottenere risultati migliori con un altro metodo.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Opzioni per l'estetica del viso
    </div>

    <p className="mt-5">
      Ci sono tre opzioni principali di trattamento disponibili per l'estetica del viso. I residenti di Rayleigh possono scegliere tra iniezioni antirughe, filler dermici e filler labbra. Esploriamo più nel dettaglio ciascuna opzione di trattamento.
    </p>

    <p className="mt-5">
      Le iniezioni antirughe sono comunemente utilizzate per rilassare i muscoli del viso in modo che la pelle sopra appaia più liscia. Questo può contribuire a ridurre le rughe profonde e le piccole rughe. Le aree di trattamento comuni includono la fronte, intorno agli occhi, i lati del naso e il mento. Questo trattamento può anche aiutare a risolvere problemi come un sorriso gengivale. I risultati sono visibili dopo circa una settimana e possono durare circa 3-4 mesi, ma ciò varierà per le diverse persone.
    </p>

    <p className="mt-5">
      I filler dermici vengono utilizzati per ripristinare il volume del viso. Contengono una sostanza chiamata acido ialuronico che aiuta a trattenere l'acqua. Questo materiale si trova naturalmente nel corpo ed è gradualmente eliminato nel tempo. I filler dermici possono essere utilizzati per riempire gli avvallamenti sotto gli occhi o nelle tempie. Possono anche essere utilizzati per creare una maggiore definizione nei vostri tratti, compresi il naso, il mento e le guance. I risultati sono visibili immediatamente e possono durare fino a 6 mesi.
    </p>

    <p className="mt-5">
      I filler labbra sono simili ai filler dermici ma vengono utilizzati per aumentare il volume delle labbra. Le labbra perdono volume con l'età, e labbra più piene possono creare un aspetto più giovane. I filler labbra possono creare un leggero aumento, cambiare la forma delle vostre labbra o creare una trasformazione più drammatica. I risultati sono visibili immediatamente e possono durare fino a 6 mesi.
    </p>
  </section>
);
