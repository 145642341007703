import React, { Fragment, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { I18NContext } from "../App";

export const I18NSelector = () => {

  const { data, selector, setter } = useContext(I18NContext);
  const { en, it, al } = data.lanSelect;
  return (
    <Menu as="div" className="relative ml-3">
    <div>
      <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
        <span className="absolute -inset-1.5" />
        <span className="sr-only">Open user menu</span>
        <img
          className="h-8 w-8 rounded-full"
          src={`/assets/flags/${selector}.png`}
          alt=""
        />
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-gray-200 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <Menu.Item>
          {({ active }) => (
            <a
              href="#"
              onClick={() => {
                setter('en')
              }}
              className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
            >
              {en}
            </a>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <a
              href="#"
              onClick={() => {
                setter('it');
              }}
              className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
            >
              {it}
            </a>
          )}
        </Menu.Item>
        <Menu.Item>
          {({ active }) => (
            <a
              href="#"
              onClick={() => {
                setter('al')
              }}
              className={`${active ? 'bg-gray-100' : ''} block px-4 py-2 text-sm text-gray-700`}
            >
              {al}
            </a>
          )}
        </Menu.Item>
      </Menu.Items>
    </Transition>
  </Menu>)
}