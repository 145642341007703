import { footerSection, pictureWallSection, services, testimonialSection, valueSection } from "./i18n/en/";

import {
  footerSection as itFooterSection,
  pictureWallSection as itPictureWallSection,
  services as itServices,
  testimonialSection as itTestimonials,
  valueSection as itValueSection,
} from "./i18n/it/";

import {
  footerSection as alFooterSection,
  pictureWallSection as alPictureWallSection,
  services as alServices,
  testimonialSection as alTestimonials,
  valueSection as alValueSection,
} from "./i18n/al/";

export const i18n = {
  en: {
    sections: {
      values: valueSection,
      testimonial: testimonialSection,
      pictureWall: pictureWallSection,
      footer: footerSection,
      offerSection: {
        tagValues: {
          briefcase: "Professionalism and experience, internationally certified doctors, highly skilled and knowledgeable professionals supporting you",
          rocket: "Latest generation innovative equipments and technologies",
          eu: "EU quality and certifications",
          home: "Free transport accommodation and assistance",
          insurance: "Written guarantee and consent",
          money: "Save your money while maintaining a high quality",
          heart: "Complete care, full suite of dental care to cover you and your family's oral health needs.",
          none: "",
        },
      },
    },
    pages: {
      home: {
        partners: "Our Partners",
      },
      services: services,
      about: {
        values: {
          header: "Our values",
          data: [
            {
              name: "Being professional",
              id: 1,
              src: "being-professional",
            },
            {
              name: "Being supportive",
              id: 2,
              src: "being-supportive",
            },
            {
              name: "Being friendly",
              id: 3,
              src: "being-friendly",
            },
            {
              name: "Taking responsibility",
              id: 4,
              src: "taking-responsibility",
            },
            {
              name: "Always learning",
              id: 5,
              src: "always-learning",
            },
            {
              name: "Being empathic",
              id: 6,
              src: "being-empathic",
            },
            {
              name: "Sharing kindness",
              id: 7,
              src: "sharing-kindness",
            },
            {
              name: "Being first class",
              id: 8,
              src: "being-first-class",
            },
          ],
        },
      },
      bookNow: {
        title: "Get in touch",
        form: {
          labels: [
            "First name",
            "Last name",
            "Email",
            "Phone number",
            "Message",
          ],
          button: "Send message",
          attach: {
            title: "Choose files",
            label: "No files chosen",
          },
        },
      },
      gallery: {
        title: "Gallery",
      },
    },
    lanSelect: {
      en: "EN",
      it: "IT",
      al: "AL",
    },
    nav: {
      main: [
        { name: "Home", href: "/" },
        { name: "About", href: "/about" },
        { name: "Services", menu: true },
        { name: "Dental Tourism", href: "/dental-tourism" },
        { name: "Gallery", href: "/gallery" },
        { name: "Book Now", href: "/book-now" },
      ],
      serviceNavs: [
        {
          name: "Oral Surgery",
          href: "oral-surgery",
        },
        {
          name: "Periodontology",
          href: "periodontology",
        },
        {
          name: "Implantology",
          href: "implantology",
        },
        {
          name: "Orthodontics",
          href: "orthodontics",
        },
        {
          name: "Prosthetics",
          href: "prosthetics",
        },
        {
          name: "Dental Aesthetics",
          href: "dental-aesthetic",
        },
        {
          name: "Pediatric Dentistry",
          href: "pediatric-dentistry",
        },
        {
          name: "Dental Hygiene",
          href: "dental-hygiene",
        },
        {
          name: "Facial Aesthetics",
          href: "facial-aesthetics",
        },
      ],
    },
  },
  it: {
    sections: {
      values: itValueSection,
      testimonial: itTestimonials,
      pictureWall: itPictureWallSection,
      footer: itFooterSection,
      offerSection: {
        tagValues: {
          briefcase: "Professionalità ed esperienza, medici con certificazione internazionale, professionisti altamente qualificati e competenti al tuo servizio",
          rocket: "Apparecchiature e tecnologie innovative di ultima generazione",
          eu: "Qualità e certificazioni UE",
          home: "Alloggio e assistenza di trasporto gratuiti",
          insurance: "Garanzia e consenso scritto",
          money: "Risparmia denaro mantenendo un'alta qualità",
          heart: "Cura completa, un'ampia gamma di servizi odontoiatrici per coprire le esigenze di salute orale tua e della tua famiglia",
          none: "",
        },
      },
    },
    pages: {
      home: {
        partners: "I nostri partner",
      },
      services: itServices,
      about: {
        values: {
          header: "I nostri valori",
          data: [
            {
              name: "Essere professionale",
              id: 1,
              src: "being-professional",
            },
            {
              name: "Essere solidale",
              id: 2,
              src: "being-supportive",
            },
            {
              name: "Essere amichevole",
              id: 3,
              src: "being-friendly",
            },
            {
              name: "Assumersi la responsabilità",
              id: 4,
              src: "taking-responsibility",
            },
            {
              name: "Essere sempre in apprendimento",
              id: 5,
              src: "always-learning",
            },
            {
              name: "Essere empatico",
              id: 6,
              src: "being-empathic",
            },
            {
              name: "Condividere gentilezza",
              id: 7,
              src: "sharing-kindness",
            },
            {
              name: "Essere di prima classe",
              id: 8,
              src: "being-first-class",
            },
          ],
        },
      },
      bookNow: {
        title: "Contattaci",
        form: {
          labels: [
            "Nome",
            "Cognome",
            "Email",
            "Numero di telefono",
            "Messaggio",
          ],
          button: "Invia messaggio",
          attach: {
            title: "Scegli file",
            label: "Nessun file selezionato",
          },
        },
      },
      gallery: {
        title: "Galleria",
      },
    },
    lanSelect: {
      en: "ING",
      it: "IT",
      al: "ALB",
    },
    nav: {
      main: [
        { name: "Casa", href: "/", current: true },
        { name: "Chi siamo", href: "/about" },
        { name: "Servizi", menu: true },
        { name: "Turismo Dentale", href: "/dental-tourism" },
        { name: "Galleria", href: "/gallery" },
        { name: "Prenota Ora", href: "/book-now" },
      ],
      serviceNavs: [
        {
          name: "Chirurgia Orale",
          href: "oral-surgery",
        },
        {
          name: "Parodontologia",
          href: "periodontology",
        },
        {
          name: "Implantologia",
          href: "implantology",
        },
        {
          name: "Ortodonzia",
          href: "orthodontics",
        },
        {
          name: "Protesica",
          href: "prosthetics",
        },
        {
          name: "Estetica Dentale",
          href: "dental-aesthetic",
        },
        {
          name: "Odontoiatria Pediatrica",
          href: "pediatric-dentistry",
        },
        {
          name: "Igiene Dentale",
          href: "dental-hygiene",
        },
        {
          name: "Estetica Facciale",
          href: "facial-aesthetics",
        },
      ],
    },
  },
  al: {
    sections: {
      values: alValueSection,
      testimonial: alTestimonials,
      pictureWall: alPictureWallSection,
      footer: alFooterSection,
      offerSection: {
        tagValues: {
          briefcase: "Profesionalizmi dhe përvoja, mjekë të certifikuar ndërkombëtarisht, profesionistë të aftë dhe të njohur që ju mbështesin",
          rocket: "Pajisjet dhe teknologjitë inovative të gjeneratës së fundit",
          eu: "Cilësia dhe certifikimet ne përputhje me BE-në",
          home: "Trasport akomodim dhe asistence falas",
          insurance: "Garanci dhe pajtim i shkruar",
          money: "Kurseni paratë tuaja duke mbajtur cilësinë e lartë",
          heart: "Kujdes i plotë, një suitë e plotë e kujdesit dental për të mbuluar nevojat e shëndetit oral të juaj dhe familjes suaj",
          none: "",
        },
      },
    },
    pages: {
      home: {
        partners: "Partnerët tanë",
      },
      services: alServices,
      about: {
        values: {
          header: "Vlerat tona",
          data: [
            {
              name: "Jemi profesionistë",
              id: 1,
              src: "being-professional",
            },
            {
              name: "Jemi përkrahës",
              id: 2,
              src: "being-supportive",
            },
            {
              name: "Jemi miqësorë",
              id: 3,
              src: "being-friendly",
            },
            {
              name: "Mbajmë përgjegjësi",
              id: 4,
              src: "taking-responsibility",
            },
            {
              name: "Gjithmonë duke mësuar",
              id: 5,
              src: "always-learning",
            },
            {
              name: "Jemi empatikë",
              id: 6,
              src: "being-empathic",
            },
            {
              name: "Shpërndajmë mirësi",
              id: 7,
              src: "sharing-kindness",
            },
            {
              name: "Jemi first class",
              id: 8,
              src: "being-first-class",
            },
          ],
        },
      },
      bookNow: {
        title: "Lidhu me ne",
        form: {
          labels: [
            "Emri",
            "Mbiemri",
            "Email",
            "Numri i telefonit",
            "Mesazhi",
          ],
          button: "Dërgo mesazh",
          attach: {
            title: "Zgjidhni skedarë",
            label: "Nuk është zgjedhur asnjë skedar",
          },
        },
      },
      gallery: {
        title: "Galeria",
      },
    },
    lanSelect: {
      en: "ANG",
      it: "IT",
      al: "SQ",
    },
    nav: {
      main: [
        { name: "Kreu", href: "/", current: true },
        { name: "Rreth Nesh", href: "/about" },
        { name: "Shërbimet", menu: true },
        { name: "Turizmi Dentar", href: "/dental-tourism" },
        { name: "Galeria", href: "/gallery" },
        { name: "Rezervo Tani", href: "/book-now" },
      ],
      serviceNavs: [
        {
          name: "Kirurgjia Orale",
          href: "oral-surgery",
        },
        {
          name: "Periodontologjia",
          href: "periodontology",
        },
        {
          name: "Implantologjia",
          href: "implantology",
        },
        {
          name: "Ortodoncia",
          href: "orthodontics",
        },
        {
          name: "Protetika",
          href: "prosthetics",
        },
        {
          name: "Estetika Dentale",
          href: "dental-aesthetic",
        },
        {
          name: "Dentistika Pediatrica",
          href: "pediatric-dentistry",
        },
        {
          name: "Higjiena Dentare",
          href: "dental-hygiene",
        },
        {
          name: "Estetika Faciale",
          href: "facial-aesthetics",
        },
      ],
    },
  },
};


export const generalData = {
  testimonials: [
    {
      author: "Jolanda Gjeci",
      review: "The service is always amazing great quality in customer service and fantastic results. Professional staff and one of the only places in Tirana that I recommend and that takes very good care of you",
      img: "t-1",
      count: 1,
      lastValue: 2,
      last: "2 years ago",
    },
    {
      author: "Denisa Kita",
      review: "Excellent studio, qualified service and specialized doctors for all areas of dentistry :)",
      img: "t-2",
      count: 2,
      lastValue: 2,
      last: "2 years ago",
    },
    {
      author: "Daniela Spahiu",
      review: "Very professional. I always have a great experience at this clinic.",
      img: "t-3",
      count: 3,
      lastValue: 2,
      last: "2 years ago",
    },
    {
      author: "Donisela Mamani",
      review: "Best Dental Studio I have ever been 👍🏻 …",
      img: "t-4",
      count: 1,
      lastValue: 2,
      last: "2 years ago",
    },
    {
      author: "Dolora Curri",
      review: "It was a perfect experience. Now I can smile again 😁!!! …",
      img: "t-5",
      count: 1,
      lastValue: 2,
      last: "2 years ago",
    },
    {
      author: "Joanne Cano",
      review: "The best Dental studio.",
      img: "t-6",
      count: 1,
      lastValue: 2,
      last: "2 years ago",
    },
    {
      author: "Nevila Peto",
      review: "The best dentist 👏👏👏👏 …",
      img: "t-7",
      last: "a year ago",
      lastValue: 1,
      count: 1,
    },
    {
      author: "Eriglent Kallollari",
      review: "Excellent expert dentist... has many Italian customers. I have carried out very important interventions and I must tell the truth the friends who suggested it to me were right... they use high quality materials and with modest prices you have excellent service... highly recommended",
      img: "t-8",
      last: "3 years ago",
      lastValue: 3,
      count: 13,
    },
    {
      author: "Rea Nase",
      review: "🌟🌟🌟🌟🌟 …",
      img: "t-9",
      count: 1,
      lastValue: 2,
      last: "2 years ago",
    },
  ],
};
