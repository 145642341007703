import React, { useContext } from "react";
import { BookNow } from "./BookNow";
import { OfferSection } from "../section/OfferSection";
import { I18NContext } from "../App";

export const Tourism = () => {
  const { data } = useContext(I18NContext);
  const offerSection = data.sections.offerSection;
  return (
    <>
      <OfferSection {...offerSection} />
      <BookNow />
    </>
  );
};