export const getCount = (w, slideCount) => {
  if (w > 1200) return slideCount?.[0] || 5;
  if (w > 1000) return slideCount?.[1] || 4;
  if (w > 700) return slideCount?.[2] || 3;
  if (w > 350) return slideCount?.[3] || 2;
  return 1;
};

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};
