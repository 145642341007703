import React from "react";
import { Carousel } from "../components/Carousel";
import { getCount } from "../utils/helpers";

export const MultiValueSection = ({ values: { header, data }, subsection }) => {
  return (
    <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8">
      <div className="mx-auto max-w-2xl lg:mx-0 pb-6">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {header}
        </h2>
      </div>
      <Carousel getCount={getCount} slideCounts={[3, 3, 2, 1]} slides={8}>
        {data.map((img) => {
          return (
            <>
              <div key={img.id}
                   className="group relative w-280 h-300 min-w-280 max-w-280 min-h-300 max-h-300 justify-center inline-flex flex-vertical">
                <div className={
                  "w-[360px] h-[240px] border-2 border-b-black aspect-h-1 aspect-w-1 overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 flex justify-center items-center"
                }>
                  <div style={{
                    backgroundImage: `url('/assets/${subsection || "values"}/${img.src}.jpg')`,
                  }} className="absolute overflow-hidden z-0 w-full h-full blur bg-no-repeat bg-cover " />
                  <img
                    src={`/assets/values/${img.src}.jpg`}
                    alt={img.name}
                    className="z-10 object-center w-280 h-300 min-w-280 max-w-280 min-h-300 max-h-300"
                  />
                </div>
              </div>
              {img.name ?
                <div className="flex-wrap overflow-hidden w-full h-full text-center text-2xl font-service-title-about ">
                  <span className="p-3 border rounded-2xl bg-gray-200 bg-opacity-70 text-gray-700">{img.name}</span>
                </div> : null
              }
            </>
          );
        })}
      </Carousel>
    </div>
  );
};
