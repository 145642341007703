import React from "react";
import {
  dentalAestheticsParagraph,
  dentalHygieneParagraph,
  facialAestheticsParagraph,
  implantologyParagraph,
  oralSurgeryParagraph,
  orthodonticsParagraph,
  pedodonticsParagraph,
  periodontologyParagraph,
  prostheticsParagraph,
} from "./paragraphs";

export const valueSection = {
  stats: [
    { label: "Fondata", value: "2017" },
    { label: "Dipendenti", value: "10" },
    { label: "Paesi", value: "13" },
    { label: "Procedure", value: "70" },
    { label: "Pazienti", value: "2232" },
  ],
  quote: "Comprendiamo l'importanza di scegliere uno studio dentistico dove ti senti benvenuto e al sicuro. Vogliamo che i nostri pazienti sentano di ricevere le migliori cure dentistiche possibili in un'atmosfera rilassata. Siamo qui per aiutarli a capire che stanno facendo il miglior investimento possibile... Un sorriso luminoso e bellissimo!",
  linkToAbout: () => (
    <>
      Scopri di più sulla nostra azienda <span aria-hidden="true">&rarr;</span>
    </>
  ),
  paragraph: {
    header: "Valori professionali",
    title: "In missione per creare un progetto d'élite, per vedere la generazione di famiglie e valorizzare la fiducia che i nostri pazienti hanno riposto in noi.",
    list: [
      {
        body: () => (
          <>
            In missione per creare un progetto d'élite, per vedere la generazione di famiglie e valorizzare la fiducia
            che i nostri pazienti hanno riposto in noi. Abbiamo sviluppato relazioni a lungo termine con i nostri
            pazienti. I pazienti vengono nella nostra clinica non solo per sistemare i loro denti o cambiare il sorriso,
            ma anche per visite regolari per garantire la continuità delle cure.
          </>
        ),
      },
      {
        body: () => (
          <>
            Il nostro team è concentrato su una cosa...
          </>
        ),
      },
      {
        body: () => (
          <>
            SOLO TU! Ogni membro del nostro team è concentrato sulla tua esperienza unica come paziente, non solo sulla
            cura dentale. Studieremo dettagliatamente la condizione della tua bocca e del tuo sorriso, ti offriremo le
            migliori opzioni per cambiare il tuo sorriso e renderemo il trattamento più confortevole e piacevole. Avrai
            anche qualcuno con cui parlare nella tua lingua madre e che ti spiegherà tutto. Il nostro personale ti
            tratterà come un membro della famiglia.
          </>
        ),
      },
    ],
  },
};

export const testimonialSection = {
  header: "Testimonianze",
  title: "Abbiamo aiutato migliaia di persone straordinarie",
};

export const pictureWallSection = {
  imgSrc: "/assets/home/home.jpg",
  header: "Ciao Dental Albania",
  text: () => (
    <>
      Siamo impegnati a offrire un servizio responsabile, sostenibile ed etico.
      La nostra duratura a lungo termine ci permette di concentrarci solo sulla qualità.
      Stiamo facendo del nostro meglio per creare una bellezza naturale, compresa la vostra sorriso e il vostro viso.
    </>
  ),
  link: {
    text: "Leggi la mia storia",
    href: "/about",
  },
};

export const footerSection = {
  logo: {
    imgSrc: "/assets/dental-logo.png",
  },
  schedule: {
    businessHours: "Orari di apertura",
    monday: {
      title: "Lunedì",
    },
    tuesday: {
      title: "Martedì",
    },
    wednesday: {
      title: "Mercoledì",
    },
    thursday: {
      title: "Giovedì",
    },
    friday: {
      title: "Venerdì",
    },
    saturday: {
      title: "Sabato",
    },
    sunday: {
      title: "Domenica",
      value: "Chiuso",
    },
  },
  rightReserved: () => (<div className="inline-flex justify-between w-full">
      <span> &copy; {new Date().getFullYear()} Ciao Dental Albania. Tutti i diritti riservati.</span>
      <span> Creato da <a href="https://www.linkedin.com/in/denisakita/" target="_blank">Denisa Kita</a> </span>
    </div>
  ),
};

export const services = {
  "oral-surgery": {
    imgCount: 4,
    pictureWall: {
      imgSrc: "/assets/services/oral-surgery/title.jpg",
      header: "Chirurgia Orale",
      text: () => (
        <>
          La chirurgia orale si riferisce a qualsiasi intervento chirurgico eseguito sui denti, gengive, mascella o
          altre strutture orali. Questo include estrazioni, impianti, innesti gengivali e interventi alla mascella. Di
          solito, la chirurgia orale è eseguita da un chirurgo orale e maxillo-facciale o da un parodontologo. Questi
          sono specialisti odontoiatri che hanno una formazione avanzata in procedure di chirurgia orale.
        </>
      ),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "chirurgia-orale",
    header: "Chirurgia Orale",
    paragraph: oralSurgeryParagraph,
    subs: {
      header: "Sotto-Servizi",
      data: [
        { id: 1, content: "Estrazioni semplici" },
        { id: 2, content: "Estrazioni chirurgiche di livello 1" },
        { id: 3, content: "Estrazioni chirurgiche di livello 2" },
        { id: 4, content: "Estrazioni dei denti del giudizio" },
        { id: 5, content: "Estrazioni delle radici dei denti" },
        { id: 6, content: "Estrazioni dei denti trattenuti" },
        { id: 7, content: "Correzione del labbro doppio" },
        { id: 8, content: "Chirurgia dei cisti" },
        { id: 9, content: "Sollevamento del seno mascellare" },
      ],
    },
  },
  "periodontology": {
    imgCount: 5,
    pictureWall: {
      imgSrc: "/assets/services/periodontology/title.jpg",
      header: "Parodontologia",
      text: () => (
        <>
          La parodontologia è lo studio di un sistema specializzato di tessuti duri (osso) e tessuti molli (gingiva) che
          supporta i denti e ne mantiene la posizione nella mascella e nella mandibola (ossa del cranio). Questo insieme
          di tessuti è chiamato parodonto ed è composto da osso alveolare, cemento radicolare, legamento parodontale e
          gingiva. I denti si trovano all'interno dell'osso alveolare e sono supportati dal legamento parodontale che
          collega il cemento che copre la radice del dente. Questi tessuti viventi possono adattarsi a diverse modifiche
          nella bocca che consentono di mantenere la posizione dei denti in modo stabile.
        </>
      ),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "parodontologia",
    header: "Parodontologia",
    paragraph: periodontologyParagraph,
    subs: {
      header: "Sotto-Servizi",
      data: [
        { id: 1, content: "Sbiancamento e detartrasi totali" },
        { id: 2, content: "Sbiancamento e detartrasi parziali" },
        { id: 3, content: "Sbiancamento e detartrasi chirurgici" },
        { id: 4, content: "Correzione del sorriso gengivale" },
        { id: 5, content: "Chirurgia plastica delle gengive" },
      ],
    },
  },
  "implantology": {
    imgCount: 5,
    pictureWall: {
      imgSrc: "/assets/services/implantology/title.jpg",
      header: "Implantologia",
      text: () => (
        <>
          L'impianto dentale è una radice artificiale del dente che viene inserita nell'osso mascellare (sia nella
          mascella che nella mandibola). Spesso assume la forma di una vite composta da titanio o lega di titanio.
        </>
      ),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "implantologia",
    header: "Implantologia",
    paragraph: implantologyParagraph,
    subs: {
      header: "Sotto-Servizi",
      data: [
        { id: 1, content: "Impianto Straumann" },
        { id: 2, content: "Impianto Schutz" },
        { id: 3, content: "Impianto Eurotecnica" },
        { id: 4, content: "Impianto Swiss" },
      ],
    },
  },
  "orthodontics": {
    imgCount: 7,
    pictureWall: {
      imgSrc: "/assets/services/orthodontics/title.jpg",
      header: "Ortodonzia",
      text: () => (
        <>
          L'ortodonzia è una specialità odontoiatrica focalizzata sull'allineamento del morso e sulla correzione dei
          denti storti, sovrapposti, contorti o con spaziatura irregolare. Potresti dover consultare un ortodontista se
          hai denti storti, sovrapposti, contorti o con spaziatura. I trattamenti ortodontici comuni includono
          apparecchi tradizionali, dispositivi trasparenti e dispositivi di ritenzione rimovibili.
        </>
      ),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "ortodonzia",
    header: "Ortodonzia",
    paragraph: orthodonticsParagraph,
    subs: {
      header: "Sotto-Servizi",
      data: [
        { id: 1, content: "Studio ortodontico" },
        { id: 2, content: "Apparecchiature ortodontiche tradizionali in metallo" },
        { id: 3, content: "Apparecchiature ortodontiche in ceramica" },
        { id: 4, content: "Allineatori dentali" },
        { id: 5, content: "Mantenitori di spazio" },
        { id: 6, content: "Allineatori per riposizionamento della mascella" },
        { id: 7, content: "Distanziatori per labbro e guancia" },
        { id: 8, content: "Espansore palatale" },
        { id: 9, content: "Apparecchi di ritenzione rimovibili" },
        { id: 10, content: "Casco cervicale" },
      ],
    },
  },
  "prosthetics": {
    imgCount: 5,
    pictureWall: {
      imgSrc: "/assets/services/prosthetics/title.jpg",
      header: "Protesi Dentaria",
      text: () => (
        <>
          I dispositivi protesici dentali sostituiscono o riparano denti mancanti o danneggiati. Possono fare molto di
          più che migliorare il tuo sorriso. Un dente mancante può influenzare la tua capacità di masticare e aumentare
          il rischio di malattia gengivale. Un dente mancante può anche influenzare la salute dell'osso della mascella e
          mettere a rischio altri denti.
        </>
      ),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "protesi-dentaria",
    header: "Protesi Dentaria",
    paragraph: prostheticsParagraph,
    subs: {
      header: "Sotto-Servizi",
      data: [
        { id: 1, content: "Corona su denti naturali" },
        { id: 2, content: "Metallo" },
        { id: 3, content: "Zirconia" },
        { id: 4, content: "Metallo porcellana" },
        { id: 5, content: "Zirconia integrale" },
        { id: 6, content: "Emax" },
        { id: 7, content: "Facette dentali" },
        { id: 8, content: "Inlay/Onlay" },
        { id: 9, content: "Paladio" },
        { id: 10, content: "Corona su impianti" },
        { id: 11, content: "Metallo porcellana" },
        { id: 12, content: "Zirconia" },
        { id: 13, content: "Zirconia integrale" },
        { id: 14, content: "Protesi dentarie" },
        { id: 15, content: "Protesi parziali acriliche" },
        { id: 16, content: "Protesi parziali in metallo" },
        { id: 17, content: "Protesi parziali in porcellana" },
        { id: 18, content: "Protesi totale" },
        { id: 19, content: "All on 2 (resina acrilica/metallo-porcellana/zirconia)" },
        { id: 20, content: "All on 4 (resina acrilica/metallo-porcellana/zirconia)" },
        { id: 21, content: "All on 6 (resina acrilica/metallo-porcellana/zirconia)" },
      ],
    },
  },
  "dental-aesthetic": {
    imgCount: 6,
    pictureWall: {
      imgSrc: "/assets/services/dental-aesthetic/title.jpg",
      header: "Estetica Dentale",
      text: () => (
        <>
          Lo scopo principale dell'estetica dentale è mantenere i denti allineati, migliorare la loro struttura e
          abbellire colore e forma in modo che tu possa sorridere con fiducia. L'estetica dentale è sia una misura
          preventiva che restaurativa in quanto può essere utilizzata per correggere denti mancanti e altri problemi
          simili.
        </>
      ),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "estetica-dentale",
    header: "Estetica Dentale",
    paragraph: dentalAestheticsParagraph,
    subs: {
      header: "Sotto-Servizi",
      data: [
        { id: 1, content: "Pulizia dentale" },
        { id: 2, content: "Igiene orale completa" },
        { id: 3, content: "Air flow" },
        { id: 4, content: "Fluorazione dentale" },
        { id: 5, content: "Pulizia con dentifricio sbiancante" },
        { id: 6, content: "Air flow" },
        { id: 7, content: "Fluorazione dentale" },
        { id: 8, content: "Pulizia con dentifricio sbiancante" },
        { id: 9, content: "Sbiancamento dentale" },
        { id: 10, content: "Opalescence to go" },
        { id: 11, content: "Riempimento dentale Gr.2" },
        { id: 12, content: "Riempimento dentale Gr.3" },
        { id: 13, content: "Riempimento dentale Gr.4" },
        { id: 14, content: "Riempimenti estetici" },
        { id: 15, content: "Facette in composito" },
      ],
    },
  },
  "pediatric-dentistry": {
    imgCount: 7,
    pictureWall: {
      imgSrc: "/assets/services/pediatric-dentistry/title.jpg",
      header: "Odontoiatria Pediatrica",
      text: () => (
        <>
          La pedodonzia è una specialità focalizzata sulla diagnosi e il trattamento dei problemi dentali dei bambini.
          Un dentista pediatrico lavora con i bambini dall'infanzia alla giovinezza. Il dentista pediatrico aiuta i
          bambini a raggiungere e mantenere una buona salute orale. Scopri di più sull'odontoiatria pediatrica per
          capire perché è importante per la salute orale del tuo bambino.
        </>
      ),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "odontoiatria-infantile",
    header: "Odontoiatria Pediatrica",
    paragraph: pedodonticsParagraph,
    subs: {
      header: "Sotto-Servizi",
      data: [
        { id: 1, content: "Pulizia e igiene dentale" },
        { id: 2, content: "Fluorizzazione" },
        { id: 3, content: "Sigillatura dentale" },
        { id: 4, content: "Riempimento dentale Gr.2" },
        { id: 5, content: "Riempimento dentale Gr.3" },
        { id: 6, content: "Trattamento delle infezioni del canale radicolare" },
      ],
    },
  },
  "dental-hygiene": {
    imgCount: 6,
    pictureWall: {
      imgSrc: "/assets/services/dental-hygiene/title.jpg",
      header: "Igiene Dentale",
      text: () => (
        <>
          L'igiene dentale professionale rimuove la placca dentale molle e dura utilizzando strumenti speciali nello
          studio dell'igienista dentale. La placca dentale è una delle cause più comuni di infiammazione gengivale,
          carie premature, parodontite (meglio conosciuta come malattia parodontale) e perdita prematura dei denti,
          quindi deve essere rimossa tempestivamente.
        </>
      ),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "igiene-dentale",
    header: "Igiene Dentale",
    paragraph: dentalHygieneParagraph,
    subs: null,
  },
  "facial-aesthetics": {
    imgCount: 6,
    pictureWall: {
      imgSrc: "/assets/services/facial-aesthetics/title.jpg",
      header: "Estetica Facciale",
      text: () => (
        <>
          Piccole rughe, solchi e perdita di volume nel viso e nelle labbra potrebbero farti sentire insicuro/a riguardo
          all'invecchiamento. Con il trattamento di estetica facciale, puoi tornare indietro nel tempo e ringiovanire la
          tua pelle con poche iniezioni.
        </>
      ),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "estetica-facciale",
    header: "Estetica Facciale",
    paragraph: facialAestheticsParagraph,
    subs: {
      header: "Sotto-Servizi",
      data: [
        { id: 1, content: "Consulenza" },
        { id: 2, content: "Pulizia profonda del viso" },
        { id: 3, content: "Pulizia profonda del corpo (collo, spalle, schiena)" },
        { id: 4, content: "Terapia con retinoidi" },
        { id: 5, content: "Terapia lifting Protenius" },
        { id: 6, content: "Terapia nutriente al caviale" },
        { id: 7, content: "Terapia di fitopeeling Apocalypsis" },
        { id: 8, content: "Terapia Thavma" },
        { id: 9, content: "Terapia Chronos" },
        { id: 10, content: "Terapia Opsis" },
        { id: 11, content: "Pelle sublime viso e collo" },
        { id: 12, content: "Skin Regimen Detox e Antinquinamento" },
        { id: 13, content: "Ritocco notturno Renight" },
        { id: 14, content: "Profhilo di IBSA" },
        { id: 15, content: "PRX-Reverse Melasma" },
        { id: 16, content: "Trattamenti totali viso, occhi e collo Fillmed" },
        { id: 17, content: "Peeling luminoso e leggero di Fillmed" },
        { id: 18, content: "Mezoterapia Apriline-Swiss" },
        { id: 19, content: "TKN HA 3 di Toskani per il viso" },
        { id: 20, content: "Mezoterapia TKN di Toskani" },
        { id: 21, content: "Mezoterapia per il doppio mento" },
        { id: 22, content: "Trattamenti con maschere purificanti e terapia a diodi emettitori di luce" },
        { id: 23, content: "Cosmo Peel Forte" },
        { id: 24, content: "BioReTherapy Biohasic Tech" },
        { id: 25, content: "Skintech RRS HA a lunga durata" },
        { id: 26, content: "JPX 3 rivitalizzante bio" },
        { id: 27, content: "Sunekos 200" },
        { id: 28, content: "Ejal 40 Rivitalizzazione con booster di acido ialuronico per la pelle" },
        { id: 29, content: "Collagene iniettabile per viso e corpo Nithya" },
        { id: 30, content: "Botox completo" },
        { id: 31, content: "Botox per gli occhi" },
        { id: 32, content: "Trattamento dell'iperidrosi" },
        { id: 33, content: "Filler dermici" },
      ],
    },
  },
};

