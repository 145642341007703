import React from "react";
import { Carousel } from "../components/Carousel";
import { Link } from "react-router-dom";
import { getCount } from "../utils/helpers";

export const ServicesSection = (props) => {
  return (
    <div className="bg-gray-300">
      <div className="py-16 sm:py-24 xl:mx-auto xl:max-w-7xl xl:px-8">
        <div className="mt-4 flow-root">
          <div className="-my-2">
            <div className="relative box-content h-80 overflow-x-auto py-2 xl:overflow-visible">
              <Carousel getCount={getCount} slides={9}>
                {Object.keys(props.data).map((keyId, key) => {
                  const service = props.data[keyId];
                  return (
                    <Link key={key} to={`services/${keyId}`}
                          className="relative flex min-h-80 h-80 max-h-80 min-w-56 w-56 max-w-56 flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto duration-300 transition hover:ease-in">
                                          <span aria-hidden="true" className="absolute inset-0">
                                            <img
                                              src={`/assets/services/${keyId}/nav.jpg`}
                                              alt={keyId}
                                              className="h-full w-full object-cover object-center"
                                            />
                                          </span>
                      <span
                        aria-hidden="true"
                        className="ease-out hover:ease-in absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50"
                      />
                      <span className="relative mt-auto text-center text-xl font-bold text-white">
                                            {service.header}
                                          </span>
                    </Link>);
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
