import React from "react";
export const oralSurgeryParagraph = () => (
  <section>
    <div className="font-semibold text-2xl">
      Mund të keni nevojë për operacion oral për shkak të disa arsyeve:
    </div>

    <ul className="list-disc pl-16 mt-1">
      <li>Kariesi i dhëmbëve të gjerë.</li>
      <li>Dhëmbët e thyer keq.</li>
      <li>Sëmundja e fytit.</li>
      <li>Dhëmbët e penguar.</li>
      <li>Dhëmbët e munguar.</li>
      <li>Çrregullimet e nyjes temporomandibular (TMD).</li>
      <li>Humbja e kockës në këmbë.</li>
      <li>Apnea e gjumit.</li>
      <li>Tërheqja e dhëmbëve.</li>
    </ul>

    <p className="mt-5">
      Lloji më i zakonshëm i operacionit oral është tërheqja e dhëmbit (heqja e dhëmbit). Një tërheqje mund të rekomandohet nëse keni kari të rëndë të dhëmbit, sëmundje të fytit (periodontiti), traumë dentare ose komplikime të dhëmbëve të mençur. Në disa raste, tërheqjet e dhëmbëve kryhen për të ju përgatitur për proteza ose pajisje protetike të tjera.
    </p>

    <p className="mt-5">
      Shumica e dentistëve preferojnë të ruajnë dhëmbët natyralë kur është e mundur, por në disa raste tërheqjet janë të nevojshme për të ruajtur shëndetin tuaj oral në përgjithësi. Po ashtu, shumë dentistë rekomandojnë tërheqjen e dhëmbëve të mençur si masë parandaluese për të reduktuar rrezikun tuaj për kari, humbje të kockës dhe probleme të tjera.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Grefti i kockës dentare
    </div>

    <p className="mt-5">
      Grefti i kockës dentare është i nevojshëm kur ka ndodhur humbja e kockës në këmbë. Ka disa arsye pse kjo mund të ndodhë. Kur dhëmbët tuaj natyralë janë të pranishëm, rrënjët stimulojnë nervat në këmbë. Kjo njofton trurin të dërgojë ushqime në këmbë tuaj, duke e mbajtur atë të fortë dhe të shëndetshme. Nëse një dhëmb ka munguar për një kohë të caktuar, mund të ndodhë degradimi i kockës në atë zonë sepse nuk ka rrënjë për të stimuluar nervat. Grefti i kockës dentare rikthen volumin dhe dendësinë në kockën e këmbës suaj në mënyrë që implantet dentare të vendosen më vonë.
    </p>

    <p className="mt-5">
      Gjatësia e procedurës suaj do të varet nga disa faktorë, duke përfshirë llojin e operacionit oral që keni (po kryeni), sa dhëmbë po trajtohen dhe nëse zgjidhni ose jo sedacion. Tërheqja e një dhëmbi zakonisht zgjat rreth 30 minuta, ndërsa një procedurë më komplekse - si operacioni korrigjues i këmbës - zakonisht zgjat të paktën dy ose tre orë.
    </p>

    <p className="mt-5">
      Pas procedurës së operacionit oral, do t'ju jepen udhëzime të detajuara pas-operative. Është e rëndësishme që të ndiqni këto udhëzime ngushtë për të reduktuar rrezikun tuaj nga gjaku, infeksioni dhe komplikimet e tjera.
    </p>

  </section>
);

export const periodontologyParagraph = () => (
  <section>
    <p className="mt-5">
      Funksioni kryesor i periodontiumit (të dhëmbëve) është të mbajë dhëmbët në vendin e tyre brenda kockës, por gjithashtu vepron si një mburojë gjatë gllabërimit për të parandaluar dëmtimin e dhëmbëve kur marrin fuqi të tepërt. Të dhëmbët dhe indet veproni dhe punoni së bashku për të mbrojtur veten dhe për të promovuar funksionimin e duhur gjatë gllabërimit.
    </p>

    <p className="mt-5">
      Gjendja orale e një personi mund të jetë një tregues i qartë i shëndetit të tyre të përgjithshëm, në veçanti gjendja periodontale e fytit të tyre, mund t'ju tregojë shumë më shumë se thjesht ç'mund të ndodhë në nivelin e periodontologjisë. Sëmundjet sistemike (diabeti, pirja e duhanit, HIV, ...) mund të kenë një efekt të drejtpërdrejtë në strukturat që mbështesin dhëmbët. Disa patologji gjithashtu mund të zbulohen përmes gojës para se ato të jenë të dukshme në çdo pjesë tjetër të trupit. Për këtë arsye, periodontistët (të dentistët e fytit) mund të jenë të parët që zbulojnë shenjat e një sëmundjeje të përgjithshme si diabeti ose çrregullime të gjakut. Zyrja dentare ose periodontisti mund të bëhet qendër për zbulimin e hershëm të diabetit.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Ç'TË BËJNË PERIODONTISTËT?
    </div>

    <p className="mt-5">
      Periodontistët janë ata dentistë që specializohen në parandalimin dhe trajtimin e sëmundjeve që prekin periodontiumin. Të gjithë dentistët marrin trajnim në periodontologji, por një periodontist merr trajnime shtesë specifike për këtë fushë. Periodontistët me status specialist kanë marrë gradë pasuniversitare ose master në periodontologji dhe implantet dentare gjatë tre kurseve akademike dhe kohës së plotë. Prandaj është e zakonshme në praktikën e përditshme që rastet më të komplikuara trajtohen nga specialistë në këtë fushë të stomatologjisë.
    </p>

    <p className="mt-5">
      Sëmundjet periodontale të trajtuara nga periodontistët janë shumë të zakonshme në popullatë dhe janë pasojë e inflamacionit të indit periodontal (fytit). Gingiviti është reagimi inflamator i fytit për shkak të akumulimit të baktereve në dhëmbë dhe indet e buta të gojës. Gingiviti ndodh të paktën një herë në jetë te të gjithë individët. Ky përgjigje është një mekanizëm mbrojtës që trupi i njeriut ka për të luftuar kundër baktereve patogjene, por mund të shkaktojë dëme në inde nëse nuk trajtohet me saktësi. Inflamacioni mund të përhapet në fyt dhe të shkatërrojë ligamentin periodontal dhe kockën alveolare duke shkaktuar periodontitin. Ky shkakton humbjen e mbështetjes për dhëmbët dhe madje edhe humbjen e dhëmbëve kur sëmundja është në stadi më të avancuar.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      TRAJTIMI I PERIODONTITIT
    </div>

    <p className="mt-5">
      Me këshilla dhe trajtim të duhur nga profesionistët është zakonisht e mundur të ndalohet plotësisht përparimi i periodontitit. Çelësi është të eleminohet pllaka bakteriale që shpejton procesin patologjik dhe të vendosët zakon të shëndetshme të higjienës orale. Kështu, hapi i parë i trajtimit periodontal përfshin në njoftimin e pacientit me gjendjen e tij dhe në dhënien e udhëzimeve për higjienën orale. Në këtë fazë higjienike të parë, është shumë e rëndësishme të sigurohet që pacienti të përmbushë adekuatë higjienën e tij. Në anën tjetër, periodontisti do të kryejë procedurat higjienike për të eleminuar depozitat dhe pllakat bakteriale në zona që nuk janë të arritshme për pacientin. Në disa raste, mund të jepet terapi antibiotike nëse infeksioni i indeve vazhdon dhe nuk reagon ndaj masave higjienike.
    </p>

    <p className="mt-5">
      Pas kësaj faze të parë, gjendja periodontale dhe higjiena e pacientit kontrollohen pas disa javësh. Progresi i sëmundjes kontrollohet dhe bëhet një sondazh për të verifikuar thellësinë e pakove. Nëse disa pako vazhdojnë (hapësira ku bakteret kanë aftësinë për të shkatërruar fytin dhe kockën), ofrohen më shumë mundësi trajtimi, përfshirë kirurgjinë. Në disa raste është e nevojshme të kryhet një operacion që ngre fytin për të pasur mundësi për të arritur ato zona ku ka depozita dhe për të pastruar ato. Kjo ju lejon të keni një pamje të drejtpërdrejtë dhe të siguroheni që zona është plotësisht e pastër. Në raste të tjera është e mundur të kryhet kirurgji rregjenerative në të cilën humbja e kockës trajtohet dhe rregjenerohet për të tentuar të japë një anatomi më të saktë defekteve të kockës të krijuara nga periodontiti. Në raste të tjera nuk është e mundur të rregjenerohen këto defekte dhe kryhen operacione në të cilat kocka është rikonfiguruar dhe nëse është e nevojshme, edhe fyuja.
    </p>

    <p className="mt-5">
      Pas kësaj faze kirurgjikale, nëse është e nevojshme, do të kryhet një fazë restauruese. Është në këtë kohë që mund të vendosën implantet, protezat përfundimtare, trajtimi ortodontik ose mundësi të tjera të trajtimit të përshtatshme për çdo situatë individuale. Implantet mund të vendosen kur trajtimi periodontal është përfunduar.
    </p>

    <p className="mt-5">
      Fundja, suksesit të terapisë periodontale të gjatë varet nga higjiena orale e pacientit dhe mirëmbajtja profesionale, dhe këshillat nga profesionistët. Është e rëndësishme të kryhet kjo fazë mirëmbajtëse në mënyrë të rregullt për të kontrolluar gjendjen e indeve periodontale dhe për të verifikuar që inflamacioni është nën kontroll. Kështu mund të kontrollohet mirëmbajtja e rezultateve të arritura me trajtimin periodontal, të parandalohet kthimi i sëmundjes dhe zbulimi i hershëm i shfaqjes së lëndimeve të reja periodontale për të pasur mundësi për t'i trajtuar ato dhe për të informuar pacientin mbi masat higjienike që duhet të ndërmerren për të parandaluar rikthimin e sëmundjes periodontale.
    </p>
  </section>
);

export const implantologyParagraph = () => (
  <section>

    <div className="font-semibold mt-9 text-2xl">
      IMPLANTOLOGJIA, NJË DEGË E STOMATOLOGJISË E FOKUSUAR TE IMPLANTET DENTARE
    </div>

    <p className="mt-5">
      Një dhëmb natyror ka dy pjesë kryesore: Korona dhe rrënja. Korona është pjesa e dukshme e dhëmbit dhe rrënja ndodhet poshtë lëkurës së fytit, në kockën e qafës. Implantologjia është dega e stomatologjisë e dedikuar implantëve dentare. Implantët dentarë janë rrënja artificiale e dhëmbit që futet në kockën e qafës (ose në maksilën ose në mandibulën). Për shpërndarje të zakonshme ka formën e një shuruprore që është e përbërë nga titan ose nga një ndërlikim i titani.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      IMPLANTËT ZËVENDËSOJNË DHËMBËT E MUNGUAR
    </div>

    <p className="mt-5">
      Implantët dentarë mund të konsiderohen për pacientët që kanë të paktën një dhëmb të munguar.
    </p>

    <p className="mt-5">
      Vetëm një implant dentar nuk zëvendëson një dhëmb natyror. Një abutment duhet të vendoset në implant pasi ky i fundit është futur në kockën e qafës.
    </p>

    <p className="mt-5">
      Varësisht nga numri i dhëmbëve për t'u zëvendësuar, do të përgatitet dhe montohet një koronë artificiale, një protezë e plotë ose e pjesshme ose një urë mbi abutmentët. Nëse pacienti ka vetëm një dhëmb të munguar, një koronë e vetme do të jetë e mjaftueshme. Nëse duhet të zëvendësohen disa dhëmbë, ka shumë mundësi: përdorimi i një implanti të vetëm me shumë korona të bashkuara me një urë ose protezë, ose përdorimi i disa implantëve me korona të ndryshme. Kjo zgjedhje është në dorën e pacientit, sipas planit të trajtimit të rekomanduar nga specialisti i implantologjisë.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      NJË PROCEDURË AMBULANTE E KRYER NËN ANESTEZI LOKALE
    </div>

    <p className="mt-5">
      Vendosja e implantëve dentarë është një procedurë kirurgjikale që zakonisht kryhet në klinikë dhe nën anestezinë lokale.
    </p>

    <p className="mt-5">
      Disa profesionistë të shëndetit dental janë të kualifikuar për të instaluar implantë dentarë: dentistë, kirurgjentë oral dhe maksilofaciale, periodontistë dhe specialistë të tjerë në stomatologji. Këta profesionistë duhet të kenë trajnim të gjerë në implantologji. Dentistët dhe protetistët shpesh specializohen në prodhimin e koronave, urave dhe protezave që vendosen mbi implantë.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      AVANTAZHET E IMPLANTËVE DENTARË
    </div>

    <ul className="list-disc pl-16 mt-1" >
      <li>
        Kur një pacient ka humbur një ose më shumë dhëmbë, implantët dentarë kanë avantazhe të shumta në krahasim me trajtimet e tjera që përfshijnë proteza të hiqshme.
      </li>

      <li>
        Implantët lejojnë instalimin e protezave dentare të fiksuara ose të hiqshme.
      </li>

      <li>
        Ata janë më të rehatshëm dhe të diskretë sepse nuk shkaktojnë ndonjë trenjë apo lëvizje të pakëndshme në grykë ashtu si në rastin e protezave të hiqshme që nuk janë të montuara në implantë (p.sh.: proteza e pjesshme ose e plotë). Është e mundur të përdorni një protezë të mbështetur në implant që nuk e mbulon fare nepen e gojës.
      </li>

      <li>
        Nuk kërkohet ngjitës (gjumë për proteza) për të mbajtur protezën e mbështetur në implant në vend.
      </li>

      <li>
        Implantët ofrojnë një stabilitet më të mirë për dhëmbët artificialë, të cilat lehtësojnë gryqëzimin dhe tretjen, përmirësojnë komfortin dhe lehtësojnë foljen.
      </li>

      <li>
        Pacientët nuk janë të detyruar të adaptojnë dietën e tyre.
      </li>

      <li>
        Implantët lejojnë shpërndarjen e forcave të gryqëzimit midis implantëve dhe strukturave të tjera të afërta të gojës (gryka, kocka dhe dhëmbë të mbetur), duke ndihmuar në reduktimin e stresit në këto struktura. Kur dhëmbët e shëndetshëm janë ende në gojë, pacienti mund të presë që të mbajë këta dhëmbë të mbetur më gjatë sesa nëse nuk ishin instaluar implantë dhe nëse hapësira e krijuar nga dhëmbët e munguar mbetej e zbrazët.
      </li>

      <li>
        Implantët ndihmojnë në ruajtjen e kockës alveolare (kocka që e mbështet dhëmbët) duke parandaluar resorbimin e saj aty ku mungojnë dhëmbët. Faktikisht, kur një dhëmb mungon, kocka alveolare që dikur e mbështeste nuk stimulohet më dhe degradohet gradualisht.
      </li>

      <li>
        Implantët nuk kërkojnë gdherritje dhe ndryshime të dhëmbëve të afërt (si në rastin e një urë tradicionale).
      </li>

      <li>
        Implantët japin përshtypjen e të pasurit dhëmbë natyralë, estetikisht dhe funksionalisht, dhe vepruan si një rrënjë artificiale e ngjitur në kockën e qafës, ashtu si një rrënjë e dhëmbit të natyrshëm. Pacientët mund të rimarrin kështu integritetin e tipareve të tyre fytyrës që janë prekur nga humbja e disa dhëmbëve.
      </li>

      <li>
        Dy komponentët kryesorë të një implantimi (korona artificiale dhe implantati vetë) nuk preken nga kari i dhëmbëve.
      </li>

      <li>
        Pasi osseointegrimi është i plotë, implantët rrallëherë duhet të zëvendësohen dhe ofrojnë kështu një zgjidhje të besueshme dhe afatgjatë. Përkundër kostove të larta fillestare, implantimi dentar është një investim i mirë duke pasur parasysh jetëgjatësinë e tij.
      </li>
    </ul>

    <div className="font-semibold mt-9 text-2xl">
      KOSTOT E IMPLANTËVE DENTARË
    </div>

    <p>
      Kostot e implantëve dentarë ndahen në dy kategori:
    </p>

    <ul className="list-disc pl-16 mt-1" >
      <li>Kostot e kirurgjisë (kur implantët dentarë dhe abutmentët vendosen në kockën e pacientit);</li>
      <li>Kostot e rikthimit protetik (korona, ura ose proteza që do të bashkohen me abutmentin e implantit).</li>
    </ul>

  </section>
);

export const orthodonticsParagraph = () => (
  <section>
    <p className="mt-5">
      Ortodontia është dega e stomatologjisë që fokusohet në diagnozën dhe trajtimin e "grirjeve të këqija" (malokluzione). Trajtimet ortodontike të zakonshme përfshijnë aparatet ortodonteze, mbajtësit e qartë dhe riperthyesit.
    </p>

    <p className="mt-5">
      Mënyra si dhëmbët tuaj përputhen së bashku ka një ndikim të konsiderueshëm në shëndetin tuaj oral. Duke përmirësuar mënyrën se si të përputhen dhëmbët e lartë dhe të poshtëm, ju mund të zvogëloni rrezikun tuaj për një sërë problemesh të shëndetit oral, përfshirë karien, sëmundjen e fytit dhe erozionin e tepërt (shfrytëzimi i tepërt).
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Kush përfiton nga ortodontia?
    </div>

    <p className="mt-5">
      Pothuajse çdo i/e ndihmuar mund të përfitojë disa avantazhe nga ortodontia. Por disa persona kanë nevojë për trajtim më shumë se të tjerët. Shumë njerëz kërkojnë trajtim ortodontik sepse duan të përmirësojnë pamjen e tyre të buzëqeshjes. Por përveç avantazheve kozmetike, ortodontia ofron funksionim më të mirë të gryqëzimit dhe shëndet oral më të mirë.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Problemet ortodontike të zakonshme
    </div>

    <p className="mt-5">
      Llojet e problemeve ortodontike përfshijnë:
    </p>

    <ul className="list-disc pl-16 mt-1" >
      <li>
        Grirje të shpërdara, kur dhëmbët e lartë dalin përpara dhëmbëve të poshtëm.
      </li>

      <li>
        Grirje të përmbysura, kur dhëmbët e poshtëm dalin më tej se dhëmbët e lartë.
      </li>

      <li>
        Dhëmbët që mbulojnë njëri-tjetrin.
      </li>

      <li>
        Dhëmbët e mbushur.
      </li>

      <li>
        Dhëmbët e kthyer.
      </li>

      <li>
        Dhëmbët e rrotulluar.
      </li>

      <li>
        Dhëmbët e mbyllur (që janë pjesërisht ose plotësisht të kapur në kockën e qafës).
      </li>
    </ul>

    <div className="font-semibold mt-9 text-2xl">
      Aparatet ortodonteze
    </div>

    <p className="mt-5">
      Aparatet ortodonteze gradualisht zhvendosin dhëmbët tuaj në pozicionet e dëshiruara me kalimin e kohës duke përdorur një kombinim të shenjave, vathave dhe tela. Ka lloje të ndryshme të aparateve ortodonteze, në varësi të nevojave tuaja, përfshirë aparate ortodonteze metalike tradicionale dhe aparate të qarta (keramike).
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Mbajtësit e qartë
    </div>

    <p className="mt-5">
      Aparatet ortodonteze të qarta janë një alternativë e njohur për aparate. Markat e njohura përfshijnë Invisalign® dhe ClearCorrect®. Në vend që të përdorin shenja dhe tela për të drejtuar dhëmbët tuaj, këto sisteme përdorin një serë treguesh ortodonteze të qarta të bëra me porosi. Ju vishni secilën set të treguesve për një ose dy javë para se t'i zëvendësoni me setin tjetër në serinë. Me kalimin e kohës, treguesit ortodonteze të qarta zhvendosin dhëmbët tuaj në pozicionet e tyre të duhura.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Riperthyesit
    </div>

    <p>
      Nëse vishni aparate ortodonteze ose treguesit e qartë, do të keni nevojë për një riperthyes pasi të përfundojë trajtimi ortodontik. Një riperthyes është një pajisje orale e bërë me porosi që mban dhëmbët tuaj nga deriat e tyre të mos zhvendosen nga pozicionet e tyre të duhura. Ortodontisti juaj mund t'ju tregojë sa herë duhet të vishni riperthyesin për të mbajtur rezultatet e trajtimit.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Zmadhuesit e qafës
    </div>

    <p>
      Ortodontistët rekomandojnë disa trajtime gjatë fëmijërisë. Kjo është sepse kockat e fytyrës së fëmijës ende janë në zhvillim dhe janë shumë më të lehta për t'u zhvendosur dhe manipuluar. Një zmadhues i qafës ndihmon në zmadhimin e kockës së lartë të fëmijës. Shumica e fëmijëve nuk kanë nevojë për zmadhues të qafës. Por në rrethanat e duhura, këto pajisje mund të krijojnë hapësira të shtuara pa nevojën për të hequr dhëmbë ose procedura të tjera.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Cilat janë avantazhet e ortodontisë?
    </div>

    <p>
      Avantazhet kozmetike të ortodontisë janë të qarta. Por këto trajtime ofrojnë avantazhe funksionale dhe të lidhura me shëndetin gjithashtu:
    </p>

    <ul className="list-disc pl-16 mt-1" >
      <li>Kur dhëmbët tuaj janë në një përputhje të duhur, është shumë më e lehtë t'i pastroni.</li>
      <li>Drejtimi i dhëmbëve tuaj mund të përmirësojë funksionin e gryqëzimit dhe foljes.</li>
      <li>Tratamenti ortodontik mund të lehtësojë dhimbjen e lidhur me çrregullimin e TMJ (tritur shpërthyes).</li>
      <li>Një grirje e përputhur mund të zvogëlojë rrezikun tuaj për karien, sëmundjen e fytit dhe çështje të tjera të shëndetit oral.</li>
      <li>Ortodontia mund të ju ndihmojë të arrini dhe të ruani një buzëqeshje të bukur dhe të shëndetshme.</li>
    </ul>

  </section>
);

export const prostheticsParagraph = () => (
  <section>
    <p className="mt-5">
      Ka disa lloje pajisjesh të fiksuara dentare, si korona dhe implante, dhe pajisje të heqshme, përfshirë proteza të plota ose të pjesshme, të cilat mund të heqen sipas nevojës.
    </p>

    <p className="mt-5">
      Në këtë artikull, ne shqyrtojmë pesë lloje pajisjesh dentare prostetike, për të mundur të keni një diskutim të informuar me dentistin tuaj për cilën prej tyre mund të jetë më e mira për ju.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      1. Koronat dentare
    </div>

    <p className="mt-5">
      Një koronë dentare është një mbulues i ri për një dhëmb të dëmtuar.
    </p>

    <p className="mt-5">
      Një koronë mund të jetë e bërë prej metali ose porcelani. Koronat zakonisht janë zgjidhje të mira afatgjata për dhëmbët që janë thyer, shpërdarë, ose të abrazuar. Por dhëmbët që kërkojnë një shumë të madhe të rindërtimit janë në rrezik shumë më të lartë për dështim, sipas një studimi të vitit 2018 në Revistën e Stomatologjisë.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Për të marrë një koronë zakonisht kërkohet të dy takime. Në një proces të zakonshëm për një koronë dentare, një dentist do të:
    </div>

    <ul className="list-disc pl-16 mt-1" >
      <li>
        Mësoni një forme të butë të dhëmbëve tuaj.
      </li>

      <li>
        Mbushni çdo katere në dhëmbin e dëmtuar (nëse është e nevojshme).
      </li>

      <li>
        Pritni dhëmbin për të përgatitur për të pritur një koronë.
      </li>

      <li>
        Vendosni një koronë të përkohshme në dhëmb gjatë krijimit të një korone të përjetshme në një laborator.
      </li>

      <li>
        Pas disa javësh, vendosni një koronë të përjetshme që është çimentuar në vend.
      </li>

      <li>
        Praktikat dentare që mund të bëjnë korona në vend ofrojnë korona të një diteje.
      </li>
    </ul>

    <p className="mt-5">
      Koronat konsiderohen zgjidhje relativisht të përjetshme. Pasi një koronë është në vend, ajo duhet të zgjasë nga 5 deri në 15 vjet ose edhe më shumë nëse mirëmbahet si duhet. Duhet të larë dhe të pastrohet një dhëmb me një koronë ashtu si do të bënit me çdo dhëmb tjetër.
    </p>

    <p className="mt-5">
      Një koronë mund të thyhet ose të shpërthejë me kalimin e kohës, ose çimenti që e mban në vend mund të zbutet. Kjo mund të lejojë bakteret të lëvizin dhe të infektojnë dhëmbin.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      2. Ura dentare
    </div>

    <p className="mt-5">
      Kur mungojnë një ose më shumë dhëmbë, një urë dentare është një kompromis mes protezave dhe implantave.
    </p>

    <p className="mt-5">
      Siç sugjeron edhe emri, një urë dentare është e destinuar për të uruar një zbrazëti të lënë nga dhëmbët e munguar. Një urë zakonisht lidhet me dhëmbët natyralë në të dyja skajet e zbrazëtisë dhe mund të përbëhet nga një ose më shumë dhëmbë të rreme të quajtur pontikë. Në disa raste, një urë mund të lidhet me një implant për siguri dhe stabilitet më të madhe.
    </p>

    <p className="mt-5">
      Një urë zakonisht është e bërë prej porcelani për të shfaqur si dhëmbët natyralë dhe duhet të larë dhe pastrohet rregullisht. Mund të jenë të nevojshme mjetet e veçanta të pastrohjes të quajtura mbërthyes për të futur flosunë mes urës dhe vijës së fytit.
    </p>

    <p className="mt-5">
      Këtu është procesi tipik për të marrë një urë:
    </p>

    <ul className="list-disc pl-16 mt-1" >

      <li className="mt-2">
        Në takimin e parë, dhëmbët e ankandhët në të dyja anët e urës priten në formë dhe bëhet një forma e gojës suaj. Mund të bëhet një urë e përkohshme për të mbushur zbrazëtinë ndërsa bëhet një e përjetshme brenda disa javëve.
      </li>

      <li className="mt-2">
        Në takimin e dytë, ura e përjetshme vendoset në vend. Bëhen ndryshimet e nevojshme të vogla për të bërë urën të duken natyrale dhe të punojnë me gryqën tuaj natyrale. Ura pastaj çimentohet në vend.
      </li>

      <li>
        Një urë e mirë e bërë mund të zgjasë dekada, por një pritje e arsyeshme është nga 5 deri në 15 vjet.
      </li>

    </ul>

    <div className="font-semibold mt-9 text-2xl">
      3. Protezat
    </div>

    <p className="mt-5">
      Protezat janë pajisje protetike që përshtaten ngushtë me gryqën dhe duken dhe funksionojnë si dhëmbët natyralë. Ata gjithashtu quhen dhëmbë të rremë.
    </p>

    <p className="mt-5">
      Shkalla e humbjes së dhëmbëve dhe preferenca juaj do të ndihmojnë të përcaktoni cilat lloje protezash janë më të mirat për ju. Llojet më të zakonshme të protezave përfshijnë:
    </p>

    <ul className="list-disc pl-16 mt-1" >

      <li className="mt-2">
        Protezat e plota zëvendësojnë të gjithë dhëmbët tuaj dhe duhen hequr çdo ditë për pastrim.
      </li>

      <li className="mt-2">
        Protezat e pjesshme të heqshme zëvendësojnë disa, por jo të gjithë dhëmbët tuaj.
      </li>

      <li>
        Protezat fikse të pjesshme njihen gjithashtu si urat e mbështetura nga implantet.
      </li>

      <li>
        Protezat që mbahen në implant janë të fiksuara në implant, por duhen hequr çdo ditë për pastrim (njihen gjithashtu si proteza të shpejtë).
      </li>

    </ul>

    <p className="mt-5">
      Një lloj tjetër i proteze quhet 'flipper'. Këto janë proteza pjesshme të përkohshme që zëvendësojnë një ose dy dhëmbë. Ato janë bërë nga materiale më pak të qëndrueshme se protezat pjesshme të rregullta, pasi ato janë të destinuara për të përdorur për një kohë të shkurtër.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      4. Veneerat dentare
    </div>

    <p className="mt-5">
      Veneerat mund të ndihmojnë në përmirësimin e ngjyrës dhe pamjes së dhëmbëve natyralë duke i mbuluar me një kabellë të hollë, të natyrshëm në dukje.
    </p>

    <p className="mt-5">
      Këtu është procesi tipik për të marrë një veneer:
    </p>

    <ul className="list-disc pl-16 mt-1" >

      <li className="mt-2">
        Dentisti do të hiqë emanelin nga dhëmbët tuaj dhe do të marrë një forme të gojës suaj.
      </li>

      <li className="mt-2">
        Duke përdorur formën, teknikët e laboratorit do të bëjnë veneerat tuaja nga porcelani ose një material rezin-kompozit. Procesi mund të marrë disa javë.
      </li>

      <li>
        Veneerat pastaj çimentohen në dhëmbët tuaj. Një dritë ultravioletë përdoret për të shpejtuar fortimin e çimentit që mban veneerat të lidhura me dhëmbët tuaj.
      </li>

      <li>
        Ju duhet të keni dhëmbë relativisht të shëndetshëm për të mbështetur një veneer. Veneerat mund të zgjasin nga 10 deri në 15 vjet para se të duhen zëvendësuar.
      </li>
    </ul>

    <div className="font-semibold mt-9 text-2xl">
      Çfarë janë avantazhet e një pajisje dentare protetike?
    </div>

    <p className="mt-5">
      Një pajisje dentare protetike është e dobishme në sigurimin e një ose më shumë nga përfitimet e mëposhtme:
    </p>

    <ul className="list-disc pl-16 mt-1" >

      <li className="mt-2">
        Përmirësimi i pamjes së dhëmbëve tuaj, duke rritur vetëbesimin dhe vetëvlerësimin.
      </li>

      <li className="mt-2">
        Përmirësimi i aftësisë tuaj për të gryqëzuar, duke ju lejuar të konsumoni një dietë të shëndetshme.
      </li>

      <li>
        Mbështetja e shëndetit dhe fuqisë së kockës së faresë, mbështetja e fuqisë së dhëmbëve të tjerë.
      </li>

      <li>
        Mbrojtja kundër sëmundjes së fytit që mund të çojë në probleme serioze të shëndetit.
      </li>
    </ul>
  </section>
);

export const dentalAestheticsParagraph = () => (
  <section>
    <div className="font-semibold mt-9 text-2xl">
      Çfarë është Estetika Dentare?
    </div>

    <p className="mt-5">
      Në termat më të thjeshta, mund të përcaktojmë estetikën dentare si një procedurë për të përmirësuar pamjen e përgjithshme të dhëmbëve. Qëllimi kryesor i estetikës dentare është të mbajë dhëmbët të ndrequra, të përmirësojë strukturën e tyre dhe të zbukurojë ngjyrën dhe formën e tyre, në mënyrë që të mund të qeshni me vetëbesim. Estetika dentare është një masë e njëkohshme dhe e restaurueshme pasi mund të përdoret për të korrigjuar dhëmbët e humbur dhe probleme të ngjashme. Por, është e rëndësishme të theksohet se stomatologia estetike nuk është e njëjtë me stomatologjinë kozmetike.
    </p>

    <p className="mt-5">
      E para është çdo metodë e përdorur për të përmirësuar buzëqeshjen tuaj nëpërmjet trajtimeve që do të ishin të nevojshme. Së kundërmi, e dyta përfshin korrigjimin e punëve të vjetra ose ekzistuese stomatologjike si veneerat, koronat, urat, dhe kështu me radhë, të cilat mund të mos jenë veçanërisht të këndshme për sytë. Tani që kemi diskutuar bazat e estetikës dentare, le të shqyrtojmë procedurat e ndryshme për estetikën dentare.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Procedurat e Estetikës Dentare
    </div>

    <div className="font-semibold mt-9 text-2xl">
      Estetika dentare përfshin një sërë procedurash, dhe më të zakonshmet janë:
    </div>


    <p className="mt-5">
      <strong>Spërlarja: </strong> Është një procedurë estetike dentare e përdorur gjerësisht. Metoda përfshin shpërlarjen e dhëmbëve tuaj për të hequr zbukurimet e jashtme të dhëmbëve. Disa zbardhje të dhëmbëve janë pasojë e problemeve të brendshme si një dhëmb vdekjeje ose shpërdarje e emanelit dhe stomatologjia estetike korrigjon këto anomalitete. Spërlarja e dhëmbëve me laser është një procedurë moderne për zbardhjen e dhëmbëve, në të cilën fytat mbulohen me gomë dhe aplikohet peroksid hidrogjeni për zbardhjen e dhëmbëve. Më pas, një shkëlqim i laserit të argonit shpërfaqet mbi dhëmbët për të shpejtuar procesin e zbardhjes. Spërlarja e dhëmbëve me laser është gjashtë herë më efikase sesa spërlarja e zakonshme e dhëmbëve.
    </p>

    <p className="mt-5">
      <strong>Përmirësimi i Formës: </strong> Është i lidhur me ndryshimin e formës së një dhëmbi të çorientuar që kërkon heqjen e një pjese të emanelit. Përmirësimi i formës mund të përdoret për të korrigjuar çarje dhe çkrese në dhëmbë. Ai gjithashtu mund të ndryshojë pozicionin, formën, ose gjatësinë e dhëmbëve. Përdorimi tjetër i rëndësishëm i dhëmbëve është korrigjimi i një overbite ose underbite.
    </p>

    <p className="mt-5">
      <strong>Lartësimi i Fytit: </strong> Është një procedurë estetike dentare që përfshin ngritjen dhe pastaj skulptimin e linjës së fytit, në mënyrë që buzëqeshja juaj të mos duket as shumë e fytur dhe as shumë e dhëmbur. Procesi riformon indin gingival dhe kockat nën të për të krijuar një buzëqeshje më të simetrikë dhe të pastër.
    </p>

    <p className="mt-5">
      <strong>Rregullimi i Dhëmbëve: </strong> Është një procedurë estetike dentare e zakonshme në të cilën dhëmbët rregullohen gradualisht me retainera ose breza. Rregullimi ndihmon në korrigjimin e dhëmbëve të mbivendosur, të mbushur ose të larguar gjerësisht. Sot, Invisalign preferohet gjithashtu nga shumë persona sepse janë të padukshëm për sy të lirë.
    </p>

    <p className="mt-5">
      <strong>Ngjitja: </strong> Është një procedurë estetike dentare që zakonisht kryhet pas trajtimeve të tjera stomatologjike. Procesi i ngjitjes së masave rezin-kompozit të ngjashme me emanelin aplikohet në sipërfaqen e dhëmbëve dhe pastaj skulptohet në formë, forcohet dhe pllakoset.
    </p>

    <p className="mt-10">
      Prandaj, mund të themi se estetika dentare është e nevojshme për të bërë njerëzit më të sigurt dhe për të injektuar vetëbesim. Ajo ka bërë jetën e shumë njerëzve më të lehtë duke i lejuar ata të qeshin më gjerë dhe më shndritshëm.
    </p>
  </section>
);

export const pedodonticsParagraph = () => (
  <section>
    <div className="font-semibold mt-9 text-2xl">
      Avantazhet e Pedodontisë
    </div>

    <p className="mt-5">
      Zakonisht fëmijët fillojnë të marrin dhëmbët e tyre të parë në moshën gjashtë muaj. Fëmijët duhet të vizitojnë dentistin pediatër brenda gjashtë muajve nga dalja e dhëmbit të parë ose deri në moshën e një viti, çfarëdo që të vijë e para. Dentistët pediatër merren me çështje të lidhura me gojën dhe grykën dhe ndihmojnë gjithashtu në korrigjimin e problemeve si gëlltitja e gishtit të madh. Mësoni avantazhet e vizitës te dentisti pediatër. Këto avantazhe ndihmojnë të dy fëmijët dhe prindërit.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Mbani fëmijët në qetësi
    </div>

    <p className="mt-5">
      Fëmijët shpesh janë të nervozuar në lidhje me vizitën te dentisti dhe mjeku. Një dentist pediatër është i trajnuar për të mbajtur fëmijët në qetësi. Dentisti përdor një set aftësish të veçanta për të bërë fëmijët të ndihen rehat. Kjo krijon një marrëdhënie të mirëbesimit midis fëmijëve dhe dentistit.
    </p>

    <p className="mt-5">
      Stafi në zyrën e dentistit pediatër gjithashtu është i trajnuar për të punuar me fëmijët. Stafi krijon një mjedis argëtues dhe të rehatshëm për pacientët. Zyrja mund të përfshijë gjithashtu aktivitete argëtuese për fëmijët. Kjo i mbajtë mendjet e fëmijëve të zënë para dhe gjatë terminit të vizitës dentare.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Mësojnë teknikat e duhura të higjienës orale për fëmijët
    </div>

    <p className="mt-5">
      Është e vështirë për prindërit të mësojnë fëmijët e tyre si të lëpirin dhe të fërkojnë dhëmbët në mënyrë të duhur. Fëmijët mund të mos kujdesen për pjesët e dhëmbëve, duke i lënë ata të ndodhen në rrezik të thithjes. Dentistët e trajnuar në pedodonti mësojnë fëmijët teknikat e duhura të higjienës orale. Dentistët gjithashtu japin prindëve këshilla për të bërë kujdesin orar në shtëpi më të lehtë. Këto këshilla mund të bëjnë madje edhe larjen dhe fërkonin e dhëmbëve të këndshme për fëmijët.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Një qasje holistike për kujdesin dentar
    </div>

    <p className="mt-5">
      Dentistët pediatër kanë trajnimin për të ndjekur një qasje holistike për kujdesin dentar. Dentistët shqyrtojnë faktorët mjedisorë që ndikojnë në dhëmbë, duke përfshirë ushqimet dhe pije të konsumuara. Këta dentistë gjithashtu marrin parasysh faktorët e rrezikut si përdorimi i bllokut gishtit dhe sjelljet e gëlltitjes së gishtit. Në të njëjtën kohë, dentistët pediatër ofrojnë pastrime dentare, trajtime me fluor dhe shërbime të tjera dentare për të mbajtur buzëqeshjet e shëndetshme. Kjo qasje holistike siguron që fëmija të marrë kujdesin e duhur.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Sigurojini zhvillimin e duhur të gojës
    </div>

    <p className="mt-5">
      Pasi dentistët pediatër janë të trajnuar për kujdesin oral për fëmijët, ata kërkojnë për çështje zhvillimore. Dentistët sigurojnë që goja po zhvillohet në mënyrë të duhur dhe dhëmbët dalin në kohën e duhur. Kur dentistët vërejnë çështje, ata fillojnë një plan trajtimi. Trajtimi i hershëm promovon zhvillimin e shëndetshëm. Zhvillimi i duhur oral është i rëndësishëm për fjalën dhe ushqimin.
    </p>
  </section>
);

export const dentalHygieneParagraph = () => (
  <section>
    <div className="font-semibold mt-9 text-2xl">
      Sëmundjet e dhëmbëve dhe i fytit shkaktuara nga higjiena e dobët dentare
    </div>

    <p className="mt-5">
      Shkaku kryesor i sëmundjeve të dhëmbëve dhe fytit është higjiena personale e dobët orale. Sëmundjet shkaktuara nga higjiena orale e dobët mund të parandalojnë duke vizituar rregullisht dentistin për ekzaminime parandaluese dhe një higjienist oral, i cili jo vetëm kryen higjienë orale profesionale, por gjithashtu sugjeron mjetet e nevojshme për kujdesin e duhur dentar në shtëpi. Ngëlliri i pasaktë i ushqimit, konsumimi i shpeshtë i ushqimeve të buta dhe mungesa e kripës në trup gjithashtu mund të kontribuojnë në fillimin e sëmundjeve të dhëmbëve dhe fytit.
    </p>

    <p className="mt-5">
      Higjiena orale profesionale rekomandohet dy herë në vit. Pacientët me implantë, proteza të fiksuara, duhet të shkojnë te higjienisti çdo 6 muaj. Pacientët që vënë brenda breket dentare duhet të përdorin procedurat e higjienës orale profesionale çdo 4 muaj ose sipas rekomandimit të higjienistit oral.
    </p>

    <p className="mt-5">
      Higjiena orale profesionale kryhet gjithmonë para mbushjeve estetike, protezave të perhershme dhe ndërhyrjeve kirurgjikale.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      HEQJA E TARTARIT
    </div>

    <div className="font-semibold mt-9 text-2xl">
      Çfarë është?
    </div>

    <p className="mt-5">
      Higjiena orale profesionale heq plakun dentar të butë dhe të fortë duke përdorur mjete speciale në zyrën e higjienistit oral. Plaku dentar është një nga shkaqet më të zakonshme të inflamacionit të fytit, kariesit të parakohshëm, periodontitis (e njohur më mirë si sëmundja parodonte), dhe humbjes së hershme të dhëmbëve, kështu që duhet të hiqet në kohë.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Sëmundjet e dhëmbëve dhe i fytit shkaktuara nga higjiena e dobët dentare
    </div>

    <p className="mt-5">
      Shkaku kryesor i sëmundjeve të dhëmbëve dhe fytit është higjiena personale e dobët orale. Sëmundjet shkaktuara nga higjiena orale e dobët mund të parandalojnë duke vizituar rregullisht dentistin për ekzaminime parandaluese dhe një higjienist oral, i cili jo vetëm kryen higjienë orale profesionale, por gjithashtu sugjeron mjetet e nevojshme për kujdesin e duhur dentar në shtëpi. Ngëlliri i pasaktë i ushqimit, konsumimi i shpeshtë i ushqimeve të buta dhe mungesa e kripës në trup gjithashtu mund të kontribuojnë në fillimin e sëmundjeve të dhëmbëve dhe fytit.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Kur Rekomandohet Higjiena Orale?
    </div>

    <p className="mt-5">
      Higjiena orale profesionale rekomandohet dy herë në vit. Pacientët me implantë, proteza të fiksuara, duhet të shkojnë te higjienisti çdo 6 muaj. Pacientët që vënë brenda breket dentare duhet të përdorin procedurat e higjienës orale profesionale çdo 4 muaj ose sipas rekomandimit të higjienistit oral.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Metoda "Air-Flow" për higjienën orale
    </div>

    <p className="mt-5">
      Rryma e fortë e ajrit, ujit dhe pulës së karbonatit të kalciumit mjekësor pastrojnë shpejt dhe pa dhimbje plakun dentar. Ky metodë nuk dëmton mënyrën e fortë të dhëmbëve dhe fytit, sepse sipërfaqja e dhëmbit trajtohet nga largësia.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Pastrimi i Tarteve Dentare
    </div>

    <p className="mt-5">
      Higjienisti oral pastron plakun mineralizuar duke përdorur një pastrues ultrasonik dhe mjete dore të nevojshme. Të gjitha sipërfaqet e dhëmbëve dhe mbushjet pastrohen me paste. Plaku i butë dhe i pikëzuar hiqet me abrasion të ajrit duke përdorur pulën e karbonatit të kalciumit (më njohur si sodë buke).
    </p>
  </section>
);

export const facialAestheticsParagraph = () => (
  <section>
    <p className="mt-5">
      Estetika e fytyrës përkon me një lloj procedure jo kirurgjikale dhe minimsalisht invazive që mund të zvogëlojë përkohësisht rrudhat ose të rikthejë volumin. Zonat e trajtimit të zakonshme përfshijnë ballin, faqet dhe buzët.
      <br/>
      Estetika e fytyrës është një term i gjerë që mund të referohet në injeksione kundra rrudhave, mbushës të lëkurës dhe mbushës të buzëve. Ky disiplinë përfshin adresimin e shqetësimeve kyçe të fytyrës, përfshirë humbjen e volumit, rrudhat ose rrudhat e holla.
    </p>

    <p className="mt-5">
      Këto rezultate nuk do të zgjasin përjetësisht dhe në fund të fundit do të zhduken, por individët mund të zgjedhin t'i "ri-mbushin" ato rregullisht nëse dëshirojnë të ruajnë rezultatet. Gjatë konsultës fillestare me ekipin tonë të estetikës së fytyrës, mund t'ju ndihmojmë të identifikoni shqetësimet kryesore dhe trajtimet që do të ofrojnë rezultatet më të mira. Pastaj, mund të formulojmë një plan trajtimi që punon për ju.
    </p>

    <p className="mt-5">
      Kur vendosni të nënshtroheni trajtimeve të estetikës së fytyrës, është e rëndësishme të keni pritshmëri realiste për atë çfarë mund të arrihet. Ne do t'ju ndihmojmë të menaxhoni pritshmëritë tuaja duke ofruar një përfaqësim realist të atij çfarë çdo trajtim mund të arrijë dhe sa kohë do të zgjasë. Ne gjithashtu do t'ju këshillojmë për trajtimet alternative nëse mendojmë se mund të arrijmë rezultate më të mira me një metodë tjetër.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Opsione për estetikën e fytyrës
    </div>

    <p className="mt-5">
      Ka tre opsione kryesore të trajtimit të disponueshëm për estetikën e fytyrës. Banorët e Rajlit mund të zgjedhin ndërmjet injeksioneve kundra rrudhave, mbushësve të lëkurës dhe mbushësve të buzëve. Le të eksplorojmë më shumë secilën opsion të trajtimit në detaje.
    </p>

    <p className="mt-5">
      Injeksionet kundra rrudhave përdoren zakonisht për të relaksuar muskujt në fytyrë që lëkundeni më lart lëkura e sipërfaqja duket më e pjekur. Kjo mund të ndihmojë në adresimin e rrudhave të thella dhe rrudhave të holla. Zonat e trajtimit të zakonshme përfshijnë ballin, rreth syve, anët e hundës dhe çfarën. Ky trajtim mund të ndihmojë gjithashtu në adresimin e shqetësimeve si buzët e lartë. Rezultatet janë të dukshme pas rreth një jave dhe mund të zgjasin rreth 3-4 muaj, por kjo ndryshon për njerëz të ndryshëm.
    </p>

    <p className="mt-5">
      Mbushësit e lëkurës përdoren për të rikthyer volumin në fytyrë. Ata përmbajnë një substancë të quajtur acidi hialuronik që ndihmon në mbajtjen e ujit. Ky material ndodh natyrshëm në trup dhe degradohet me kohë. Mbushësit e lëkurës mund të përdoren për të mbushur zbraztirat nën sytë ose në tempuj. Ata gjithashtu mund të përdoren për të krijuar më shumë definicion në format tuaja, duke përfshirë hundën, çfarën dhe faqet. Rezultatet janë të dukshme menjëherë dhe mund të zgjasin deri në 6 muaj.
    </p>

    <p className="mt-5">
      Mbushësit e buzëve janë të ngjashëm me mbushësit e lëkurës, por përdoren për të zvogëluar buzët. Buzët humbasin volum me moshën dhe buzët më të trasha mund të krijojnë një pamje më të re. Mbushësit e buzëve mund të krijojnë një zvogëlim të përulur, të ndryshojnë formën e buzëve tuaja, ose të krijojnë një transformim dramatik. Rezultatet janë të dukshme menjëherë dhe mund të zgjasin deri në 6 muaj.
    </p>
  </section>
);

export const footerSection = {
  navigation: {
    solutions: [
      { name: 'Marketing', href: '#' },
      { name: 'Analitika', href: '#' },
      { name: 'Tregtia', href: '#' },
      { name: 'Informacione', href: '#' },
    ],
    support: [
      { name: 'Caktimi i çmimeve', href: '#' },
      { name: 'Dokumentacioni', href: '#' },
      { name: 'Udhëzues', href: '#' },
      { name: 'Statusi i API', href: '#' },
    ],
    company: {
      title: 'Kompania',
      data: [
        { name: 'Rreth nesh', href: '#' },
        { name: 'Blogu', href: '#' },
        { name: 'Punët', href: '#' },
        { name: 'Media', href: '#' },
        { name: 'Partnerët', href: '#' },
      ],
    },
    legal: {
      title: 'Ligjor',
      data: [
        { name: 'Pretendimi', href: '#' },
        { name: 'Privatësia', href: '#' },
        { name: 'Kushtet', href: '#' },
      ],
    },
    social: {
      title: 'Sociale',
      data: [
        {
          name: 'Facebook',
          href: '#',
          icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
              <path
                fillRule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
              />
            </svg>
          ),
        },
        {
          name: 'Instagram',
          href: '#',
          icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
              <path
                fillRule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clipRule="evenodd"
              />
            </svg>
          ),
        },
        {
          name: 'Twitter',
          href: '#',
          icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
              <path
                fillRule="evenodd"
                d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
              />
            </svg>
          ),
        },
        {
          name: 'GitHub',
          href: '#',
          icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
              <path
                fillRule="evenodd"
                d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                clipRule="evenodd"
              />
            </svg>
          ),
        },
        {
          name: 'YouTube',
          href: '#',
          icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
              <path
                fillRule="evenodd"
                d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
                clipRule="evenodd"
              />
            </svg>
          ),
        },
      ],
    },
  },
  logo: {
    imgSrc: "/assets/dental-logo.png",
  },
  header: () => (
    <>
      Duke bërë botën një vend më të mirë përmes ndërtimit të hierarkive elegante.
    </>
  ),
  rightReserved: () => (
    <>&copy; 2020 Kompania juaj, Inc. Të gjitha të drejtat e rezervuara.</>
  ),
};

