import React, { useContext } from "react";
import { HeroSection } from "../section/HeroSection";
import { RoundedImageSection } from "../section/RoundedImageSection";
import { MultiValueSection } from "../section/MultiValueSection";
import { I18NContext } from "../App";


export const About = () => {
  const { data } = useContext(I18NContext);
  return (
    <div className="bg-gray-200 pb-6">
      <main className="isolate">
        <HeroSection />
        <RoundedImageSection />
        <MultiValueSection {...data.pages.about} subsection={"partners"} />
      </main>
    </div>
  );
};
