import React, { createContext, useState } from "react";
import "./App.css";
import { Nav } from "./Nav";
import { Home } from "./pages/Home";
import { FooterSection } from "./section/FooterSection";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { About } from "./pages/About";
import { Gallery } from "./pages/Gallery";
import { BookNow } from "./pages/BookNow";
import { createServiceFactory } from "./pages/Services";
import { i18n } from "./utils/data/data";
import { serviceNavs } from "./utils/links";
import { Tourism } from "./pages/Tourism";
import ScrollToTop from "react-scroll-to-top";

export const I18NContext = createContext();

export const App = () => {
  const getLan = () => {
    return localStorage.getItem("I18N") || "en";
  };

  const [lan, setLan] = useState(getLan());

  const setLanguage = (lan) => {
    localStorage.setItem("I18N", lan);
    setLan(lan);
  };

  return (
    <I18NContext.Provider value={{
      selector: lan,
      data: i18n[lan],
      setter: setLanguage,
    }}>
      <BrowserRouter>
        <main>
          <ScrollToTop style={{
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
          }} smooth color="#111827" />
          <Nav />
          <Routes>
            <Route path={"/home"} Component={Home} />
            <Route path={"/about"} Component={About} />
            <Route path={"/gallery"} Component={Gallery} />
            {serviceNavs.map((route) => (
              <Route
                key={route.href} // Unique key prop
                path={`/services/${route.href}`}
                Component={createServiceFactory(route.href)}
              />
            ))}
            <Route path={"/book-now"} Component={BookNow} />
            <Route path={"/dental-tourism"} Component={Tourism} />
            <Route index Component={Home} />
          </Routes>
          <FooterSection />
        </main>
      </BrowserRouter>
    </I18NContext.Provider>
  );
};

export default App;
