import React from "react";
import {
  dentalAestheticsParagraph,
  dentalHygieneParagraph,
  facialAestheticsParagraph,
  implantologyParagraph,
  oralSurgeryParagraph,
  orthodonticsParagraph,
  pedodonticsParagraph,
  periodontologyParagraph,
  prostheticsParagraph,
} from "./paragraphs";

/**
 * <Section data>
 * */
export const valueSection = {
  stats: [
    { label: "Founded", value: "2017" },
    { label: "Employees", value: "10" },
    { label: "Countries", value: "13" },
    { label: "Procedures", value: "70" },
    { label: "Patients", value: "2232" },
  ],
  quote: "We understand the importance of choosing a dental office where you feel welcome and safe. We want our patients to feel that they are getting the best possible dental care in a relaxed atmosphere. We are here to help them realize they are making the best investment possible...A bright,beautiful smile!",
  linkToAbout: () => (<>Learn more about our company <span aria-hidden="true">&rarr;</span></>),
  paragraph: {
    header: "Professional values ",
    title: "On a mission to create an elite project, to see the generation of families and value the trust that our patients have placed in us.",
    list: [
      {
        body: () => (<>
          We have developed long term relationship with our patients. Patients come in our clinic not only to fix their
          teeth or to change their smile but also to do regular visits to ensure continuity of care.
        </>),
      },
      {
        body: () => (<>Our team in focused on one thing...</>),
      },
      {
        body: () => (<>ONLY YOU! Every member of our team is focused in your unique patient experience,and not also in
          your dental care. We will study in details your mouth and smile condition,will give you the best options to
          change your smile and will make you the treatment more comfortable and pleasant. You will have also someone to
          talk in your native language,and to explain you everything.
          Our staff will treat you like family.
        </>),
      },
    ],
  },
};

export const testimonialSection = {
  header: "Testimonials",
  title: "We have helped thousands of amazing people",
};

export const pictureWallSection = {
  imgSrc: "/assets/home/home.jpg",
  header: "Ciao Dental Albania",
  text: () => (<>
    We're committed to responsible, sustainable and ethical service.
    Our long-term lasting allows us to focus only on quality.
    We are doing our best to create a natural beauty including your smile and face.
  </>),
  link: {
    text: "Read my story",
    href: "/about",
  },
};

export const footerSection = {
  logo: {
    imgSrc: "/assets/dental-logo.png",
  },
  schedule: {
    businessHours: "Business Hours",
    monday: {
      title: "Monday",
    },
    tuesday: {
      title: "Tuesday",
    },
    wednesday: {
      title: "Wednesday",
    },
    thursday: {
      title: "Thursday",
    },
    friday: {
      title: "Friday",
    },
    saturday: {
      title: "Saturday",
    },
    sunday: {
      title: "Sunday",
      value: "Closed",
    },
  },
  rightReserved: () => (<div className="inline-flex justify-between w-full">
    <span> &copy; {new Date().getFullYear()} Ciao Dental Albania. All rights reserved.</span>
    <span> Created by <a href="https://www.linkedin.com/in/denisakita/" target="_blank">Denisa Kita</a> </span>
  </div>),
};

/**
 * </Section data>
 * */

/**
 * <Page data>
 * */

export const services = {
  "oral-surgery": {
    imgCount: 4,
    pictureWall: {
      imgSrc: "/assets/services/oral-surgery/title.jpg",
      header: "Oral Surgery",
      text: () => (<>
        Oral surgery refers to any surgical procedure performed on your teeth, gums, jaws or other oral structures. This
        includes extractions, implants, gum grafts and jaw surgeries. Oral surgery is usually performed by an oral and
        maxillofacial surgeon or a periodontist. These are dental specialists who have advanced training in oral surgery
        procedures.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "oral-surgery",
    header: "Oral Surgery",
    paragraph: oralSurgeryParagraph,
    subs: {
      header: "Sub-Services",
      data: [
        { id: 1, content: "Simple extractions" },
        { id: 2, content: "Sugical extractions level 1" },
        { id: 3, content: "Surgical extractions level 2" },
        { id: 4, content: "Wisdom tooth extractions" },
        { id: 5, content: "Roots of teeth extractions" },
        { id: 6, content: "Extractions of retained teeth" },
        { id: 7, content: "Correction of double lip" },
        { id: 8, content: "Surgery of cysts" },
        { id: 9, content: "Sinus lift" },
      ],
    },
  },
  "periodontology": {
    imgCount: 5,
    pictureWall: {
      imgSrc: "/assets/services/periodontology/title.jpg",
      header: "Periodontology",
      text: () => (<>
        Periodontology is the study of a specialized system of hard (bone) and soft tissue (gingiva) that supports the
        teeth and maintains its position in the maxilla and mandible (bones of the skull). This set of tissues is called
        periodontium and is composed of alveolar bone, root cement, periodontal ligament and gingiva. The teeth are
        inside the alveolar bone and are supported by the periodontal ligament that joins the cement that covers the
        root of the tooth. These living tissues can adapt to different changes in the mouth that allow to maintain the
        position of the teeth in a stable manner.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "periodontology",
    header: "Periodontology",
    paragraph: periodontologyParagraph,
    subs: {
      header: "Sub-Services",
      data: [
        { id: 1, content: "Total scaling and root planing" },
        { id: 2, content: "Partially scaling and root planing " },
        { id: 3, content: "Surgical scaling and root planing" },
        { id: 4, content: "Correction of gummy smile" },
        { id: 5, content: "Plastic surgery of gum" },
      ],
    },
  },
  "implantology": {
    imgCount: 5,
    pictureWall: {
      imgSrc: "/assets/services/implantology/title.jpg",
      header: "Implantology",
      text: () => (<>
        The dental implant is an artificial tooth root that is inserted into the jawbone (either the maxilla or the
        mandible). It often takes the form of a screw that is composed of titanium or titanium alloy.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "implantology",
    header: "Implantology",
    paragraph: implantologyParagraph,
    subs: {
      header: "Sub-Services",
      data: [
        { id: 1, content: "Straumann Implant" },
        { id: 2, content: "Schutz implant" },
        { id: 3, content: "Eurotecnica implant" },
        { id: 4, content: "Swiss implant" },
      ],
    },
  },
  "orthodontics": {
    imgCount: 7,
    pictureWall: {
      imgSrc: "/assets/services/orthodontics/title.jpg",
      header: "Orthodontics",
      text: () => (<>
        Orthodontics is a dental specialty focused on aligning your bite and straightening your teeth. You might need to
        see an orthodontist if you have crooked, overlapped, twisted or gapped teeth. Common orthodontic treatments
        include traditional braces, clear aligners and removable retainers.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "orthodontics",
    header: "Orthodontics",
    paragraph: orthodonticsParagraph,
    subs: {
      header: "Sub-Services",
      data: [
        { id: 1, content: "Orthodontic study" },
        { id: 2, content: "Traditional metallic braces" },
        { id: 3, content: "Ceramic braces" },
        { id: 4, content: "Dental aligners" },
        { id: 5, content: "Space maintainers" },
        { id: 6, content: "Jaw repositioning aligners" },
        { id: 7, content: "Lip and cheek bumpers" },
        { id: 8, content: "Palatal expander" },
        { id: 9, content: "Removable retainers" },
        { id: 10, content: "Headgear" },
      ],
    },
  },
  "prosthetics": {
    imgCount: 5,
    pictureWall: {
      imgSrc: "/assets/services/prosthetics/title.jpg",
      header: "Prosthetics",
      text: () => (<>
        Dental prosthesis devices replace or repair missing or damaged teeth. They can do much more than improve your
        smile. A missing tooth can affect your ability to chew and raise your risk of gum disease. A missing tooth can
        also affect the bone health of the jaw and put other teeth at risk.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "prosthetics",
    header: "Prosthetics",
    paragraph: prostheticsParagraph,
    subs: {
      header: "Sub-Services",
      data: [
        { id: 1, content: "Crown over natural teeth" },
        { id: 2, content: "Metal" },
        { id: 3, content: "Zirconia" },
        { id: 4, content: "Metal porcelain " },
        { id: 5, content: "Full Zirconia" },
        { id: 6, content: "Emax" },
        { id: 7, content: "Veneers" },
        { id: 8, content: "Inlay/Onlay" },
        { id: 9, content: "Paladium" },
        { id: 10, content: "Crown over implants" },
        { id: 11, content: "Metal porcelain" },
        { id: 12, content: "Zirconia" },
        { id: 13, content: "Full Zirconia" },
        { id: 14, content: "Dentures" },
        { id: 15, content: "Partial acrylic dentures" },
        { id: 16, content: "Partial metal dentures" },
        { id: 17, content: "Partial porcelain dentures" },
        { id: 18, content: "Total denture" },
        { id: 19, content: "All on 2 (acrylic resin/metal-porcelain/zirconia)" },
        { id: 29, content: "All on 4 (acrylic resin/metal-porcelain,zirconia)" },
        { id: 21, content: "All on 6 (acrylic resin/metal-porcelain/zirconia)" },
      ],
    },
  },
  "dental-aesthetic": {
    imgCount: 6,
    pictureWall: {
      imgSrc: "/assets/services/dental-aesthetic/title.jpg",
      header: "Dental aesthetics",
      text: () => (<>
        The primary purpose of dental aesthetics is to keep the teeth aligned, improve their structure, and beautify
        their color and shape so that you can smile confidently. Dental aesthetics is both a preventive and a
        restorative measure as it can be utilized to correct missing teeth and other similar problems.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "dental-aesthetic",
    header: "Dental aesthetics",
    paragraph: dentalAestheticsParagraph,
    subs: {
      header: "Sub-Services",
      data: [
        { id: 1, content: "Dental cleaning" },
        { id: 2, content: "Total mouth hygiene " },
        { id: 3, content: "Air flow" },
        { id: 4, content: "Dental fluoridation " },
        { id: 5, content: "Cleaning with bleaching toothpaste" },
        { id: 6, content: "Air flow" },
        { id: 7, content: "Dental fluoridation " },
        { id: 8, content: "Cleaning with bleaching toothpaste" },
        { id: 9, content: "Dental bleaching" },
        { id: 10, content: "Opalesence to go" },
        { id: 11, content: "Teeth filling Gr.2" },
        { id: 12, content: "Teeth filling Gr.3" },
        { id: 13, content: "Teeth filling Gr.4" },
        { id: 14, content: "Aesthetic fillings" },
        { id: 15, content: "Composite veneers" },
      ],
    },
  },
  "pediatric-dentistry": {
    imgCount: 7,
    pictureWall: {
      imgSrc: "/assets/services/pediatric-dentistry/title.jpg",
      header: "Pediatric Dentistry",
      text: () => (<>
        Pedodontics is a specialty focused on diagnosing and treating children’s dental issues. A pediatric dentist
        works with children starting in infancy through young adulthood. The pediatric dentist helps children achieve
        and maintain good oral health. Learn more about pediatric dentistry to understand why it is important for your
        child’s oral health.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "pediatric-dentistry",
    header: "Pediatric Dentistry",
    paragraph: pedodonticsParagraph,
    subs: {
      header: "Sub-Services",
      data: [
        { id: 1, content: "Cleaning and hygiene" },
        { id: 2, content: "Fluoridation " },
        { id: 3, content: "Dental sealants" },
        { id: 4, content: "Teeth filling Gr.2" },
        { id: 5, content: "Teeth filling Gr.3" },
        { id: 6, content: "Treatment of root canal infections" },
      ],
    },
  },
  "dental-hygiene": {
    imgCount: 6,
    pictureWall: {
      imgSrc: "/assets/services/dental-hygiene/title.jpg",
      header: "Dental Hygiene",
      text: () => (<>
        Professional dental hygiene removes soft and hard dental plaque using special instruments in the oral
        hygienist’s office. Dental plaque is one of the most common causes of gum inflammation, untimely caries,
        periodontitis (better known as periodontal disease), and early tooth loss, so it must be removed on time.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "dental-hygiene",
    header: "Dental Hygiene",
    paragraph: dentalHygieneParagraph,
    subs: null,
  },
  "facial-aesthetics": {
    imgCount: 6,
    pictureWall: {
      imgSrc: "/assets/services/facial-aesthetics/title.jpg",
      header: "Facial Aesthetics",
      text: () => (<>
        Fine lines, wrinkles and loss of volume in your face and lips might leave you feeling self-conscious about
        ageing. With facial aesthetics treatment, you can turn back the clock and rejuvenate your skin with just a few
        injections.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "facial-aesthetics",
    header: "Facial Aesthetics",
    paragraph: facialAestheticsParagraph,
    subs: {
      header: "Sub-Services",
      data: [
        { id: 1, content: "Consult" },
        { id: 2, content: "Deep facial cleaning" },
        { id: 3, content: "Deep body cleaning (neck,shoulders,back)" },
        { id: 4, content: "Retinoid therapy" },
        { id: 5, content: "Protenius lifting therapy" },
        { id: 6, content: "Caviar nourishing therapy" },
        { id: 7, content: "Phytopeeling Apocalypsis therapy" },
        { id: 8, content: "Thavma therapy" },
        { id: 9, content: "Chronos therapy" },
        { id: 10, content: "Opsis therapy" },
        { id: 11, content: "Sublime skin Full Face&Neck" },
        { id: 12, content: "Skin Regimen Detox& Antipollution" },
        { id: 13, content: "Renight Recover Touch" },
        { id: 14, content: "Profhilo by IBSA" },
        { id: 15, content: "PRX-Reverse Melasma" },
        { id: 16, content: "Fillmed total treatments face-eye-neck" },
        { id: 17, content: "Bright and light peeling by Fillmed" },
        { id: 18, content: "Apriline mezotherapy-Swiss" },
        { id: 19, content: "TKN HA 3 by Toskani total face" },
        { id: 20, content: "TKN mesotherapy by Toskani" },
        { id: 21, content: "Double chin mesotherapy" },
        { id: 22, content: "Purifying mask treatments & light emitting diode therapy" },
        { id: 23, content: "Cosmo Peel Forte" },
        { id: 24, content: "BioReTherapy Biohasic Tech" },
        { id: 25, content: "Skintech RRS HA Long Lasting" },
        { id: 26, content: "JPX 3 bio rivitalising" },
        { id: 27, content: "Sunekos 200" },
        { id: 28, content: "Ejal 40 Revitalisation Far Hyaluronic Acid Skin Booster" },
        { id: 29, content: "Nithya face&body Collagen Injectable" },
        { id: 30, content: "Full botox" },
        { id: 31, content: "Eyes botox" },
        { id: 32, content: "Treatment of hyperhidrosis" },
        { id: 33, content: "Dermal fillers" },
      ],
    },
  },
};


/**
 * </Page data>
 * */

