import React, { useContext } from "react";
import { PictureWallSection } from "../section/PictureWallSection";
import { ServicesSection } from "../section/ServicesSection";
import { ValuesSection } from "../section/ValueSection";
import { TestimonialSection } from "../section/TestimonialsSection";
import { I18NContext } from "../App";
import { PartnerSection } from "../section/PartnerSection";

export const Home = () => {
  const { data } = useContext(I18NContext);
  return (
    <main>
      <PictureWallSection {...data.sections.pictureWall} />
      <ServicesSection data={data.pages.services} />
      <ValuesSection {...data.sections.values} />
      <PartnerSection />
      <TestimonialSection {...data.sections.testimonial} />
    </main>
  );
};
