import React from "react";

const imagMatrix = [12];

export const RoundedImageSection = () => {
  return (
    <div>
      {imagMatrix.map((num, index) => (
        <div className="mt-32 sm:mt-40 xl:mx-auto xl:max-w-7xl xl:px-8" key={index}>
          <img
            src={`/assets/about/${num}.jpg`}
            alt=""
            className="aspect-[5/2] w-full object-cover xl:rounded-3xl"
          />
        </div>
      ))}
    </div>
  );
};
