import React, { useContext, useEffect, useState } from "react";
import { I18NContext } from "../App";

export const Gallery = () => {

  const [images, setImages] = useState([]);
  const { data } = useContext(I18NContext);

  useEffect(() => {
    const newImages = [];
    for (let i = 1; i < 41; i++) {
      newImages.push({
        id: i,
        alt: `Gallery image ${i}`,
        src: `/assets/gallery/${i}.jpg`,
      });
    }

    setImages(newImages);
  }, []);

  return (
    <div className="bg-gray-200">
      <main
        className="mx-auto max-w-2xl px-4 py-24 sm:px-6 lg:max-w-7xl lg:px-8"
        aria-labelledby="order-history-heading"
      >
        <div className="max-w-xl">
          <h1
            id="order-history-heading"
            className="text-3xl font-bold tracking-tight text-gray-900"
          >
            {data.pages.gallery.title}
          </h1>
        </div>

        <div
          className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
          {images.map((img) => (
            <div key={img.id}
                 className="group relative w-280 h-300 min-w-280 max-w-280 min-h-300 max-h-300 justify-center inline-flex flex-vertical">
              <div
                className={"border-2 aspect-h-1 aspect-w-1 overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 items-center flex"}>
                <div style={{
                  backgroundImage: `url('${img.src}')`,
                }} className="absolute overflow-hidden z-0 w-full h-full blur bg-no-repeat bg-cover " />
                <img
                  src={img.src}
                  alt={img.alt}
                  className="z-10 object-center w-280 h-300 min-w-280 max-w-280 min-h-300 max-h-300"
                />
              </div>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};
