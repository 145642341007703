import React, { useContext, useState } from "react";
import { EmailIcon, FacebookIcon, InstagramIcon, WhatsappIcon } from "../utils/svg";
import { I18NContext } from "../App";
import axios from "axios";

const validations = {
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  phone: /^(\+\d{1,3}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gm,
  firstName: 50,
  lastName: 50,
  message: 500,
  files: 5242880,
};

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  message: "",
  files: [],
};

export const BookNow = () => {
  const { data } = useContext(I18NContext);
  const { title, form: { labels, attach, button } } = data.pages.bookNow;

  const [errors, setErrors] = useState({});

  const [values, setValues] = useState(initialValues);

  const onSubmit = (e) => {
    e.preventDefault();
    for (const [key, value] of Object.entries(values)) {
      validateValueChanges(key, value);
    }

    if (Object.values(errors).filter(f => !!f).length) {
      return;
    }

    const formData = new FormData();

    for (const [key, value] of Object.entries(values)) {
      if (key === "files") continue;
      formData.append(key, value);
    }

    if (values.files) {
      Object.values(values.files).forEach((file) => {
        formData.append("files", file);
      });
    }

    axios({
      method: "post",
      url: "https://us-central1-dental-7aa46.cloudfunctions.net/yourFunctionName/save",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function(response) {
        //handle success
        setValues(initialValues);
        setErrors({});
      })
      .catch(function(response) {
        //handle error
        console.log(response);
      });
    e.currentTarget.reset();
  };

  const validateValueChanges = (key, value) => {
    switch (key) {
      case "firstName":
        if (value.length > validations.firstName) {
          setErrors(prev => ({ ...prev, firstName: true }));
        } else {
          setErrors(prev => ({ ...prev, firstName: false }));
        }
        break;
      case "lastName":
        if (value.length > validations.lastName) {
          setErrors(prev => ({ ...prev, lastName: true }));
        } else {
          setErrors(prev => ({ ...prev, lastName: false }));
        }
        break;
      case "email":
        if (!validations.email.test(value)) {
          setErrors(prev => ({ ...prev, email: false }));
        } else {
          setErrors(prev => ({ ...prev, email: true }));
        }
        break;
      case "phone":
        if (!validations.phone.test(value)) {
          setErrors(prev => ({ ...prev, phone: false }));
        } else {
          setErrors(prev => ({ ...prev, phone: true }));
        }
        break;
      case "message":
        if (value.length > validations.message) {
          setErrors(prev => ({ ...prev, message: true }));
        } else {
          setErrors(prev => ({ ...prev, message: false }));
        }
        break;
      default:
        break;
    }
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const fileArray = [];
    for (let i = 0; i < e.currentTarget.files.length; i++) {
      fileArray.push(e.currentTarget.files[i]);
    }

    const allowedTypes = ["image/jpeg", "image/png", "image/jpg", "application/pdf"];

    if (fileArray.filter(file => !allowedTypes.includes(file?.type)).length) {
      setErrors(prev => ({
        ...prev,
        fileType: true,
      }));
      return;
    }

    if (fileArray.reduce((a, b) => a.size + b.size, 0) > validations.files) {
      setErrors(prev => ({
        ...prev,
        fileSize: true,
      }));
      return;
    }

    setValues(prev => ({
      ...prev,
      files: fileArray,
    }));

    setErrors(prev => ({
      ...prev,
      fileSize: false,
      fileType: false,
    }));
  };
  const handleSimpleValueChange = (e) => {
    e.preventDefault();
    values[e.currentTarget.name] = e.currentTarget.value;
    setValues({
      ...values,
    });
  };

  return (
    <div className="relative isolate bg-gray-200">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="flex justify-center relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-48">
          <div className=" mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div
              className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
              <svg
                className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                    width={200}
                    height={200}
                    x="100%"
                    y={-1}
                    patternUnits="userSpaceOnUse"
                  >
                    <path d="M130 200V.5M.5 .5H200" fill="none" />
                  </pattern>
                </defs>
                <rect width="100%" height="100%" strokeWidth={0} fill="white" />
                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                  <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                </svg>
                <rect
                  width="100%"
                  height="100%"
                  strokeWidth={0}
                  fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
                />
              </svg>
            </div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              {title}
            </h2>
            <dl className="mt-10 space-y-1 text-base leading-1 text-gray-600 cyan">
              <div className="mt-10 sm:mt-5 grid grid-cols-1">
                <div className="sm:max-w-full sm:text-left pt-10 ">
                  <h4 className="text-xl font-semibold inline-flex flex-row justify-center sm:justify-start">
                    <WhatsappIcon width={"32px"} stroke={"#083341"} fill={"#083341"} height={"32px"}
                                  viewBox={"0 0 32 32"} />
                    <span className="leading-loose">
                                 &ensp; Tel / WhatsApp
                              </span>
                  </h4>
                  <ul className="list-none">
                    <li className="mt-2">
                      <a
                        href="tel:+355698080020"
                        className="hover:underline"
                      >
                        <i className="fas fa-phone-volume mr-2"></i>
                        +355 69 80 80 020
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sm:max-w-full sm:text-left pt-10 ">
                  <h4 className="text-xl font-semibold inline-flex flex-row justify-center sm:justify-start">
                    <InstagramIcon width={"32px"} stroke={"#083341"} fill={"#083341"} height={"32px"}
                                   viewBox={"0 0 24 24"} />
                    <span className="leading-loose">
                                 &ensp; Instagram
                              </span>
                  </h4>
                  <ul className="list-none">
                    <li className="mt-2">
                      <a
                        href="https://instagram.com/ciaodental?igshid=MzRlODBiNWFlZA=="
                        className="hover:underline"
                        target="_blank" rel="noopener noreferrer"
                      >
                        <i className="fas fa-envelope mr-2"></i>
                        ciaodental
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sm:max-w-full sm:text-left pt-10 ">
                  <h4 className="text-xl font-semibold inline-flex flex-row justify-center sm:justify-start">
                    <EmailIcon width={"32px"} stroke={"#083341"} fill={"#083341"} height={"32px"}
                               viewBox={"0 0 24 24"} />
                    <span className="leading-loose">
                                 &ensp; Email
                              </span>
                  </h4>
                  <ul className="list-none">
                    <li className="mt-2">
                      <a
                        href="mailto:yourdentistinalbania@gmail.com"
                        className="hover:underline"
                      >
                        <i className="fas fa-envelope mr-2"></i>
                        yourdentistinalbania@gmail.com
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="sm:max-w-full sm:text-left pt-10 ">
                  <h4 className="text-xl font-semibold inline-flex flex-row justify-center sm:justify-start">
                    <FacebookIcon width={"32px"} stroke={"#083341"} fill={"#083341"} height={"32px"}
                                  viewBox={"0 0 24 24"} />
                    <span className="leading-loose">
                                 &ensp; Facebook
                              </span>
                  </h4>
                  <ul className="list-none">
                    <li className="mt-2">
                      <a
                        href="https://www.facebook.com/yourdentistinalbania"
                        target="_blank" rel="noopener noreferrer"
                        className="hover:underline"
                      >
                        <i className="fas fa-envelope mr-2"></i>
                        Ciao turismo dentale
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </dl>
          </div>
        </div>
        <form
          className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48"
          onSubmit={onSubmit}
          method="POST"
        >
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {labels[0]}
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={values.firstName}
                    onChange={handleSimpleValueChange}
                    autoComplete="given-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {labels[1]}
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={values.lastName}
                    onChange={handleSimpleValueChange}
                    autoComplete="family-name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {labels[2]}
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    name="email"
                    value={values.email}
                    onChange={handleSimpleValueChange}
                    id="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {labels[3]}
                </label>
                <div className="mt-2.5">
                  <input
                    type="tel"
                    name="phoneNumber"
                    value={values.phoneNumber}
                    onChange={handleSimpleValueChange}
                    id="phoneNumber"
                    autoComplete="tel"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {labels[4]}
                </label>
                <div className="mt-2.5">
                                    <textarea
                                      name="message"
                                      id="message"
                                      value={values.message}
                                      onChange={handleSimpleValueChange}
                                      rows={4}
                                      className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  {attach.title}:
                </label>
                <div className="mt-2.5">
                  <input
                    type="file"
                    id="files"
                    name="files"
                    onChange={handleFileChange}
                    multiple />
                </div>
              </div>

            </div>
            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                className="rounded-md bg-cyan-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
              >
                {button}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
