import React from "react";
export const oralSurgeryParagraph = () => (
  <section>
    <div className="font-semibold text-2xl">
      You could need oral surgery for a number of reasons:
    </div>

    <ul className="list-disc pl-16 mt-1" >
      <li>Extensive tooth decay.</li>
      <li>Badly broken teeth.</li>
      <li>Gum disease.</li>
      <li>Impacted teeth.</li>
      <li>Missing teeth.</li>
      <li>Temporomandibular joint disorders (TMD).</li>
      <li>Bone loss in your jaw.</li>
      <li>Sleep apnea.</li>
      <li>Tooth extraction</li>
    </ul>

    <p className="mt-5">
      The most common type of oral surgery is tooth extraction (tooth removal). An extraction might be recommended if you have severe tooth decay, gum disease (periodontitis), dental trauma or wisdom teeth complications. Sometimes tooth extractions are performed to prepare you for dentures or other prosthetic devices.
    </p>

    <p className="mt-5">
      Most dentists prefer to save natural teeth when possible, but sometimes extractions are necessary to preserve your overall oral health. Additionally, many dentists recommend wisdom tooth extraction as a preventative measure to reduce your risk for cavities, bone loss and other problems.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Dental bone graft
    </div>

    <p className="mt-5">
      A dental bone graft is necessary when bone loss has occurred in your jaw. There are a couple of reasons why this may occur. When your natural teeth are present, the roots stimulate the nerves in your jaw. This signals your brain to send nutrients to your jaw, keeping it strong and healthy. If a tooth has been missing for some time, bone deterioration can occur in that area because there are no roots to stimulate the nerves. A dental bone graft restores volume and density in your jawbone so that dental implants can be placed later on.
    </p>

    <p className="mt-5">
      The length of your procedure will depend on several factors, including what type of oral surgery you’re having, how many teeth are being treated and whether or not you choose sedation. A one-tooth extraction usually takes about 30 minutes, while a more involved procedure — such as corrective jaw surgery — typically takes at least two to three hours.
    </p>

    <p className="mt-5">
      Following your oral surgery procedure, you’ll be given detailed post-operative instructions. It’s important that you follow these guidelines closely to reduce your risk of bleeding, infection and other complications.
    </p>

  </section>
);

export const periodontologyParagraph = () => (
  <section>
    <p className="mt-5">
      The main function of the periodontium (gum tissues) is to keep the teeth in their position inside the bone but also acts as a buffer during mastication preventing damage to the teeth when they receive excessive forces. The tissues and teeth act and work together to protect themselves and promote proper function during chewing.     </p>

    <p className="mt-5">
      The oral state of a person can be a clear indication of their general health, in particular the periodontal condition of their gums, can show us much more than simply what happens at the level of the periodontology. Systemic diseases (diabetes, smoking, HIV, …) can have a direct effect on the structures that support the teeth. Some pathologies can also be detected through the mouth before they are evident in any other part of the body. For this reason, periodontists (gum dentists) may be the first to detect signs of a general illness such as diabetes or blood disorders. The dental office or the periodontist can become a center for early detection of diabetes.    </p>

    <div className="font-semibold mt-9 text-2xl">
      WHAT DO PERIODONTISTS DO?
    </div>

    <p className="mt-5">
      Periodontists are those dentists who specialize in the prevention and treatment of diseases that affect the periodontium. All dentists receive training in periodontology but a periodontist receives additional training specific to this field. Periodontists with specialist status have received postgraduate or master’s degree in periodontology and dental implants during three academic courses and full-time. So it is common in daily practice that the most complicated cases are treated by specialists in this area of  dentistry.    </p>

    <p className="mt-5">
      The periodontal diseases treated by periodontists are very common among the population and are a consequence of the inflammation of the periodontal (gum) tissues. Gingivitis is the inflammatory response of the gums due to the accumulation of bacteria in the teeth and soft tissues of the mouth. Gingivitis occurs at least once in life in all individuals. This response is a defense mechanism that the human body has to fight against pathogenic bacteria but can cause damage to the tissues if not treated correctly. The inflammation can extend into the gums and destroy the periodontal ligament and alveolar bone causing periodontitis. This causes a lost of the support for the teeth and even teeth lost when the disease is in more advanced stages.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      TREATMENT OF PERIODONTITIS
    </div>

    <p className="mt-5">
      With proper advice and treatment from professionals it is usually possible to completely stop the progression of periodontitis. The key is to eliminate bacterial plaque that accelerates the pathological process and establish excellent oral hygiene habits. Thus, the first step of a periodontal treatment consists in making the patient aware of his condition and giving oral hygiene instructions. In this first hygienic phase it is very important to make sure that the patient adequately complies with their hygiene. On the other hand, the periodontist will perform hygienic procedures to eliminate calculus and bacterial plaque from areas that are not accessible to the patient. Sometimes antibiotic therapy can be given if the infection of the tissues persists and does not respond to hygienic measures.
    </p>

    <p className="mt-5">
      After this first phase, the patient’s periodontal condition and hygiene are checked after a few weeks. The progress of the disease is checked and a probing is carried out to check the depth of the bags. If some bags persist (space where bacteria that have the ability to destroy gum and bone), more treatment options, including surgery, are offered. Sometimes it is necessary to perform a surgery lifting the gum to be able to access those areas where there is calculation and to be able to clean it. This allows you to have a direct view and make sure that the area is completely clean. In other cases it will be possible to perform regenerative surgeries in which the bone loss is treated and regenerated in order to try to give a more correct anatomy to the bone defects created by periodontitis. In other cases it will not be possible to regenerate these defects and surgeries will be performed in which the bone is remodeled and if necessary the gum as well.
    </p>

    <p className="mt-5">
      After this surgical phase, if necessary, a restorative phase will be carried out. It is at this time that implants, definitive prostheses, orthodontic treatment or other treatment options appropriate to each individual situation can be placed. The implants may be placed when the periodontal treatment is completed.
    </p>

    <p className="mt-5">
      Finally, the success of long-term periodontal therapy depends on oral hygiene by the patient and professional maintenance, and advice from professionals. It is important to perform this maintenance phase on a regular basis to check the state of the periodontal tissues and check that the inflammation is controlled. This way you can check the maintenance of the results obtained by periodontal treatment, prevent the recurrence of the disease and early detection of the appearance of new periodontal lesions to be able to treat them and inform the patient of the hygienic measures that should be taken to avoid the recurrence of periodontal disease.
    </p>
  </section>
);

export const implantologyParagraph = () => (
  <section>

    <div className="font-semibold mt-9 text-2xl">
      IMPLANTOLOGY, A BRANCH OF DENTISTRY FOCUSED ON DENTAL IMPLANTS
    </div>

    <p className="mt-5">
      A natural tooth has two main components: The Crown and the root. The crown is the visible part of the tooth and the root is located below the gumline, in the bone of the jaw. Implantology is the branch of dentistry dedicated to dental implants. The dental implant is an artificial tooth root that is inserted into the jawbone (either the maxilla or the mandible). It often takes the form of a screw that is composed of titanium or titanium alloy.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      IMPLANTS REPLACE MISSING TEETH
    </div>

    <p className="mt-5">
      Dental implants can be considered for patients who are missing one or more teeth.
    </p>

    <p className="mt-5">
      A dental implant alone does not replace a natural tooth. An abutment must be mounted on the implant once the latter has been inserted into the jawbone.
    </p>

    <p className="mt-5">
      Depending on the number of teeth to replace, an artificial crown, a full or partial denture or a bridge will be prepared and installed on the abutment(s). If the patient only has one missing tooth, a single crown will be sufficient. If several teeth need to be replaced, many options are available: using a single implant with multiple crowns joined by a bridge or a prosthesis, or using multiple implants with distinct crowns. This choice is up to the patient, according to the treatment plan recommended by the implantology specialist.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      AN OUTPATIENT PROCEDURE DONE UNDER LOCAL ANESTHESIA
    </div>

    <p className="mt-5">
      The insertion of dental implants is a surgical procedure that is usually performed at the clinic and under local anesthesia.
    </p>

    <p className="mt-5">
      Several dental health professionals are qualified to install dental implants: dentists, oral and maxillofacial surgeons, periodontists and other specialists in dentistry. These professionals must have extensive implantology training. Dentists and prosthodontists often specialize in the fabrication of crowns, bridges and dentures that are mounted on the implants.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      BENEFITS OF DENTAL IMPLANTS
    </div>

    <ul className="list-disc pl-16 mt-1" >
      <li>
        When a patient has lost one or more teeth, dental implants have multiple advantages over other treatments involving removable prostheses.
      </li>

      <li>
        Implants allow the installation of fixed or removable dental prostheses.
      </li>

      <li>
        They are more comfortable and discreet because they do not cause any unpleasant friction or movement on the gums as in the case of removable prostheses that are not mounted on implants (e.g.: partial or complete dentures). It is possible to get an implant-supported denture that does not even cover the palate at all.
      </li>

      <li>
        No adhesive (denture glue) is required to hold the implant-supported prosthesis in place.
      </li>

      <li>
        Implants offer a better stability to artificial teeth, which facilitates chewing and digestion, improves comfort and facilitates speech.
      </li>

      <li>
        The patients are not forced to adapt their diet.
      </li>

      <li>
        Implants allow distributing chewing forces between implants and adjacent structures of the mouth (gums, bones and remaining teeth), which helps to reduce the stress on these structures. When healthy teeth are still in the mouth, the patient can expect to be able to keep these remaining teeth longer than if no implant was installed and if the space created by missing teeth remained vacant.
      </li>

      <li>
        Implants contribute to the preservation of the alveolar bone (the bone that supports teeth) by preventing its resorption where teeth are missing. In fact, when a tooth is missing, the alveolar bone that used to support it is not stimulated anymore and deteriorates gradually.
      </li>

      <li>
        Implants do not require grinding and modifying adjacent teeth (like in the case of a traditional bridge).
      </li>

      <li>
        Implants give the impression of having natural teeth, both aesthetically and functionally, and act as an artificial root anchored in the bone of the jaw, like a natural tooth root. The patients may, therefore, regain the integrity of their facial features that had been affected by the loss of several teeth.
      </li>

      <li>
        The two main components of an implant (the artificial crown and the implant itself) will not be affected by tooth decay.
      </li>

      <li>
        Once osseointegration is complete, the implants rarely need to be replaced and therefore offer a reliable and long-term solution. Despite the high initial cost, the dental implant is a good investment given its life expectancy.
      </li>
    </ul>

    <div className="font-semibold mt-9 text-2xl">
      DENTAL IMPLANT COSTS
    </div>

    <p>
      The costs of dental implants are separated into two categories:
    </p>

    <ul className="list-disc pl-16 mt-1" >
      <li>The cost of surgery (when dental implants and abutments are inserted in the patient’s jaw);</li>
      <li>The cost of the prosthetic restoration (crown, bridge or denture that will attach to the abutment of the implant).</li>
    </ul>

  </section>
);

export const orthodonticsParagraph = () => (
  <section>
    <p className="mt-5">
      Orthodontics is the branch of dentistry that focuses on diagnosing and treating “bad bites” (malocclusion). Common orthodontic treatments include braces, clear aligners and retainers.
    </p>

    <p className="mt-5">
      The way your teeth fit together has a significant impact on your oral health. By improving the way your upper and lower teeth meet, you can reduce your risk for a host of oral health issues, including cavities, gum disease and excessive wear (erosion).
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Who benefits from orthodontics?
    </div>

    <p className="mt-5">
      Almost everyone can gain some benefit from orthodontics. But some people need treatment more than others. Many people seek orthodontic treatment because they want to improve the appearance of their smile. But in addition to cosmetic benefits, orthodontics offers improved chewing function and better oral health.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Common orthodontic problems
    </div>

    <p className="mt-5">
      Types of orthodontic problems include:
    </p>

    <ul className="list-disc pl-16 mt-1" >
      <li>
        Overbite, when your upper teeth stick out over your lower teeth.
      </li>

      <li>
        Underbite, when your lower teeth stick out further than your upper teeth.
      </li>

      <li>
        Overlapping teeth.
      </li>

      <li>
        Crowded teeth.
      </li>

      <li>
        Crooked teeth.
      </li>

      <li>
        Rotated teeth.
      </li>

      <li>
        Impacted teeth (which are partially or fully trapped in your jawbone).
      </li>
    </ul>

    <div className="font-semibold mt-9 text-2xl">
      Braces
    </div>

    <p className="mt-5">
      Braces gradually shift your teeth into their desired positions over time using a combination of brackets, bands and wires. There are different types of braces depending on your needs, including traditional metal braces and clear (ceramic) braces.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Clear aligners
    </div>

    <p className="mt-5">
      Clear orthodontic aligners are a popular alternative to braces. Well-known brands include Invisalign® and ClearCorrect®. Rather than using brackets and wires to straighten your teeth, these systems use a series of custom-made, clear aligner trays. You wear each set of trays for one to two weeks before switching them out for the next set in the series. Over time, the clear aligners shift your teeth into their proper positions.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Retainers
    </div>

    <p>
      If you wear braces or clear aligners, you’ll need a retainer once your orthodontic treatment ends. A retainer is a custom oral appliance that keeps your teeth from drifting out of their proper positions. Your orthodontist can tell you how often you’ll need to wear your retainer to maintain the results of treatment.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Palate expanders
    </div>

    <p>
      Orthodontists recommend some treatments during childhood. This is because a child’s facial bones are still developing and they’re much easier to move and manipulate. A palate (palatal) expander helps to widen a child’s upper jaw. Most children don’t need palate expanders. But under the right circumstances, these devices can create extra space without the need for tooth extractions or other procedures.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      What are the advantages of orthodontics?
    </div>

    <p>
      The cosmetic benefits of orthodontics are clear. But these treatments offer functional and health-related advantages as well:
    </p>

    <ul className="list-disc pl-16 mt-1" >
      <li>When your teeth are in proper alignment, they’re much easier to clean.</li>
      <li>Straightening your teeth can improve chewing and speech function.</li>
      <li>Orthodontic treatment can ease pain related to TMJ disorder.</li>
      <li>An aligned bite can reduce your risk for cavities, gum disease and other oral health issues.</li>
      <li>Orthodontics can help you achieve and maintain a beautiful, healthy smile.</li>
    </ul>

  </section>
);

export const prostheticsParagraph = () => (
  <section>
    <p className="mt-5">
      There are several types of fixed dental appliances, such as crowns and implants, and removable devices, including full or partial dentures, which can be taken out as needed.
    </p>

    <p className="mt-5">
      In this article, we review five types of dental prosthesis devices so that you can have an informed discussion with your dentist about which one might be best for you.    </p>

    <div className="font-semibold mt-9 text-2xl">
      1. Dental crowns
    </div>

    <p className="mt-5">
      A dental crown is a new covering for a damaged tooth.
    </p>

    <p className="mt-5">
      A crown may be made of metal or porcelain. Crowns tend to be good long-term solutions for teeth that have been chipped, cracked, or worn down. But teeth that require a significant amount of restoration are at much higher risk for failure, according to a 2018 study in the Journal of DentistryTrusted Source.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Getting a crown often requires two appointments. In a typical process for a dental crown, a dentist will:
    </div>

    <ul className="list-disc pl-16 mt-1" >
      <li>
        Get a soft mold of your teeth.
      </li>

      <li>
        Fill any cavities in the damaged tooth (if necessary).
      </li>

      <li>
        Trim the tooth to prepare it for receiving a crown.
      </li>

      <li>
        Place a temporary crown on the tooth while a permanent crown is made in a lab.
      </li>

      <li>
        After a few weeks, place a permanent crown that is cemented in place.
      </li>

      <li>
        Dental practices that can make crowns on-site may offer same-day crowns.
      </li>
    </ul>

    <p className="mt-5">
      Crowns are considered a relatively permanent solution. Once a crown is in place, it should last 5 to 15 years or even longer if maintained properly. You should brush and floss a tooth with a crown as you would any other tooth.
    </p>

    <p className="mt-5">
      A crown can crack or chip over time, or the cement holding it in place can soften. This can allow bacteria to move in and infect the tooth.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Dental bridge
    </div>

    <p className="mt-5">
      When one or more teeth are missing, a dental bridge is a compromise between dentures and implants.
    </p>

    <p className="mt-5">
      As the name implies, a dental bridge is meant to bridge a gap left by missing teeth. A bridge is usually anchored to natural teeth at both ends of the gap and may be made of one or more false teeth called pontics. In some cases, a bridge may be attached to an implant for greater security and stability.
    </p>

    <p className="mt-5">
      A bridge is often made of porcelain to look like natural teeth and must be brushed and flossed regularly. Special flossing aids called threaders may be necessary to get floss between the bridge and the gum line.
    </p>

    <p className="mt-5">
      Here’s the typical process for getting a bridge:
    </p>

    <ul className="list-disc pl-16 mt-1" >

      <li className="mt-2">
        At the first appointment, the anchor teeth on either side of the bridge are filed into shape and a mold is made of your mouth. A temporary bridge may be made to fill in the gap while a permanent one is made within a couple of weeks.
      </li>

      <li className="mt-2">
        At the second appointment, the permanent bridge is fitted into place. Any necessary minor adjustments are made so that the bridge looks natural and works with your natural bite. The bridge is then cemented into place.
      </li>

      <li>
        A well-made bridge can last for decades, but 5 to 15 years is a reasonable expectation.
      </li>

    </ul>

    <div className="font-semibold mt-9 text-2xl">
      Dentures
    </div>

    <p className="mt-5">
      Dentures are prosthetic devices that fit snugly against the gums and look and function as natural teeth. They’re also referred to as false teeth.
    </p>

    <p className="mt-5">
      The extent of tooth loss and your preference will help determine what kind of dentures are best for you. The most common types of dentures include:
    </p>

    <ul className="list-disc pl-16 mt-1" >

      <li className="mt-2">
        Complete dentures replace all of your teeth and must be removed daily for cleaning.
      </li>

      <li className="mt-2">
        Removable partial dentures replace some but not all of your teeth.
      </li>

      <li>
        Fixed partial dentures are also known as implant-supported bridges.
      </li>

      <li>
        Implant-retained dentures are affixed to implants but must be removed daily for cleaning (also called snap-in dentures).
      </li>

    </ul>

    <p className="mt-5">
      Another type of denture is called a flipper. These are temporary partial dentures replacing one or two teeth. They are made of less durable materials than regular partial dentures since they are meant to be used for a short time.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Dental veneers
    </div>

    <p className="mt-5">
      Veneers can help improve the color and appearance of natural teeth by covering them with a thin, natural-looking shell.
    </p>

    <p className="mt-5">
      Here’s the typical process for getting a veneer:
    </p>

    <ul className="list-disc pl-16 mt-1" >

      <li className="mt-2">
        The dentist will grind enamel off your teeth and take a mold of your mouth.
      </li>

      <li className="mt-2">
        Using the mold, laboratory technicians will make your veneers out of porcelain or a resin-composite material. The process may take a couple of weeks.
      </li>

      <li>
        Veneers are then cemented to your teeth. An ultraviolet light is used to quickly harden cement that keeps veneers attached to your teeth.
      </li>

      <li>
        You need to have relatively healthy teeth to support a veneer. Veneers can last 10 to 15 years before needing to be replaced.
      </li>
    </ul>

    <div className="font-semibold mt-9 text-2xl">
      What are the benefits of a dental prosthesis device?
    </div>

    <p className="mt-5">
      A dental prosthetic is useful in providing one or more of the following benefits:
    </p>

    <ul className="list-disc pl-16 mt-1" >

      <li className="mt-2">
        Improving the appearance of your teeth, boosting confidence and self-esteem.
      </li>

      <li className="mt-2">
        Improving your ability to chew, allowing you to consume a healthy diet.
      </li>

      <li>
        Supporting the health and strength of the jawbone, supporting the strength of other teeth.
      </li>

      <li>
        Protecting against gum disease that can lead to serious health problems.
      </li>
    </ul>
  </section>
);

export const dentalAestheticsParagraph = () => (
  <section>
    <div className="font-semibold mt-9 text-2xl">
      What is Dental Aesthetics?
    </div>

    <p className="mt-5">
      In simple terms, we can define dental aesthetics as a procedure to enhance the overall appearance of the teeth. The primary purpose of dental aesthetics is to keep the teeth aligned, improve their structure, and beautify their color and shape so that you can smile confidently. Dental aesthetics is both a preventive and a restorative measure as it can be utilized to correct missing teeth and other similar problems. But, it is noteworthy to highlight that aesthetic dentistry is not the same as cosmetic dentistry.
    </p>

    <p className="mt-5">
      The former is any method used for enhancing your smile through treatments that would be necessary. On the contrary, the latter entails correcting the existing or old dental works such as veneers, crowns, bridges, and so forth, which may not be particularly pleasant to the eyes. Now that we have discussed the basics of dental aesthetics let us observe the various procedures for dental aesthetics.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Dental Aesthetics Procedures
    </div>

    <div className="font-semibold mt-9 text-2xl">
      Dental aesthetics include a throng of procedures, and the most common ones are:
    </div>


    <p className="mt-5">
      <strong>Whitening: </strong> It is a widely used dental aesthetic procedure. The method entails bleaching your teeth for removing external teeth stains. Some teeth yellowing is a consequence of internal problems such as a dying tooth or enamel erosion, and aesthetic dentistry corrects such anomalies. Laser teeth whitening is a modern dental whitening procedure in which gums are covered with rubber, and hydrogen peroxide bleach is applied to the teeth. Thereafter, a flash of the argon laser is projected onto the teeth to hasten the whitening process. Laser teeth whitening is six times more effective than conventional teeth whitening.
    </p>

    <p className="mt-5">
      <strong>Reshaping: </strong> It is related to the reshaping of a misaligned tooth that requires the removal of some part of the enamel. Reshaping can be used to correct chips and cracks on the teeth. It can also tweak the position, shape, or length of the teeth. Another crucial usage of teeth is the correction of an overbite or underbite.
    </p>

    <p className="mt-5">
      <strong>Gum Lifts: </strong> It is an aesthetic dental procedure that entails raising and then sculpting the gum line so that your smile does not look either too gummy or toothy. The process reshapes the gingival tissue and the underlying bones to fashion a more symmetrical and seamless smile.
    </p>

    <p className="mt-5">
      <strong>Teeth Straightening: </strong> It is a standard dental aesthetic procedure in which teeth are gradually straightened with retainers or braces. Straightening helps in fixing overlapping, crowded, or widely-spaced teeth. Nowadays, Invisalign is also preferred by many people as they are invisible to the naked eye.
    </p>

    <p className="mt-5">
      <strong> Bonding: </strong> It is an aesthetic dental procedure that is usually performed after other dental treatments. The composite resin bonding process is when enamel-like dental composite materials are applied to the teeth’ surface and then sculpted into shape, hardened, and polished.
    </p>

    <p className="mt-10">
      Therefore, we can assert that dental aesthetics is necessary to make people more confident and infuse self-esteem. It has made the lives of many people easier by enabling them to smile broader and brighter.
    </p>
  </section>
);

export const pedodonticsParagraph = () => (
  <section>
    <div className="font-semibold mt-9 text-2xl">
      Pedodontics benefits
    </div>

    <p className="mt-5">
      Children usually begin getting primary teeth around six months of age. Children should visit the pediatric dentist within six months of the first tooth or by the age of one, whichever comes first. Pediatric dentists address issues with the mouth and jaw and also help correct issues such as thumb-sucking. Learn the benefits of visiting the pediatric dentist. These benefits help both children and parents.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Keep children at ease
    </div>

    <p className="mt-5">
      Children are often nervous about going to the dentist and the doctor. A pediatric dentist is trained to keep children at ease. The dentist uses a special skill set to make children comfortable. This builds rapport between the children and dentist.
    </p>

    <p className="mt-5">
      The staff at a pediatric dentist’s office is also trained to work with children. The staff creates a fun and comfortable environment for patients. The office might also include fun activities for the kids. This keeps kids’ minds busy before and during the dental appointment.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Teach children proper oral hygiene techniques
    </div>

    <p className="mt-5">
      It is hard for parents to teach their children how to brush and floss properly. Children might miss sections of the teeth, leaving them susceptible to decay. Dentists trained in pedodontics teach children proper oral hygiene techniques. Dentists also provide parents with tips to make home oral care easier. These tips can even make brushing and flossing fun for kids.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      A holistic approach to dental care
    </div>

    <p className="mt-5">
      Pediatric dentists have the training to take a holistic approach to dental care. Dentists look at environmental factors that impact the teeth, including the foods and beverages consumed. These dentists also consider risk factors such as pacifier use and thumb-sucking behaviors. At the same time, pediatric dentists provide dental cleanings, fluoride treatments and other dental services to maintain healthy smiles. This holistic approach ensures the child receives proper care.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Ensure mouth is developing properly
    </div>

    <p className="mt-5">
      Since pediatric dentists are trained in oral care for children, they look for developmental issues. Dentists ensure the mouth is developing properly and the teeth are erupting at the right time. When dentists notice issues, they begin a treatment plan. Early treatment promotes healthy development. Proper oral development is critical for speech and eating.
    </p>
  </section>
);

export const dentalHygieneParagraph = () => (
  <section>
    <div className="font-semibold mt-9 text-2xl">
      Dental and gum diseases caused by poor dental hygiene
    </div>

    <p className="mt-5">
      The main cause of dental and gum diseases is poor personal oral hygiene. Diseases caused by poor oral hygiene can be prevented by regularly visiting a dentist for preventive examination, and an oral hygienist, who not only performs professional oral hygiene but also suggests the necessary tools for proper dental care at home. Incorrect food chewing, frequent consumption of soft foods and lack of salt in the body can also contribute to the onset of dental and gum diseases.
    </p>

    <p className="mt-5">
      Professional oral hygiene is recommended twice a year. Patients with implants, fixed prostheses, have to go to a hygienist every 6 months. Patients who wear dental braces should use professional oral hygiene procedures every 4 months or as recommended by the oral hygienist.
    </p>

    <p className="mt-5">
      Professional oral hygiene is always performed before aesthetic filling, permanent prosthesis, and surgical interventions.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      TARTAR REMOVAL
    </div>

    <div className="font-semibold mt-9 text-2xl">
      What is it?
    </div>

    <p className="mt-5">
      Professional dental hygiene removes soft and hard dental plaque using special instruments in the oral hygienist’s office. Dental plaque is one of the most common causes of gum inflammation, untimely caries, periodontitis (better known as periodontal disease), and early tooth loss, so it must be removed on time.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Dental and gum diseases caused by poor dental hygiene
    </div>

    <p className="mt-5">
      The main cause of dental and gum diseases is poor personal oral hygiene. Diseases caused by poor oral hygiene can be prevented by regularly visiting a dentist for preventive examination, and an oral hygienist, who not only performs professional oral hygiene but also suggests the necessary tools for proper dental care at home. Incorrect food chewing, frequent consumption of soft foods and lack of salt in the body can also contribute to the onset of dental and gum diseases.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      When Is Dental Hygiene Recommended?
    </div>

    <p className="mt-5">
      Professional oral hygiene is recommended twice a year. Patients with implants, fixed prostheses, have to go to a hygienist every 6 months. Patients who wear dental braces should use professional oral hygiene procedures every 4 months or as recommended by the oral hygienist.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      “Air-Flow” method for dental hygiene
    </div>

    <p className="mt-5">
      The strong flow of air, water, and medical calcium carbonate powder quickly and painlessly clean up dental plaque. This method does not damage hard tooth tissues and gums, because the surface of the tooth is treated from a distance.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Tartar cleaning
    </div>

    <p className="mt-5">
      Oral hygienist cleans mineralized plaque by using an ultrasonic scaler and the necessary hand tools. All surfaces of teeth and fillings get polished with paste. Soft and pigmented plaque is removed by air abrasion using calcium carbonate powder (better known as baking soda).
    </p>
  </section>
);

export const facialAestheticsParagraph = () => (
  <section>
    <p className="mt-5">
      Facial aesthetics refers to a type of non-surgical and minimally invasive procedure that can temporarily diminish wrinkles or restore volume. Common treatment areas include the forehead, cheeks and lips.
      <br/>
      Facial aesthetics is a broad term that can refer to anti-wrinkle injections, dermal fillers and lip fillers. This discipline involves addressing key concerns about the face, including loss of volume, fine lines or wrinkles.
    </p>

    <p className="mt-5">
      These results won’t last forever and will eventually wear off, but individuals can choose to have them “topped up” regularly if they wish to maintain the results. During your initial consultation with our facial aesthetics team, we can help you to identify your primary concerns and the treatments that will deliver the best results. Next, we can formulate a treatment plan that works for you.
    </p>

    <p className="mt-5">
      When deciding to undergo facial aesthetics treatments, it’s important to have realistic expectations about what can be achieved. We will help you to manage your expectations by offering a realistic representation of what each treatment can achieve and how long it will last. We will also advise you on alternative treatments if we think we can achieve better results with another method.
    </p>

    <div className="font-semibold mt-9 text-2xl">
      Options for facial aesthetics
    </div>

    <p className="mt-5">
      There are three main treatment options available for facial aesthetics. Rayleigh residents can choose from anti-wrinkle injections, dermal fillers and lip fillers. Let’s explore each treatment option in more detail.
    </p>

    <p className="mt-5">
      Anti-wrinkle injections are commonly used to relax the muscles in the face so that the skin above appears smoother. This can help to address deep-set wrinkles and fine lines. Common treatment areas include the forehead, around the eyes, the sides of the nose and the chin. This treatment can also help to address concerns such as a gummy smile. The results are visible after around one week and can last around 3-4 months, but this will vary for different people.
    </p>

    <p className="mt-5">
      Dermal fillers are used to restore volume in the face. They contain a substance called hyaluronic acid which helps to retain water. This material occurs naturally in the body and is broken down over time. Dermal fillers may be used to fill hollows under the eyes or in the temples. They can also be used to create more definition in your features, including your nose, chin and cheeks. The results are visible immediately and can last up to 6 months.
    </p>

    <p className="mt-5">
      Lip fillers are similar to dermal fillers but are used to plump up the lips. Lips lose volume as we age, and plumper lips can create a more youthful appearance. Lip fillers can create a subtle plumping, change the shape of your lips, or create a dramatic transformation. The results are visible immediately and can last up to 6 months.
    </p>
  </section>
);








