import React, { useContext, useEffect, useState } from "react";
import { I18NContext } from "../App";

export const PartnerSection = () => {
  const { data } = useContext(I18NContext);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const newImages = [];
    for (let i = 1; i < 7; i++) {
      newImages.push({
        id: i,
        alt: `Partner image ${i}`,
        src: `/assets/partners/${i}.jpg`,
      });
    }
    setImages(newImages);
  }, []);

  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto w-full lg:mx-0 pb-6">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl pb-9  text-center">
            {data.pages.home.partners}
          </h2>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-6 lg:grid-cols-6 gap-2 md:gap-4 lg:gap-4 object-contain">
          {images.map(img => (<img
            src={img.src}
            alt={img.alt} />))}
        </div>
      </div>
    </div>);
};