import React, { useContext } from "react";
import { I18NContext } from "../App";
import { EmailIcon, FacebookIcon, InstagramIcon, WhatsappIcon } from "../utils/svg";

export const FooterSection = () => {
  const { data } = useContext(I18NContext);
  const { logo, schedule, rightReserved } = data.sections.footer;

  return (
    <footer className="relative bg-gray-900 py-8">
      <div className="max-w-6xl container mx-auto text-cyan-100">
        <div className="mx-auto">
          <div className=" w-full justify-center inline-flex flex-col">
            <div className=" w-full justify-center inline-flex flex-row">
              <img
                className="w-56 h-20"
                src={logo.imgSrc}
                alt="Ciao Dental Albania"
              />
            </div>
          </div>

          <div className="mt-10 sm:mt-5 grid grid-cols-1 lg:grid-cols-4 md:grid-cols-4 lg:gap-4">
            <div className="sm:max-w-full sm:text-left pt-10 pl-[35px]">
              <h4 className="text-xl font-semibold inline-flex flex-row justify-center sm:justify-start">
                <WhatsappIcon width={"32px"} stroke={"#CFFFFE"} fill={"#CFFFFE"} height={"32px"}
                              viewBox={"0 0 32 32"} />
                <span className="leading-loose">
                                 &ensp; Tel / WhatsApp
                              </span>
              </h4>
              <ul className="list-none">
                <li className="mt-2">
                  <a
                    href="tel:+355698080020"
                    className="hover:underline"
                  >
                    <i className="fas fa-phone-volume mr-2"></i>
                    +355 69 80 80 020
                  </a>
                </li>
              </ul>
            </div>
            <div className="sm:max-w-full sm:text-left pt-10 pl-[35px]">
              <h4 className="text-xl font-semibold inline-flex flex-row justify-center sm:justify-start">
                <InstagramIcon width={"32px"} stroke={"#CFFFFE"} fill={"#CFFFFE"} height={"32px"}
                               viewBox={"0 0 24 24"} />
                <span className="leading-loose">
                                 &ensp; Instagram
                              </span>
              </h4>
              <ul className="list-none">
                <li className="mt-2">
                  <a
                    href="https://instagram.com/ciaodental?igshid=MzRlODBiNWFlZA=="
                    className="hover:underline"
                    target="_blank" rel="noopener noreferrer"
                  >
                    <i className="fas fa-envelope mr-2"></i>
                    ciaodental
                  </a>
                </li>
              </ul>
            </div>
            <div className="sm:max-w-full sm:text-left pt-10 pl-[35px]">
              <h4 className="text-xl font-semibold inline-flex flex-row justify-center sm:justify-start">
                <EmailIcon width={"32px"} stroke={"#CFFFFE"} fill={"#CFFFFE"} height={"32px"} viewBox={"0 0 24 24"} />
                <span className="leading-loose">
                                 &ensp; Email
                              </span>
              </h4>
              <ul className="list-none">
                <li className="mt-2">
                  <a
                    href="mailto:yourdentistinalbania@gmail.com"
                    className="hover:underline"
                  >
                    <i className="fas fa-envelope mr-2"></i>
                    yourdentistinalbania@gmail.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="sm:max-w-full sm:text-left pt-10 pl-[35px]">
              <h4 className="text-xl font-semibold inline-flex flex-row justify-center sm:justify-start">
                <FacebookIcon width={"32px"} stroke={"#CFFFFE"} fill={"#CFFFFE"} height={"32px"}
                              viewBox={"0 0 24 24"} />
                <span className="leading-loose">
                                 &ensp; Facebook
                              </span>
              </h4>
              <ul className="list-none">
                <li className="mt-2">
                  <a
                    href="https://www.facebook.com/yourdentistinalbania"
                    target="_blank" rel="noopener noreferrer"
                    className="hover:underline"
                  >
                    <i className="fas fa-envelope mr-2"></i>
                    Ciao turismo dentale
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div
            className="pt-[48px] border-t border-white/10 mt-10  sm:mt-10 grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 lg:gap-8">
            <div className="mt-10 md:mt-0 max-w-px300 inline-flex flex-row sm:justify-center justify-end">
              <a href="https://maps.app.goo.gl/XTZbJqe5saH6RqVD9" target="_blank" rel="noopener noreferrer"
                 className="w-[300px] ml-auto">
                <img src={"map.png"} alt="Our location" className="mt-[25px] rounded-3xl cursor-pointer"
                     width={"300"} />
              </a>
            </div>
            <div
              className="border border-cyan-200 rounded-3xl max-w-[360px] min-w-[360px] mt-10 md:mt-0 inline-flex flex-col justify-start sm:justify-center mx-auto">
              <div className="max-w-sm flex justify-center pt-[25px]">
                {schedule.businessHours}
              </div>
              <div
                className="px-30 border-t border-cyan-200 max-w-sm pt-[15px] mt-[15px] grid grid-cols-2 lg:grid-cols-2 md:grid-cols-2 lg:gap-8 ">
                <div className="pl-30 inline-flex flex-row justify-center">
                  {schedule.monday.title}
                </div>
                <div className="pr-30 inline-flex flex-row justify-center">
                  9:00am - 9:00pm
                </div>
              </div>
              <div
                className="px-30 border-t border-cyan-200 max-w-sm pt-[10px] mt-[15px] grid grid-cols-2 lg:grid-cols-2 md:grid-cols-2 lg:gap-8 ">
                <div className="pl-30 inline-flex flex-row justify-center">
                  {schedule.tuesday.title}
                </div>
                <div className="pr-30 inline-flex flex-row justify-center">
                  9:00am - 9:00pm
                </div>
              </div>
              <div
                className="px-30 border-t border-cyan-200 max-w-sm pt-[10px] mt-[15px] grid grid-cols-2 lg:grid-cols-2 md:grid-cols-2 lg:gap-8 ">
                <div className="pl-30 inline-flex flex-row justify-center">
                  {schedule.wednesday.title}
                </div>
                <div className="pr-30 inline-flex flex-row justify-center">
                  9:00am - 9:00pm
                </div>
              </div>
              <div
                className="px-30 border-t border-cyan-200 max-w-sm pt-[10px] mt-[15px] grid grid-cols-2 lg:grid-cols-2 md:grid-cols-2 lg:gap-8 ">
                <div className="pl-30 inline-flex flex-row justify-center">
                  {schedule.thursday.title}
                </div>
                <div className="pr-30 inline-flex flex-row justify-center">
                  9:00am - 9:00pm
                </div>
              </div>
              <div
                className="px-30 border-t border-cyan-200 max-w-sm pt-[10px] mt-[15px] grid grid-cols-2 lg:grid-cols-2 md:grid-cols-2 lg:gap-8 ">
                <div className="pl-30 inline-flex flex-row justify-center">
                  {schedule.friday.title}
                </div>
                <div className="pr-30 inline-flex flex-row justify-center">
                  9:00am - 9:00pm
                </div>
              </div>
              <div
                className="px-30 border-t border-cyan-200 max-w-sm pt-[10px] mt-[15px] grid grid-cols-2 lg:grid-cols-2 md:grid-cols-2 lg:gap-8 ">
                <div className="pl-30 inline-flex flex-row justify-center">
                  {schedule.saturday.title}
                </div>
                <div className="pr-30 inline-flex flex-row justify-center">
                  9:00am - 3:00pm
                </div>
              </div>
              <div
                className="px-30 border-t pb-[15px] border-cyan-200 max-w-sm pt-[10px] mt-[15px] grid grid-cols-2 lg:grid-cols-2 md:grid-cols-2 lg:gap-8 ">
                <div className="pl-30 inline-flex flex-row justify-center">
                  {schedule.sunday.title}
                </div>
                <div className="pr-30 inline-flex flex-row justify-center">
                  {schedule.sunday.value}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl sm:pt-6 mt-8">
        <div className="mt-5 border-t border-white/10 sm:mt-20 lg:mt-3">
                    <span className="text-xs leading-5 text-gray-400">
                        {rightReserved()}
                    </span>
        </div>
      </div>
    </footer>
  );
};
