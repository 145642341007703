import React from "react";
import { StarIcon } from "@heroicons/react/20/solid";
import { generalData } from "../utils/data/data";

export const TestimonialSection = ({ header, title }) => {

  return (
    <div className="py-24 sm:py-32 bg-gray-200">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-xl text-center">
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-cyan-600">
            {header}
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            {title}
          </p>
        </div>
        <div className="mx-auto mt-16  max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
          <div className="-mt-8 sm:-mx-4 grid sm:text-[0]  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 ">
            {generalData.testimonials
              .sort((a, b) => b.lastValue - a.lastValue)
              .map((testimonial) => (
                <div
                  key={testimonial.author}
                  className="pt-8 sm:inline-block sm:w-full sm:px-4 rounded-b-md"
                >
                  <figure className="mx-auto max-w-2xl">
                    <p className="sr-only">5 out of 5 stars</p>

                    <blockquote
                      className="text-lg background mt-10 font-semibold leading-1 tracking-tight text-gray-900 sm:leading-1">
                      <div className="flex max-w-px200 gap-x-1 text-cyan-600">
                        <StarIcon className="h-[20px] w-[20px] flex-none" aria-hidden="true" />
                        <StarIcon className="h-[20px] w-[20px] flex-none" aria-hidden="true" />
                        <StarIcon className="h-[20px] w-[20px] flex-none" aria-hidden="true" />
                        <StarIcon className="h-[20px] w-[20px] flex-none" aria-hidden="true" />
                        <StarIcon className="h-[20px] w-[20px] flex-none" aria-hidden="true" />
                      </div>
                      <dov className="text-base max-h-[150px] overflow-y-auto">
                        {testimonial.review}
                      </dov>
                    </blockquote>
                    <figcaption className="mt-10 flex items-center gap-x-6">
                      <img
                        className="h-12 w-12 rounded-full bg-gray-50"
                        src={`/assets/testimonials/${testimonial.img}.png`}
                        alt=""
                      />
                      <div className="text-sm leading-6">
                        <div className="font-semibold text-gray-900">{testimonial.author}</div>
                        <div
                          className="mt-0.5 text-gray-600">{testimonial.last} / {testimonial.count} review{testimonial.count > 1 ? "s" : ""}</div>
                      </div>
                    </figcaption>
                  </figure>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
