import React, { useContext, useEffect, useState } from "react";
import { PictureWallSection } from "../section/PictureWallSection";
import { I18NContext } from "../App";
import { classNames, getCount } from "../utils/helpers";
import { Carousel } from "../components/Carousel";
import "./Services.css";

export const createServiceFactory = (key) => () => {

  const { data } = useContext(I18NContext);
  const { pictureWall, paragraph, subs, imgCount } = data.pages.services[key];
  const [images, setImages] = useState([]);

  useEffect(() => {
    const newImages = [];
    for (let i = 1; i <= imgCount; i++) {
      newImages.push({
        id: i,
        alt: `Gallery image ${i}`,
        src: `/assets/services/${key}/side-${i}.jpg`,
      });
    }

    setImages(newImages);
  }, []);
  return (<section>
    <PictureWallSection {...pictureWall} />

    <div className="bg-gray-200 pb-6">
      <main className="isolate">

        <div className="relative isolate -z-10">
          <svg
            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M.5 200V.5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
            />
          </svg>
          <div
            className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
            aria-hidden="true"
          >
            <div
              className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath: "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
              }}
            />
          </div>
          <div className="overflow-hidden">
            <div className="mx-auto max-w-7xl px-6 pb-32 pt-6 sm:pt-6 lg:px-8 lg:pt-6">
              <div className="mx-auto lg:mx-0 lg:flex lg:items-center">
                <div className="w-full lg:shrink-0">
                  <div className="relative mt-6 text-lg leading-8 text-gray-600 lg:max-w-none font-serif">
                    {paragraph()}

                    <div className="mt-8 mb-6">
                      <Carousel getCount={getCount} slideCounts={[2, 2, 1, 1]} slides={images.length}>
                        {images.map((img) => (
                          <>
                            <div key={img.id}
                                 className="max-h-[360px] group min-w-280 max-w-280 min-h-300 max-h-300 justify-center inline-flex flex-vertical">
                              <div className={
                                "border-2 border-b-black aspect-h-1 aspect-w-1 overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 flex justify-center items-center"
                              }>
                                <div style={{
                                  backgroundImage: `url('${img.src}')`,
                                }} className="absolute overflow-hidden z-0 w-full h-full blur bg-no-repeat bg-cover " />
                                <img
                                  src={`${img.src}`}
                                  alt={img.id}
                                  className="z-10 object-center w-280 h-300 min-w-280 max-w-280 min-h-300 max-h-300"
                                />
                              </div>
                            </div>
                          </>
                        ))}
                      </Carousel>
                    </div>


                    {subs && <div className="text-6xl font-bold flex justify-center mt-5 pb-4 border-b-2 font-sans">
                      {pictureWall.header}
                    </div>}
                    {subs &&

                      <div className="mt-4 mx-auto overflow-y-scroll max-h-[600px] rounded-md no-scroll">

                        <div
                          className="divide-y overflow-hidden rounded-lg shadow sm:grid sm:gap-px sm:divide-y-0 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 font-sans">
                          {subs.data.map((action, actionIdx) =>
                            (
                              <div
                                key={action.content}
                                className={
                                  classNames(
                                    actionIdx === 0 ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none" : "",
                                    actionIdx === 1 ? "sm:rounded-tr-lg" : "",
                                    actionIdx === subs.data.length - 2 ? "sm:rounded-bl-lg" : "",
                                    actionIdx === subs.data.length - 1 ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none" : "", "group relative bg-gray-100 p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500",
                                    "max-w-[450px] m-3 bg-gray-100 rounded-2xl",
                                  )}
                              >

                                <div className="justify-center">
                                  <div
                                    className="w-full inline-flex justify-center sm:text-lg font-medium leading-7 text-gray-900 text-2xl">
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    {action.content}
                                  </div>
                                </div>
                              </div>))}
                        </div>
                      </div>}

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </main>
    </div>
  </section>);
};
