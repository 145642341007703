import React from "react";
import {
  dentalAestheticsParagraph,
  dentalHygieneParagraph,
  facialAestheticsParagraph,
  implantologyParagraph,
  oralSurgeryParagraph,
  orthodonticsParagraph,
  pedodonticsParagraph,
  periodontologyParagraph,
  prostheticsParagraph,
} from "./paragraphs";

export const valueSection = {
  stats: [
    { label: "Themeluar", value: "2017" },
    { label: "Punonjës", value: "10" },
    { label: "Vende", value: "13" },
    { label: "Procedura", value: "70" },
    { label: "Pacientë", value: "2232" },
  ],
  quote: "Ne e kuptojmë rëndësinë e zgjedhjes së një zyre dentare ku ndiheni të mirëpritur dhe të sigurt. Ne duam që pacientët tanë të ndjejnë se po marrin kujdesin më të mirë të mundshëm dentar në një atmosferë të relaksuar. Ne jemi këtu për t'i ndihmuar ata të kuptojnë se po bëjnë investimin më të mirë të mundshëm...Një buzëqeshje e ndritshme, e bukur!",
  linkToAbout: () => (
    <>
      Mësoni më shumë për kompaninë tonë <span aria-hidden="true">&rarr;</span>
    </>
  ),
  paragraph: {
    header: "Vlera profesionale",
    title: "Një mision për të krijuar një projekt të elitës, për të parë gjeneratën e familjeve dhe për të vlerësuar besimin që pacientët tanë kanë vendosur te ne.",
    list: [
      {
        body: () => (
          <>
            Një mision për të krijuar një projekt të elitës, për të parë gjeneratën e familjeve dhe për të vlerësuar
            besimin që pacientët tanë kanë vendosur te ne. Kemi zhvilluar marrëdhëniet afatgjata me pacientët tanë.
            Pacientët vijnë në klinikën tonë jo vetëm për të rregulluar dhëmbët e tyre ose për të ndryshuar buzëqeshjen
            e tyre, por edhe për vizita të rregullta për të siguruar vazhdimësinë e kujdesit.
          </>
        ),
      },
      {
        body: () => (
          <>
            Ekipi ynë është i përqendruar në një gjë...
          </>
        ),
      },
      {
        body: () => (
          <>
            VETËM JU! Çdo anëtar i ekipit tonë është i përqendruar në përvojën tuaj unike si pacient, dhe jo vetëm në
            kujdesin e dhëmbëve tuaj. Ne do të studiojmë me detaje gjendjen e gojës suaj dhe të buzëqeshjes suaj, do
            t'ju ofrojmë opsionet më të mira për të ndryshuar buzëqeshjen tuaj dhe do të bëjmë trajtimin më të këndshëm
            dhe të këndshëm. Ju do të keni edhe dikë për të folur në gjuhën tuaj amtare dhe për të ju shpjeguar
            gjithçka. Stafi ynë do t'ju trajtojë si familje.
          </>
        ),
      },
    ],
  },
};

export const testimonialSection = {
  header: "Dëshmi",
  title: "Kemi ndihmuar mijëra njerëz të mrekullueshëm",
};

export const pictureWallSection = {
  imgSrc: "/assets/home/home.jpg",
  header: "Ciao Dental Albania",
  text: () => (<>
    Ne jemi të përkushtuar për shërbim të përgjegjshëm, të qëndrueshëm dhe etik.
    Përkushtimi ynë afatgjatë na lejon të përqendrohemi vetëm në cilësi.
    Po bëjmë maksimumin tonë për të krijuar një bukuri natyrale duke përfshirë buzëqeshjen dhe fytyrën tuaj.
  </>),
  link: {
    text: "Lexo historinë time",
    href: "/about",
  },
};

export const footerSection = {
  logo: {
    imgSrc: "/assets/dental-logo.png",
  },
  schedule: {
    businessHours: "Orari i Punës",
    monday: {
      title: "E Hënë",
    },
    tuesday: {
      title: "E Martë",
    },
    wednesday: {
      title: "E Mërkurë",
    },
    thursday: {
      title: "E Enjte",
    },
    friday: {
      title: "E Premte",
    },
    saturday: {
      title: "E Shtunë",
    },
    sunday: {
      title: "E Diel",
      value: "Mbyllur",
    },
  },
  rightReserved: () => (
    <div className="inline-flex justify-between w-full">
      <span> &copy; {new Date().getFullYear()} Ciao Dental Albania. Të gjitha të drejtat e rezervuara.</span>
      <span> Krijuar nga <a href="https://www.linkedin.com/in/denisakita/" target="_blank">Denisa Kita</a> </span>
    </div>
  ),
};

export const services = {
  "oral-surgery": {
    imgCount: 4,
    pictureWall: {
      imgSrc: "/assets/services/oral-surgery/title.jpg",
      header: "Kirurgjia Orale",
      text: () => (<>
        Kirurgjia orale përfshin çdo procedurë kirurgjikale të kryer në dhëmbët, fytin, faqet ose strukturat tjera
        orale. Kjo përfshin ekstraksionet, implantet, transplante të fytit dhe operacione të faqeve. Kirurgjia orale
        zakonisht kryhet nga një kirurg oral dhe maksilofaciale ose një periodontist. Këta janë specialistë të
        stomatologjisë që kanë trajnime të avancuara në procedurat e kirurgjisë orale.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "oral-surgery",
    header: "Kirurgjia Orale",
    paragraph: oralSurgeryParagraph,
    subs: {
      header: "Nën-Shërbimet",
      data: [
        { id: 1, content: "Ekstraksione të thjeshta" },
        { id: 2, content: "Ekstraksione kirurgjikale nivel 1" },
        { id: 3, content: "Ekstraksione kirurgjikale nivel 2" },
        { id: 4, content: "Ekstraksione të dhëmbëve të urtit" },
        { id: 5, content: "Ekstraksione e rrënjëve të dhëmbëve" },
        { id: 6, content: "Ekstraksione e dhëmbëve të mbajtur" },
        { id: 7, content: "Korreksioni i buzës së dyfishtë" },
        { id: 8, content: "Kirurgjia e kistave" },
        { id: 9, content: "Ngritja e sinusit" },
      ],
    },
  },
  "periodontology": {
    imgCount: 5,
    pictureWall: {
      imgSrc: "/assets/services/periodontology/title.jpg",
      header: "Periodontologjia",
      text: () => (<>
        Periodontologjia është studimi i një sistemi të specializuar të indit të fortë (kockë) dhe indit të butë
        (gjymyra) që mbështet dhëmbët dhe ruajt pozicionin e tyre në maksilën dhe mandibulën (kockat e kokës). Ky grup i
        indeve quhet periodontium dhe është i përbërë nga kocka alveolare, qeli i rrënjës, lidha periodontale dhe
        gjymyra. Dhëmbët janë brenda kockës alveolare dhe mbështeten nga lidha periodontale që bashkon qelin që mbulon
        rrënjën e dhëmbit. Këto inde të gjalla mund të përshtaten me ndryshime të ndryshme në gojë që lejojnë mbajtjen e
        pozicionit të dhëmbëve në një mënyrë të qëndrueshme.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "periodontology",
    header: "Periodontologjia",
    paragraph: periodontologyParagraph,
    subs: {
      header: "Nën-Shërbimet",
      data: [
        { id: 1, content: "Pastrimi total dhe planifikimi i rrënjëve" },
        { id: 2, content: "Pastrimi pjesërisht dhe planifikimi i rrënjëve" },
        { id: 3, content: "Pastrimi kirurgjikal dhe planifikimi i rrënjëve" },
        { id: 4, content: "Korreksioni i buzës me gyp" },
        { id: 5, content: "Kirurgjia plastike e gypit" },
      ],
    },
  },
  "implantology": {
    imgCount: 5,
    pictureWall: {
      imgSrc: "/assets/services/implantology/title.jpg",
      header: "Implantologjia",
      text: () => (<>
        Implanti dentar është një rrënjë artificiale e dhëmbit që futet në kockën e kokës (ose në maksilë ose në
        mandibulë). Zakonisht ka formën e një sharrë që është i përbërë nga titan ose ndërlikimi i titanit.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "implantology",
    header: "Implantologjia",
    paragraph: implantologyParagraph,
    subs: {
      header: "Nën-Shërbimet",
      data: [
        { id: 1, content: "Implant Straumann" },
        { id: 2, content: "Implant Schutz" },
        { id: 3, content: "Implant Eurotecnica" },
        { id: 4, content: "Implant Swiss" },
      ],
    },
  },
  "orthodontics": {
    imgCount: 7,
    pictureWall: {
      imgSrc: "/assets/services/orthodontics/title.jpg",
      header: "Ortodontia",
      text: () => (<>
        Ortodontia është një specialitet stomatologjik i fokusuar në zhbërjen e mordës suaj dhe drejtimin e dhëmbëve
        tuaj. Mund të ketë nevojë të shihni një ortodont nëse keni dhëmbë të kthjelluar, të mbivendosur, të deformuar
        ose të hapur. Trajtimet ortodonte zakonisht përfshijnë breket tradicionale, mbajtësit e qartë dhe qartë, dhe
        shqetësuesit e heqshëm.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "orthodontics",
    header: "Ortodontia",
    paragraph: orthodonticsParagraph,
    subs: {
      header: "Nën-Shërbimet",
      data: [
        { id: 1, content: "Studim ortodontik" },
        { id: 2, content: "Aparate ortodontike tradicionale metalike" },
        { id: 3, content: "Aparate ortodontike seramike" },
        { id: 4, content: "Alineatorë dentalë" },
        { id: 5, content: "Mbajtësit e hapësirës" },
        { id: 6, content: "Alineatorë për ripozicionimin e faqes" },
        { id: 7, content: "Mbajtësit e buzës dhe faqes" },
        { id: 8, content: "Zgjeruesi palatal" },
        { id: 9, content: "Mbajtësit e hekurit të zënshëm" },
        { id: 10, content: "Aparati për kokën" },
      ],
    },
  },
  "prosthetics": {
    imgCount: 5,
    pictureWall: {
      imgSrc: "/assets/services/prosthetics/title.jpg",
      header: "Proteza Dentare",
      text: () => (<>
        Pajisjet e protezës dentare zëvendësojnë ose rregullojnë dhëmbët që mungojnë ose janë të dëmtuar. Ata mund të
        bëjnë shumë më shumë se sa të përmirësojnë buzëqeshjen tuaj. Mungesa e një dhëmbi mund të ndikojë në aftësinë
        tuaj për të ngrysur dhe rritur rrezikun tuaj të sëmundjes së fytit. Mungesa e një dhëmbi gjithashtu mund të
        ndikojë në shëndetin e kockës së kokës dhe të vendosë dhëmbët e tjerë në rrezik.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "prosthetics",
    header: "Proteza Dentare",
    paragraph: prostheticsParagraph,
    subs: {
      header: "Nën-Shërbimet",
      data: [
        { id: 1, content: "Korona mbi dhëmbët natyrorë" },
        { id: 2, content: "Metali" },
        { id: 3, content: "Zirconia" },
        { id: 4, content: "Metali dhe porcelani" },
        { id: 5, content: "Zirconia e plotë" },
        { id: 6, content: "Emax" },
        { id: 7, content: "Fasadat" },
        { id: 8, content: "Inlay/Onlay" },
        { id: 9, content: "Paladium" },
        { id: 10, content: "Korona mbi implante" },
        { id: 11, content: "Metali dhe porcelani" },
        { id: 12, content: "Zirconia" },
        { id: 13, content: "Zirconia e plotë" },
        { id: 14, content: "Protezat dentare" },
        { id: 15, content: "Protezat akrilike pjellore" },
        { id: 16, content: "Protezat metal-pjellore pjellore" },
        { id: 17, content: "Protezat pjellore porcelani" },
        { id: 18, content: "Proteza totale" },
        { id: 19, content: "Të gjithë në 2 (rrëshirë akrilike/metali-porcelani/zirconia)" },
        { id: 29, content: "Të gjithë në 4 (rrëshirë akrilike/metali-porcelani, zirconia)" },
        { id: 21, content: "Të gjithë në 6 (rrëshirë akrilike/metali-porcelani/zirconia)" },
      ],
    },
  },
  "dental-aesthetic": {
    imgCount: 6,
    pictureWall: {
      imgSrc: "/assets/services/dental-aesthetic/title.jpg",
      header: "Estetika Dentare",
      text: () => (<>
        Qëllimi kryesor i estetikës dentare është të mbajë dhëmbët e orientuar, të përmirësojë strukturën e tyre dhe të
        zbukurojë ngjyrën dhe formën e tyre, kështu që mund të buzëqeshni me besim. Estetika dentare është një masë
        parandaluese dhe riparuese, pasi mund të përdoret për të korrigjuar dhëmbët që mungojnë dhe probleme të
        ngjashme.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "dental-aesthetic",
    header: "Estetika Dentare",
    paragraph: dentalAestheticsParagraph,
    subs: {
      header: "Nën-Shërbimet",
      data: [
        { id: 1, content: "Pastrimi dental" },
        { id: 2, content: "Higjiena totale e gojës" },
        { id: 3, content: "Ajri i rrjedhës" },
        { id: 4, content: "Fluorimi dental" },
        { id: 5, content: "Pastrimi me pastë dhëmbësh që zbëllet" },
        { id: 6, content: "Ajri i rrjedhës" },
        { id: 7, content: "Fluorimi dental" },
        { id: 8, content: "Pastrimi me pastë dhëmbësh që zbëllet" },
        { id: 9, content: "Zbelimi dental" },
        { id: 10, content: "Opalescence për të shkuar" },
        { id: 11, content: "Mbushja e dhëmbëve Gr.2" },
        { id: 12, content: "Mbushja e dhëmbëve Gr.3" },
        { id: 13, content: "Mbushja e dhëmbëve Gr.4" },
        { id: 14, content: "Mbushjet estetike" },
        { id: 15, content: "Fasadat kompozite" },
      ],
    },
  },
  "pediatric-dentistry": {
    imgCount: 7,
    pictureWall: {
      imgSrc: "/assets/services/pediatric-dentistry/title.jpg",
      header: "Stomatologjia Pediatric Dentistry",
      text: () => (<>
        Pedodontia është një specialitet i fokusuar në diagnostikimin dhe trajtimin e çështjeve të dhëmbëve të fëmijëve.
        Një stomatolog pediatrik punon me fëmijët që fillon në foshnjëri deri në të rriturin e ri. Stomatologu pediatrik
        ndihmon fëmijët të arrijnë dhe të mbajnë një shëndet të mirë oral. Mësoni më shumë rreth stomatologjisë
        pediatrike për të kuptuar pse është e rëndësishme për shëndetin oral të fëmijës tuaj.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "pediatric-dentistry",
    header: "Stomatologjia Pediatric Dentistry",
    paragraph: pedodonticsParagraph,
    subs: {
      header: "Nën-Shërbimet",
      data: [
        { id: 1, content: "Pastrimi dhe higjiena" },
        { id: 2, content: "Fluorizimi" },
        { id: 3, content: "Mbylljet dentare" },
        { id: 4, content: "Mbushja e dhëmbëve Gr.2" },
        { id: 5, content: "Mbushja e dhëmbëve Gr.3" },
        { id: 6, content: "Trajtimi i infeksioneve të kanalit të rrënjës" },
      ],
    },
  },
  "dental-hygiene": {
    imgCount: 6,
    pictureWall: {
      imgSrc: "/assets/services/dental-hygiene/title.jpg",
      header: "Higjiena Dentare Profesionale",
      text: () => (<>
        Higjiena dentare profesionale heq plakun e butë dhe të fortë duke përdorur mjete speciale në zyrën e
        higjienistit oral. Plaku dental është një nga shkaktarët më të zakonshëm të inflamacionit të fytit, kariestës së
        hershme, periodontitis (më mirë të njohur si sëmundja periodontale) dhe humbjen e hershme të dhëmbëve, kështu që
        duhet të hiqet në kohë.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "dental-hygiene",
    header: "Higjiena Dentare Profesionale",
    paragraph: dentalHygieneParagraph,
    subs: null,
  },
  "facial-aesthetics": {
    imgCount: 6,
    pictureWall: {
      imgSrc: "/assets/services/facial-aesthetics/title.jpg",
      header: "Estetika Faciale",
      text: () => (<>
        Rrallat, rrudhat dhe humbja e vëllimit në fytyrën dhe buzët tuaja mund t'ju lënë të ndiheni të vetëdijshëm për
        plakjen. Me trajtimin e estetikës fytyrës, mund të ktheni kohën prapa dhe të rinovoni lëkurën tuaj me vetëm disa
        injeksione.
      </>),
    },
    linkImgUrl: "https://tailwindui.com/img/ecommerce-images/home-page-01-category-01.jpg",
    to: "facial-aesthetics",
    header: "Estetika Faciale",
    paragraph: facialAestheticsParagraph,
    subs: {
      header: "Nën-Shërbimet",
      data: [
        { id: 1, content: "Këshillim" },
        { id: 2, content: "Pastrimi i thellë i fytyrës" },
        { id: 3, content: "Pastrimi i thellë i trupit (qafë, supet, shpinë)" },
        { id: 4, content: "Terapia me retinoid" },
        { id: 5, content: "Terapia e ngritjes së Protenius" },
        { id: 6, content: "Terapia e ushqimit me kaviar" },
        { id: 7, content: "Terapia Phytopeeling Apocalypsis" },
        { id: 8, content: "Terapia Thavma" },
        { id: 9, content: "Terapia Chronos" },
        { id: 10, content: "Terapia Opsis" },
        { id: 11, content: "Lëkura e shkëlqyeshme për fytyrë dhe qafë" },
        { id: 12, content: "Detox dhe antidetarminimi i lëkurës" },
        { id: 13, content: "Rigjenerimi i natës me Renight" },
        { id: 14, content: "Profhilo nga IBSA" },
        { id: 15, content: "PRX-Reverse Melazma" },
        { id: 16, content: "Trajtimet totale fytyre-sy-qafë nga Fillmed" },
        { id: 17, content: "Peeling i ndritshëm dhe i lehtë nga Fillmed" },
        { id: 18, content: "Mezoterapia e Apriline-Swiss" },
        { id: 19, content: "TKN HA 3 nga Toskani për gjithë fytyrën" },
        { id: 20, content: "Mezoterapia TKN nga Toskani" },
        { id: 21, content: "Mezoterapia për faqen e dyfishtë" },
        { id: 22, content: "Trajtimet e maskës së pastrimit dhe terapia me dioda të ndriçimit" },
        { id: 23, content: "Cosmo Peel Forte" },
        { id: 24, content: "Teknologjia BioReTherapy Biohasic" },
        { id: 25, content: "RRS HA e Skintech e zgjatur" },
        { id: 26, content: "Rigorivitalizimi bio JPX 3" },
        { id: 27, content: "Sunekos 200" },
        { id: 28, content: "Ejal 40 Rivitalizues i Lëkurës me Acid Hialuronik" },
        { id: 29, content: "Injektimi i Kolagenit në fytyrë dhe trup" },
        { id: 30, content: "Botoxi i plotë" },
        { id: 31, content: "Botoxi për sy" },
        { id: 32, content: "Trajtimi i hiperhidrozës" },
        { id: 33, content: "Mbushësit dermikë" },
      ],
    },
  },
};
